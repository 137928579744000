import React from 'react'
import Base from '../Utils/Base'


export default class Sustainability extends Base{
    state = {
        data_sustainability : {title : '', content : '', image : ''},
    }

    async componentDidMount(){
        try {
            var url = this.url + '/blog/home?blog_home_type_name=sustainability&is_publish=1'
      
            var response = await this.axios.get(url, this.optionsAxios);
      
            if (response.data.status == 'success') {
                var data = response.data.data.data
                data[0].image = ''
                for(var x in data){
                    data[x].image = this.url_image + '/blog?file_name=' + data[x].file_name
                }

                await this.setState({data_sustainability : data[0]})

            }

        } catch (e) {
            console.log(e.message)
        }
    }

    render(){
        const {
            data_sustainability
        } = this.state
        return (
            <>
            <section className="mt-5">
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <h2 className='text-uppercase'>CRAFTED WITH CONSCIENCE</h2>
                        </div>

                        <div className='col-12 mt-5'>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className="ratio-11 d-md-block" style={{backgroundImage : "url(" + data_sustainability.image +")", backgroundRepeat : 'no-repeat', backgroundSize : 'cover'}} ></div>
                                </div>
                                <div className='col-12 col-md-6 mt-5 mt-md-0 sustainAboutContent'>
                                    <h5 className='text-uppercase'>{data_sustainability.title}</h5>
                                    <p className='mt-4' style={{lineHeight : '1.8em', fontSize : '15px'}} dangerouslySetInnerHTML={{__html: data_sustainability.content}}></p>
                                </div>
                            </div>
                        </div>

                    </div>
            </section>

            </>
        )
    }
}