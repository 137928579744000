import React from 'react';
import Base from '../Utils/Base';

export default class ModalAddAddress extends Base {

	state = {
	}

	async componentDidMount() {
	}

	render() {
		const {
			viewFrom,
			order_summary_data,
			grand_total,
			toCheckout,
			useVoucher,
			toPayment
		} = this.props
		return (
			<>
				<div className='col-12 col-md-4 px-4 mt-5 mt-md-0'>
					<p className='pb-3' style={{ borderBottom: '1px solid' }}>Order Summary</p>
					<div className='row m-0'>

						<div className='col-12 p-0' style={{ borderBottom: '1px solid' }}>
							{
								order_summary_data.map((data, index) => (
									<div className='row' key={index}>
										<div className='col'>
											{data.title}
										</div>
										<div className='col-auto text-right'>
											<p>
												{data.type === 'min' ? '-' : ''}
												{this.props.current_currency === "USD" ? '$' : "Rp."} 
												{(this.props.current_currency === "USD" ? data.value / this.props.current_rate : data.value).toLocaleString(this.props.current_currency === "IDR" ? 'id-ID' : 'en-US')}
											</p>
										</div>
									</div>
								))
							}
						</div>

						<div className='col-12 p-0 mt-3'>
							<div className='row'>
								<div className='col'>
									Total
								</div>
								<div className='col-auto text-right'>
									<p>
										{this.props.current_currency === "USD" ? '$' : "Rp."} 
										{(this.props.current_currency === "USD" ? grand_total / this.props.current_rate : grand_total).toLocaleString(this.props.current_currency === "IDR" ? 'id-ID' : 'en-US')}
									</p>
								</div>
							</div>
						</div>

						{
							viewFrom === 'checkout' ?
								<>
									<div className='col-12 p-0 mt-2'>
										<button className='btn btn-outline-secondary btn-text-sm w-100 rounded' onClick={() => useVoucher()}>Use Voucher</button>
									</div>
									<div className='col-12 p-0 mt-4'>
										<button className='btn btn-primary btn-text-sm w-100' onClick={() => toPayment()}>Payment</button>
									</div>
								</>
								:
								viewFrom === 'cart' ?
									<div className='col-12 p-0 mt-3'>
										<button className='btn btn-primary w-100' style={{ fontFamily: 'Questrial' }} onClick={() => toCheckout()}>Checkout</button>
									</div>
									: <></>
						}

					</div>

				</div>
			</>
		);
	}
}
