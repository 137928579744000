import React from 'react';
import Base from '../Utils/Base';
import Alert from './Alert'

export default class ModalOrderComplain extends Base{

	state = {
        is_disabled : false,
        complain_text : '',
        alert : {is_show : false, message : '', type : ''}
	}

	async componentDidMount(){
	}

    async submitComplain(){
        var optionsAxios = this.optionsAxios
        optionsAxios['headers']['Authorization'] = await localStorage.getItem('token')
        var data = this.props.order

        if(this.state.complain_text !== ''){
            try {
                await this.setState({is_disabled : true})
                var url = this.url + '/order'
                
                data.complain_text = this.state.complain_text
                data.status = 'complained'
                
                var response = await this.axios.put(url, data, optionsAxios);
          
                if (response.data.status === 'success') {
                    await this.setState({alert : {is_show : true, message : 'Success', type : 'success'}})
    
                    setTimeout(() => {
                        window.location.reload()
                    }, 750);
                }
                else{
                    await this.setState({alert : {is_show : true, message : 'Failed', type : 'danger'}})
                    await this.setState({is_disabled : false})
                }
    
            } catch (e) {
                await this.setState({alert : {is_show : true, message : e.message, type : 'danger'}})
                await this.setState({is_disabled : false})
                // console.log(e.message)
            }
        }

    }

    async changeInput(value){
        await this.setState({alert : {is_show : false, message : '', type : ''}})
        await this.setState({complain_text : value})
    }

    render(){
        const {
            order
        } = this.props
        const {
            is_disabled,
            complain_text,
            alert
        } = this.state
        return (
        <>
            <div className="modal fade" id="modalOrderComplain" tabIndex="-1" aria-labelledby="modalOrderComplainLabel" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalOrderComplainLabel">Complain Order</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {
                            alert.is_show ? 
                            <Alert type={alert.type} message={alert.message} />
                            : <></>
                        }
                        <form>
                            <div className="form-group">
                                <label>Your Complaint</label>
                                <textarea className='form-control' rows={5} value={complain_text} onChange={(e)=>this.changeInput(e.target.value)}></textarea>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-secondary btn-text-sm" disabled={is_disabled} data-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-sm btn-primary btn-text-sm" disabled={is_disabled} onClick={()=>this.submitComplain()}>Submit</button>
                    </div>
                    </div>
                </div>
            </div>
        </>
        );
    }
}
