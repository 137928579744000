import { data } from 'jquery'
import React from 'react'
import Base from '../Utils/Base'

export default class Payment extends Base{
    state = {
        token : '',
        optionsAxios : this.optionsAxios,
        payment_method_arr : [],
        payment_method_selected : {id : ''},
        subTotal : 0,
        grand_total : 0,
        cart_data : {},
        order_data : {},
        is_disabled : false,
    }

    async componentDidMount(){
        // await localStorage.clear()
        var token = await localStorage.getItem('token')
        var cart_data = await localStorage.getItem('cart')
        cart_data = JSON.parse(cart_data)

        var order_data = await localStorage.getItem('order')
        order_data = JSON.parse(order_data)

        var subTotal = 0

        for(var x in cart_data){
            cart_data[x].currencyFormat = (cart_data[x].currency.name === 'IDR' ? this.currencyIDR : this.currencyUSD)
            cart_data[x].price_display = (cart_data[x].currency.name === 'IDR' ? 'Rp. ' : '$') + parseFloat(cart_data[x].price).toLocaleString(cart_data[x].currencyFormat)
            cart_data[x].totalPrice = (cart_data[x].currency.name === 'IDR' ? 'Rp. ' : '$') + parseFloat(cart_data[x].price * cart_data[x].orderQty).toLocaleString(cart_data[x].currencyFormat)

            subTotal += cart_data[x].orderQty * cart_data[x].price
        }

        var grand_total = subTotal

        if(cart_data == null){
            cart_data = []
        }

        var optionsAxios = this.state.optionsAxios
        optionsAxios['headers']['Authorization'] = token
        await this.setState({token : token, optionsAxios : optionsAxios, order_data : order_data, cart_data : cart_data, grand_total : grand_total, subTotal : subTotal})


        await this.get_paymentMethod()
    }

    async get_paymentMethod(){
        try {
            var url = this.url + '/payment-method/all'
      
            var response = await this.axios.get(url, this.state.optionsAxios,
            );
      
            if (response.data.status === 'success') {
                var data = response.data.data
                for(var x in data){
                    data[x].is_selected = false
                }
                await this.setState({payment_method_arr : data})
            }

        } catch (e) {
            // console.log(e.message)
        }
    }

    async changeMethod(index){
        var payment_method = this.state.payment_method_arr
        for(var x in payment_method){
            payment_method[x].is_selected = false
        }
        payment_method[index]. is_selected = true

        await this.setState({payment_method_arr : payment_method, payment_method_selected : payment_method[index]})
    }

    async payNow(){
        var order = this.state.order_data
        order.payment_method = {id : this.state.payment_method_selected.id}

        this.$('#modalPayNow').modal('show')

        // try {
        //     await this.setState({is_disabled : true})
        //     var url = this.url + '/order'
      
        //     var response = await this.axios.post(url, order, this.state.optionsAxios,
        //     );
      
        //     if (response.data.status === 'success') {
        //         this.$('#modalPayNow').modal('show')
        //         await localStorage.removeItem('cart')
        //         await localStorage.removeItem('order')
        //     }
        //     else{
        //         await this.setState({is_disabled : false})
        //     }

        // } catch (e) {
        //     await this.setState({is_disabled : false})
        //     // console.log(e.message)
        // }
    }

    render(){
        const {
            payment_method_arr,
            cart_data,
            subTotal,
            grand_total,
            is_disabled
        } = this.state
        return (
            <>

            <div className='row'>
                
                <div className='col-12 p-5'>
                    <div className='row'>

                        <div className='col-12 col-md-8 px-4'>
                            <p className='pb-3' style={{borderBottom : '1px solid'}}>Payment</p>

                            <div className='row m-0'>

                                <div className='col-12 border p-3 mt-3'>
                                    <p className='m-0'>Payment Method : </p>
                                    <div className='row mt-2'>
                                        <div className='col-12'>
                                        {
                                            payment_method_arr.map((data, index)=>(
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" id={"courier" + index} className="custom-control-input" name='selectPaymentMethod' value={data.id} checked={data.is_selected} onChange={()=>this.changeMethod(index)} />
                                                    <label className="custom-control-label w-100 pl-2" htmlFor={"courier" + index}>
                                                        <p className="m-0"><b>{data.name}</b></p>
                                                    </label>
                                                </div>
                                            ))
                                        }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='col-12 col-md-4 px-4'>
                            <p className='pb-3' style={{borderBottom : '1px solid'}}>Order Summary</p>
                            <div className='row m-0'>
                            
                                <div className='col-12 p-0' style={{borderBottom : '1px solid'}}>
                                    <div className='row'>
                                        <div className='col'>
                                            Subtotal
                                        </div>
                                        <div className='col-auto text-right'>
                                            <p>Rp. {subTotal.toLocaleString(this.currencyIDR)}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            Shipping
                                        </div>
                                        <div className='col-auto text-right'>
                                            <p>Rp. {subTotal.toLocaleString(this.currencyIDR)}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 p-0 mt-3'>
                                    <div className='row'>
                                        <div className='col'>
                                            Total
                                        </div>
                                        <div className='col-auto text-right'>
                                            <p>Rp. {grand_total.toLocaleString(this.currencyIDR)}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 p-0 mt-3'>
                                    <button className='btn btn-primary btn-text-sm w-100' onClick={()=>this.payNow()} disabled={is_disabled}>Pay</button>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <div className="modal fade" id="modalPayNow" tabIndex="-1" aria-labelledby="modalPayNowLabel" aria-hidden="true" data-backdrop="static" data-keyboard='false'>
                <div className="modal-dialog">
                    <div className="modal-content">
                    {/* <div className="modal-header">
                        <h5 className="modal-title" id="modalPayNowLabel">Payment</h5>
                    </div> */}
                    <div className="modal-body">
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <h5>Selesaikan Pembayaran Dalam</h5>
                                <h6>Countdown</h6>

                                <p className='m-0 mt-3'>Batas Akhir Pembayaran</p>
                                <p>Date</p>

                            </div>
                            <div className='col-12 text-center'>
                                <a href='/' className='btn btn-primary'>Back to Home</a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-secondary btn-text-sm" data-dismiss="modal">Close</button>
                    </div> */}
                    </div>
                </div>
            </div>

            </>
        )
    }
}