import React from 'react'
import Base from '../Utils/Base'

export default class Select extends Base{
    state = {
    }

    async componentDidMount(){
    }

    render(){
        const {
            data,
            changeInput,
            data_arr,
            title,
            id_select
        } = this.props
        return (
            <>
            <select className="custom-select custom-select-sm" id={(id_select != null ? id_select : '')} value={data.id} onChange={(e)=>changeInput(e.target.value)}>
                <option value="" disabled={true}>{title}</option>
                {
                    data_arr.map((data, index)=>(
                        <option value={data.id} key={index}>{data.name}</option>
                    ))
                }
            </select>
            </>
        )
    }
}