import React from 'react'
import Base from '../Utils/Base'
import Breadcrumbs from '../Components/Breadcrumbs'
import ProductList from './productList'
import NoData from '../Components/NoData'
import Preloader from '../Components/ModalPreloader'

import { tns } from 'tiny-slider/src/tiny-slider'
import { data } from 'jquery'

export default class ProductDetail extends Base {
	state = {
		breadcrumbs_arr: [
			{ title: 'Home', url: '/', is_active: false },
			{ title: 'Shop', url: '/shop-all', is_active: false },
		],
		product_data: { id: '', variant: { name: '' }, qty: 1, amount: 1, product: { id: '', description: '' }, arr_variant: [], arr_photo: [], product_final: [], product_photo: [] },
		optionsAxios: this.optionsAxios,
		collapsible_arr: [
			{ title: 'Product Detail', is_open: true, icon: '-' },
			{ title: 'Return & Refund Policy', is_open: false, icon: '+' },
			{ title: 'Shipping Info', is_open: false, icon: '+' },
		],
		selected_variant: { id: '', no_variant_name: '' },
		is_error: false,
		price_display: '',
		price_display_after_disc: '',
		related_product_arr: [],
		new_cart: {},
		isLoading: true,
		current_currency : '',
		current_rate: 0,
	}

	async componentDidMount() {
		await this.preloader('open')
		var token = await localStorage.getItem('token')
		await this.setState({ token: token })
		if (token != null) {
			var optionsAxios = this.state.optionsAxios
			optionsAxios['headers']['Authorization'] = token
			await this.setState({ optionsAxios: optionsAxios })
		}

		var currency = await localStorage.getItem('currency_selected')
    if(currency != null){
			await this.setState({current_currency : currency})
			if(currency === 'USD'){
				await this.get_rate()
			}
    }
    else{
			await this.setState({current_currency : 'IDR'})
    }

		await this.setView('product', this.props.match.params.id)

		await this.get_data()
		await this.setState({ isLoading: false })
		await this.preloader('close')
		await this.get_data('related')

		if (this.state.related_product_arr.length > 0) {
			tns({
				container: ".relatedProduct-carousel",
				responsive: {
					0: {
						items: 1,
					},
					992: {
						items: 4,
					},
				},
				gutter: 20,
				loop: true,
				slideBy: "1",
				nav: false,
				controlsContainer: '#relatedProduct-controls',
				controls: true,
				swipeAngle: false,
				mouseDrag: true
			});
		}

		this.$('#modalAddToCart').on('hide.bs.modal', async function (event) {
			window.location.reload()
		})
	}

	async get_rate(){
    try {
        var url = this.url + '/exchange/last'

        var response = await this.axios.get(url, this.optionsAxios);

        if (response.data.status == 'success') {
            await this.setState({current_rate : response.data.data.price})
        }

    } catch (e) {
        console.log(e.message)
    }
  }

	async get_data(type = '') {
		try {
			var url = this.url + '/product?id=' + this.props.match.params.id

			if (type === 'related') {
				url = this.url + '/product?category_id=' + this.state.product_data.category_id + '&not_id=' + this.props.match.params.id
			}

			var response = await this.axios.get(url + '&is_publish=1', this.optionsAxios,
			);

			if (response.data.status === 'success') {
				var data = response.data.data

				if (type === '') {
					data.image_display = this.no_image
					var photo_product = data.arr_photo

					data.image_primary = this.no_image
					data.product_photo = []

					for (var x in photo_product) {
						photo_product[x].image_display = this.url_image + '/product?file_name=' + photo_product[x].file_name
						photo_product[x].is_active = false

						if (photo_product[x].is_primary) {
							photo_product[x].is_active = true
							data.image_display = this.url_image + '/product?file_name=' + photo_product[x].file_name
							data.image_primary = this.url_image + '/product?file_name=' + photo_product[x].file_name
						}

						data.product_photo.push(photo_product[x])
					}

					data.currencyFormat = (data.currency.name === 'IDR' ? this.currencyIDR : this.currencyUSD)
					data.price_display = (data.currency.name === 'IDR' ? 'Rp. ' : '$') + parseFloat(data.price).toLocaleString(data.currencyFormat)
					data.is_discount = false
					if (data.price_after_discount != null) {
						data.is_discount = true
						data.price_display_after_disc = (data.currency.name === 'IDR' ? 'Rp. ' : '$') + parseFloat(data.price_after_discount).toLocaleString(data.currencyFormat)
					}

					

					data.amount = 1
					data.qty = 1

					var product_final = data.product_final
					for (var x in product_final) {
						product_final[x].is_selected = false
						var variant_photo = product_final[x].arr_photo
						for (var y in variant_photo) {
							variant_photo[y].is_active = false
							variant_photo[y].image_display = this.url_image + '/product?file_name=' + variant_photo[y].file_name
							data.product_photo.push(variant_photo[y])
						}

					}

					if (product_final.length === 1) {
						product_final[0].is_selected = true

						data.amount = 1
						data.qty = product_final[0].stock

						data.price_display = (data.currency.name === 'IDR' ? 'Rp. ' : '$') + parseFloat(product_final[0].price).toLocaleString(data.currencyFormat)
						data.is_discount = false
						if (data.price_after_discount != null) {
							data.is_discount = true
							data.price_display_after_disc = (product_final[0].currency.name === 'IDR' ? 'Rp. ' : '$') + parseFloat(product_final[0].price_after_discount).toLocaleString(data.currencyFormat)
						}

						await this.setState({ selected_variant: product_final[0] })
					}
					

					var arr_variant = data.arr_variant
					for (var x in arr_variant) {
						var sub_variant = arr_variant[x].sub_variant
						for (var y in sub_variant) {
							sub_variant[y].is_selected = false
						}
					}

					var breadcrumbs_arr = this.state.breadcrumbs_arr
					breadcrumbs_arr.push({ title: data.name, url: '#!', is_active: true })
					await this.setState({ product_data: data, price_display: data.price_display, price_display_after_disc: data.price_display_after_disc, breadcrumbs_arr: breadcrumbs_arr })
				}
				else if (type === 'related') {
					data = response.data.data.data
					for (var x in data) {
						var photo_product = data[x].arr_photo
						data[x].image_display = this.no_image
						for (var y in photo_product) {
							if (photo_product[y].is_primary) {
								data[x].image_display = this.url_image + '/product?file_name=' + photo_product[y].file_name
							}
						}

						data[x].currencyFormat = (data[x].currency.name === 'IDR' ? this.currencyIDR : this.currencyUSD)
						data[x].price_display = (data[x].currency.name === 'IDR' ? 'Rp. ' : '$') + parseFloat(data[x].price).toLocaleString(data[x].currencyFormat)
						data[x].is_discount = false
						if (data[x].price_after_discount != null) {
							data[x].is_discount = true
							data[x].price_display_after_disc = (data[x].currency.name === 'IDR' ? 'Rp. ' : '$') + parseFloat(data[x].price_after_discount).toLocaleString(data[x].currencyFormat)
						}
					}
					await this.setState({ related_product_arr: data })
				}
			}

		} catch (e) {
			// console.log(e.message)
		}
	}

	async changeQty(value) {
		var product_data = this.state.product_data
		product_data['amount'] = (value === '0' ? 1 : value)

		if (value > product_data.qty) {
			product_data['amount'] = product_data.qty
		}

		await this.setState({ product_data: product_data })
	}

	async addToCart(type) {
		var product_data = this.state.product_data
		var selected_variant = this.state.selected_variant, selected_sub_variant = {}
		var cart = {}

		cart = selected_variant
		cart.amount = product_data.amount
		cart.display_name = product_data.name + ((selected_variant.no_variant_name != '') ? ' - ' + selected_variant.no_variant_name : '')

		cart.image_display = product_data.image_primary
		cart.qty = product_data.qty

		if (product_data.amount > 0) {
			if (type === 'cart') {
				if (cart.id === '' || cart.id == null) {
					await this.setState({ is_error: true })
				}
				else {
					var cart_data = await localStorage.getItem('cart')
					cart_data = JSON.parse(cart_data)
					var flag = false

					if (cart_data == null) {
						cart_data = []
						cart.amount = product_data.amount
						cart_data.push(cart)
					}
					else if (cart_data != null) {
						for (var x in cart_data) {
							if (cart_data[x].id === cart.id) {
								cart_data[x].amount = parseFloat(cart_data[x].amount) + parseFloat(product_data.amount)
								flag = true
							}
						}
						if (!flag) {
							cart.amount = product_data.amount
							cart_data.push(cart)
						}
					}

					await localStorage.setItem('cart', JSON.stringify(cart_data))

					await this.setState({ new_cart: cart })

					this.$('#modalAddToCart').modal('show')
				}
			}
			else if (type === 'buy') {
				var token = await localStorage.getItem('token')
				if (token == null) {
					this.$('#modalAuth').modal('show')
				}
				else {
					if (cart.id === '' || cart.id == null) {
						await this.setState({ is_error: true })
					}
					else {
						var order = {}
						var data = {}
						data.price = cart.price_after_discount != null ? cart.price_after_discount : cart.price
						data.amount = cart.amount
						data.product_final = { id: cart.id }
						data.image_display = cart.image_display
						order.arr_cart = [data]

						var cart_data = [cart]

						await localStorage.setItem('cart', JSON.stringify(cart_data))
						await localStorage.setItem('order', JSON.stringify(order))
						window.location.href = '/transaction/checkout'
					}
				}
			}
		}
	}

	async changeDisplayImage(index) {
		var product_data = this.state.product_data
		var photo = product_data.product_photo
		for (var x in photo) {
			photo[x].is_active = false
		}
		photo[index].is_active = true

		if (photo[index].is_active) {
			product_data.image_display = photo[index].image_display
		}

		await this.setState({ product_data: product_data })
	}

	async selectVariant(index) {
		var product_data = this.state.product_data
		var product_final = product_data.product_final
		var product_photo = product_data.product_photo

		await this.setState({ is_error: false })

		for (var x in product_final) {
			if (x != index) {
				product_final[x].is_selected = false
			}
		}

		product_final[index].is_selected = !product_final[index].is_selected

		var selected_variant = { id: '', name: '' }
		var price_currency = 0, price_display = 0, price_display_after_disc = 0
		if (product_final[index].is_selected) {
			selected_variant = product_final[index]
			product_data.amount = product_final[index].stock === 0 ? 0 : 1
			product_data.qty = selected_variant.stock
			price_currency = selected_variant.currency.name === 'IDR' ? 'Rp. ' : '$'
			product_data.is_discount = false
			price_display = price_currency + parseFloat(selected_variant.price).toLocaleString(selected_variant.currency.name === 'IDR' ? this.currencyIDR : this.currencyUSD)
			if (selected_variant.price_after_discount != null && selected_variant.price_after_discount != selected_variant.price) {
				product_data.is_discount = true
				product_data.price_display_after_disc = price_currency + parseFloat(selected_variant.price_after_discount).toLocaleString(selected_variant.currency.name === 'IDR' ? this.currencyIDR : this.currencyUSD)
				price_display_after_disc = price_currency + parseFloat(selected_variant.price_after_discount).toLocaleString(selected_variant.currency.name === 'IDR' ? this.currencyIDR : this.currencyUSD)
			}
			else{
				product_data.is_discount = true
				product_data.price_display_after_disc = ''
				price_display_after_disc = ''
			}

			if(this.state.current_currency === 'USD'){
				if(product_final[index].currency.name === 'IDR'){
					price_display = '$' + parseFloat(product_final[index].price / this.state.current_rate).toLocaleString(product_final[index].currencyFormat, {
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
					})
				}
			}
			else if(this.state.current_currency === 'IDR'){
				if(product_final[index].currency.name === 'USD'){
					price_display = 'Rp. ' + parseFloat(product_final[index].price / this.state.current_rate).toFixed(2).toLocaleString(product_final[index].currencyFormat, {
						maximumFractionDigits: 0,
						minimumFractionDigits: 0,
					})
				}
			}

			var sub_variant_photo_arr = product_final[index].photo
			var sub_variant_photo = { id: '' }
			for (var x in sub_variant_photo_arr) {
				if (sub_variant_photo_arr[x].is_primary) {
					sub_variant_photo_arr[x].image_display = this.url_image + '/product?file_name=' + sub_variant_photo_arr[x].file_name
					if (sub_variant_photo_arr[x].is_primary) {
						sub_variant_photo = sub_variant_photo_arr[x]
					}
				}
			}

			for (var x in product_photo) {
				product_photo[x].is_active = false
				if (product_photo[x].id === sub_variant_photo.id) {
					product_photo[x].is_active = true
					product_data.image_display = product_photo[x].image_display
				}
			}
		}
		else {
			product_data.amount = 1
			product_data.qty = 1
			price_currency = product_data.price_currency
			price_display = product_data.price_display
			price_display_after_disc = product_data.price_display_after_disc
		}

		// console.log(product_data)

		await this.setState({ product_data: product_data, selected_variant: selected_variant, price_display: price_display, price_display_after_disc: price_display_after_disc })

	}

	render() {
		const {
			breadcrumbs_arr,
			product_data,
			is_error,
			price_display,
			price_display_after_disc,
			related_product_arr,
			new_cart,
			selected_variant,
			isLoading
		} = this.state

		return (
			<>
				<div style={{ marginBottom: '3.75rem', marginTop: '2.5rem' }}>
					<Breadcrumbs breadcrumbs_arr={breadcrumbs_arr} />
				</div>

				{
					!isLoading ?
						<>
							{
								product_data.id !== '' ?
									<div className='row'>
										<div className='col-12'>
											<div className='row'>
												<div className='col-12 col-md-6'>
													<div className='row'>
														<div className='col-12'>
															<div className='ratio-11 rounded' style={{ backgroundImage: "url(" + product_data.image_display + ")" }}></div>
														</div>
														<div className='col-12 mt-3'>
															<div className='row m-0'>
																{
																	product_data.product_photo.map((data, index) => (
																		<div className='col-2 pl-0 pr-2 mb-2' key={index}>
																			<div className={'ratio-11 listPhoto_product' + (data.is_active ? ' active' : '')} style={{ backgroundImage: "url(" + data.image_display + ")", cursor: 'pointer' }} onClick={() => this.changeDisplayImage(index)}></div>
																		</div>
																	))
																}
															</div>
														</div>
													</div>
												</div>
												<div className='col-12 col-md-6'>
													<p className='m-0 text-capitalize' style={{ fontSize: '2rem' }}>{product_data.name}</p>
													{
														selected_variant.id !== '' ?
															<p><small>SKU : {selected_variant.id}</small></p>
															: <></>
													}
													{
														price_display_after_disc != '0' && price_display_after_disc != null && price_display_after_disc != '' ?
															<p style={{ fontSize: '1rem' }}>
																<del>{price_display}</del>
																<b>{price_display_after_disc}</b>
															</p>
															:
															<div>
																<p style={{ fontSize: '1rem' }}>
																	{price_display.toLocaleString(this.state.current_currency === "IDR" ? 'id-ID' : 'en-US')}
																</p>
																{/*
																<p style={{ fontSize: '1rem' }}>
																	{this.state.current_currency === "USD" ? '$' : "Rp."} {(this.state.current_currency === product_data.currency.name ? product_data.price : (product_data.currency.name === "IDR" ? product_data.price / this.state.current_rate : product_data.price * this.state.current_rate)).toLocaleString(this.state.current_currency === "IDR" ? 'id-ID' : 'en-US')}
																</p>
																*/}
															</div>
													}

													{
														product_data.product_final.length > 1 ?
															<div className='row m-0 mt-4'>
																<div className='col-12 p-0'>
																	<p className='m-0 text-capitalize'>Variant :
																		{
																			is_error ?
																				<small className='text-danger'> Select 1 Variant</small>
																				: <> {selected_variant.no_variant_name}</>
																		}
																	</p>
																</div>
																<div className='col-12 mt-2'>
																	<div className='row'>
																		{
																			product_data.product_final.map((data, index) => (
																				<div className={'col-auto pl-0 pr-2 mb-2'} key={index}>
																					<div className={'variantOpt px-2 py-1' + (data.is_selected ? ' selected' : '')} onClick={() => this.selectVariant(index)}>
																						<p className='m-0 text-capitalize'><small>{data.no_variant_name}</small></p>
																					</div>
																				</div>
																			))
																		}
																	</div>
																</div>
															</div>
															: <></>
													}

													<form className='row mt-3'>
														<div className='col-auto'>
															<label><small>Quantity</small></label>
															<input type='number' className='form-control' pattern={'[0-9]*'} min={(product_data.qty > 0 ? 1 : 0)} max={product_data.qty} value={product_data.amount} onChange={(e) => this.changeQty(e.target.value)} />
														</div>
														<div className='col-12 mt-5'>
															<button type='button' className={'btn btn-sm w-100 btn-primary btn_primary'} onClick={() => this.addToCart('cart')} disabled={product_data.qty > 0 ? false : true}>{product_data.qty > 0 ? 'Add to Cart' : 'Out Of Stock'}</button>

															<button type='button' className={'btn btn-sm w-100 btn-secondary mt-3'} onClick={() => this.addToCart('buy')} disabled={product_data.qty > 0 ? false : true}>Buy Now</button>
														</div>
													</form>

													<div className='row mt-4'>
														<div className='col-12'>
															<p>Product Info</p>
															<p dangerouslySetInnerHTML={{ __html: product_data.description }}></p>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className='col-12 mt-5'>
											<p className='text-center' style={{ fontSize: '1.75rem' }}>Related Products</p>
											<div className='row'>
												{
													related_product_arr.length == 0 ?
														<div className='col-12 text-center'>
															<p style={{ fontSize: '1.5rem' }}>No Product</p>
														</div>
														:
														<>
															<div className='col-12 px-5'>
																<div className="relatedProduct-container position-relative px-3">
																	<div id="relatedProduct-controls" className="controls" aria-label="Carousel Navigation" tabIndex="0">
																		<div className="prev valign-wrapper d-flex align-items-center justify-content-center" data-controls="prev" aria-controls="relatedProduct-carousel" tabIndex="-1">
																			<i className="fas fa-angle-left"></i>
																		</div>
																		<div className="next valign-wrapper d-flex align-items-center justify-content-center" data-controls="next" aria-controls="relatedProduct-carousel" tabIndex="-1">
																			<i className="fas fa-angle-right"></i>
																		</div>
																	</div>
																	<div className="relatedProduct-carousel">
																		{
																			related_product_arr.map((data, index) => (
																				<div key={index}>
																					<ProductList 
																						data={data} 
																						current_currency={this.state.current_currency}
																						current_rate={this.state.current_rate}
																						updateQty={(type, value) => this.updateQty(type, index, value)} 
																						addToCart={() => this.addToCart(index)} 
																						hoverList={(type) => this.hoverList(type, index)} />
																				</div>
																			))
																		}
																	</div>
																</div>
															</div>
														</>
												}
											</div>
										</div>
									</div>
									:
									<div className='row text-center'>
										<div className='col-12'>
											<NoData />
										</div>
										<div className='col-12'>
											<a href='/shop-all'><u>Continue Shopping</u></a>
										</div>
									</div>
							}
						</>
						:
						<></>
				}


				<div className="modal fade" id="modalAddToCart" tabIndex="-1" aria-labelledby="modalAddToCartLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content rounded">
							<div className="modal-body">
								<div className='row m-0'>
									<div className='col-12 text-center'>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">&times;</span>
										</button>
										<h5 className='m-0'>Berhasil Ditambahkan</h5>
									</div>
									<div className='col-12 mt-4 border rounded p-2'>
										<div className='row m-0'>
											<div className='col-2 p-0'>
												<div className='ratio-43' style={{ backgroundImage: "url(" + new_cart.image_display + ")" }}></div>
											</div>
											<div className='col align-middle align-items-center d-flex'>
												<p className='m-0 text-capitalize'>{new_cart.display_name}</p>
											</div>
											<div className='col-auto align-middle align-items-center d-flex p-0'>
												<a href='/transaction/cart' className='btn btn-primary'>Lihat Keranjang</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Preloader />

			</>
		)
	}
}