import React from 'react';
import Base from '../Utils/Base';
import Alert from './Alert'

export default class ModalOrderConfirm extends Base{

	state = {
	}

	async componentDidMount(){
	}

    render(){
        const {
            actionBtn,
            action_type,
            is_disabled
        } = this.props
        return (
        <>
            <div className="modal fade" id="modalOrderConfirm" tabIndex="-1" aria-labelledby="modalOrderConfirmLabel" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalOrderConfirmLabel">Confirm</h5>
                        {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button> */}
                    </div>
                    <div className="modal-body">
                        {
                            action_type === 'delete' ? 
                            <p className='m-0'>Are you sure you want to delete this data?</p>
                            :
                            <p className='m-0'>Are you sure you?</p>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-secondary btn-text-sm" disabled={is_disabled} data-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-sm btn-primary btn-text-sm" disabled={is_disabled} onClick={()=>actionBtn()}>{action_type === 'delete' ? 'Delete' : 'Yes'}</button>
                    </div>
                    </div>
                </div>
            </div>
        </>
        );
    }
}
