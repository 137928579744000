import React from 'react';
import Base from '../Utils/Base';
import Router from '../Router/router'
import Navbar from './navbar'
import Footer from './footer'

export default class NotFoundPage extends Base{

  state = {
  }
  
  async componentDidMount(){
    console.log(this.props)
  }

  render(){
    return (
      <>
        <div id="wrapper">
            <div className='container py-5'>
              <div className='row p-5'>
                    <div className='col-12 px-4'>
                        <div className='row m-0 text-center p-5 border-top border-bottom d-flex align-middle align-items-center'>
                            <div className='col-12'>
                                <h5 className='m-0'>The page you were looking for does not exist. </h5>
                            </div>
                            <div className='col-12'>
                                <a href='/'><u>Click here to continue shopping.</u></a>
                            </div>
                        </div>
                    </div>
              </div>
            </div>

        </div>
      </>
    );
  }
}
