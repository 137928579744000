import React from 'react'
import Base from '../Utils/Base'
import Alert from '../Components/Alert'
import ModalPreloader from '../Components/ModalPreloader'


export default class AboutUs extends Base {
	state = {
		name: '',
		email: '',
		phone: '',
		message: '',
		alert : {is_show : false, message : '', type: '',},
	}

	async componentDidMount() {
		try {
			var url = this.url + '/blog/home?blog_home_type_name=about_us&is_publish=1'

			var response = await this.axios.get(url, this.optionsAxios);

			if (response.data.status == 'success') {
				var data = response.data.data.data
				for (var x in data) {
					if (data[x].file_name != null) {
						data[x].image = this.url_image + '/blog?file_name=' + data[x].file_name
					}
				}
				if (data.length > 0) {
					await this.setState({ data_about_us: data[0], data_detail_about_us: data[1] })
				}

			}

		} catch (e) {
			console.log(e.message)
		}
	}

	async submit(){
		if(this.state.name == "")
			await this.setState({ alert: {is_show : true, message : 'Name is Empty', type: 'danger',} })
		else if(this.state.email == "")
			this.setState({ alert: {is_show : true, message : 'Email is Empty', type: 'danger',} })
		else if(this.state.message == "")
			this.setState({ alert: {is_show : true, message : 'Message is Empty', type: 'danger',} })
		else{
			try {
				this.$('#modalPreloader').modal('show')
				var url = this.url + '/inquiry'

				var token = await localStorage.getItem('token')
				var optionsAxios = this.optionsAxios
				optionsAxios['headers']['Authorization'] = token

				var data = {}
				data.name = this.state.name
				data.email = this.state.email
				data.phone = this.state.phone
				data.message = this.state.message
	
				var response = await this.axios.post(url, data, optionsAxios,);
				this.$('#modalPreloader').modal('hide')

				if (response.data.status === 'success') {
					await this.setState({ alert: {is_show : true, message : "Success. Please Wait...", type: 'success',} })
					setTimeout(() => {
						window.location.href = "/"
					}, 1000);
				}
				else{
					this.setState({ alert: {is_show : true, message : response.data.message,} })
				}

			} catch (e) {
				this.setState({ alert: {is_show : true, message : "Server Error",} })
			}
		}
	}

	render() {
		const {
			data_about_us,
			data_detail_about_us
		} = this.state
		return (
			<>
				<section className="mt-5">
					<ModalPreloader/>
					<div className='row'>
						<div className='col-12 text-center'>
							<h2 className='text-uppercase'>LET'S TALK</h2>
						</div>

						<div className='col-12 mt-3 mt-md-5 mb-5 mb-md-0'>
							<div className='row'>
								
								<div className='col-12 col-md-6 px-md-3'>
									<div className='mt-4'>
										<p>
										If you have questions or special<br/>
										inquiries, you're welcome to contact<br/>
										us or fill out this form
										</p>
									</div>
								</div>
								<div className='col-12 col-md-6'>
									<div className='row'>
										{
											this.state.alert.is_show &&
											<div className='col-12'>
												<Alert type={this.state.alert.type} message={this.state.alert.message} />
											</div>
										}
										

										<div className='col-6'>
											<p className='font-italic'>Enter Your Name*</p>
											<div className='form-inline justify-content-center'>
												<div id="sc_000" className="input-group border-bottom">
													<input type="text" className="form-control form-control-sm bg-transparent border-0 p-0" placeholder='Full Name' value={this.state.name} onChange={(e) => this.setState({name: e.target.value, })} />
												</div>
											</div>
										</div>

										<div className='col-6'>
											<p className='font-italic'>Enter Your Email*</p>
											<div className='form-inline justify-content-center'>
												<div id="sc_000" className="input-group border-bottom">
													<input type="text" className="form-control form-control-sm bg-transparent border-0 p-0" placeholder='Email' value={this.state.email} onChange={(e) => this.setState({email: e.target.value, })} />
												</div>
											</div>
										</div>

										<div className='col-12 mt-3'>
											<p className='font-italic'>Enter Your Phone</p>
											<div className=' justify-content-center'>
												<div id="sc_000" className="input-group border-bottom" style={{maxWidth: 'none',}}>
													<input type="text" className="form-control form-control-sm bg-transparent border-0 p-0" placeholder='Phone' value={this.state.phone} onChange={(e) => this.setState({phone: e.target.value, })} />
												</div>
											</div>
										</div>

										<div className='col-12 mt-3'>
											<p className='font-italic'>Enter Your Message*</p>
											<div className=' justify-content-center'>
												<div id="sc_000" className="input-group border-bottom" style={{maxWidth: 'none',}}>
													<textarea className="form-control form-control-sm bg-transparent border-0 p-0" placeholder='Message' onChange={(e) => this.setState({message: e.target.value, })}>{this.state.message}</textarea>
												</div>
											</div>
										</div>

										<div className="form-group col-12 mt-3">
												<button type="button" className="btn btn-primary w-100" onClick={()=>this.submit()}>Submit</button>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</section>

			</>
		)
	}
}