import React from 'react'
import Base from '../Utils/Base'

import FavoritesSection from './Favorites'
import NaturallySimpleSection from './naturallySimple'
import ImperfectionSection from './Imperfection'

export default class Login extends Base {
    state = {
        header_section: { title: '', content: '', image: '' },
        section2_testimony_arr: [],

        home_data_arr: [],
    }

    async componentDidMount() {
        await this.get_home_data('title')
        await this.get_home_data('section_1')
        await this.get_home_data('section_2')
        await this.get_home_data('section_2_testimony')
        await this.get_home_data('section_3')
    }

    async get_home_data(type) {
        try {
            var url = this.url + '/blog/home?blog_home_type_name=' + type + '&is_publish=1'

            var response = await this.axios.get(url, this.optionsAxios);

            if (response.data.status == 'success') {
                var data = response.data.data.data
                var home_data_arr = this.state.home_data_arr
                var home_data = {}
                data[0].image = ''
                for (var x in data) {
                    data[x].image = this.url_image + '/blog?file_name=' + data[x].file_name
                }
                if (type === 'title') {
                    await this.setState({ header_section: data[0] })
                }
                else if (type === 'section_2_testimony') {
                    await this.setState({ section2_testimony_arr: data })
                }
                else {
                    home_data.type = type
                    home_data.title = data[0].blog_home_type.title
                    home_data.data = data

                    home_data_arr.push(home_data)
                    await this.setState({ home_data_arr: home_data_arr })
                }

            }

        } catch (e) {
            console.log(e.message)
        }
    }

    render() {
        const {
            header_section,
            section2_testimony_arr,
            home_data_arr
        } = this.state

        return (
            <>
                {
                    header_section.title !== '' ?
                    <section className="mt-5">
                        <div className="row">
                            <div id="index_banner_section">
                                <div className="img" style={{ backgroundImage: "url(" + header_section.image + ")" }} >
                                </div>
                                <div className="txt">
                                    <div className="flx">
                                        <h1>{header_section.title}</h1>
                                        <span dangerouslySetInnerHTML={{ __html: header_section.content }}></span>
                                        <a href='/shop-all' className='btn btn_primary_secondary p-2 px-4'>Shop Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :<></>
                }


                {
                    home_data_arr.map((data, index) => (
                        <section className="mt-5 pb-5" key={index}>
                            <div className='row'>
                                <div className='col-12 text-center'>
                                    <h2 className='text-uppercase homeSectionTitle'>{data.title}</h2>
                                </div>
                                <div className='col-12'>
                                {
                                    data.type === 'section_1' ?
                                        <FavoritesSection section1={data.data} />
                                        : data.type === 'section_2' || data.type === 'section2_testimony' ?
                                            <NaturallySimpleSection section2={data.data} section2_testimony_arr={section2_testimony_arr} />
                                            : data.type === 'section_3' ?
                                                <ImperfectionSection imperfection_arr={data.data} />
                                                : <></>
                                }
                                </div>
                                {/* <div className='col-12 mt-3 mt-md-5'>
                                </div> */}
                            </div>
                        </section>

                    ))
                }
            </>
        )
    }
}