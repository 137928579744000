import React from 'react'
import Base from '../../Utils/Base'
import Pagination from 'react-js-pagination'

import ModalConfirm from '../../Components/ModalOrderConfirm'
import ModalPayNow from '../../Components/ModalPayNow'
import ModalOrderComplain from '../../Components/ModalOrderComplain'

export default class ListOrderView extends Base{
    state = {
        token : '',
        optionsAxios : '',
        nav_menu : [
            {id : 'wait_payment', title : 'Waiting Payment', is_selected : true},
            {id : 'processed', title : 'Processed', is_selected : false},
            {id : 'shipped', title : 'Shipped', is_selected : false},
            {id : 'arrived', title : 'Arrived', is_selected : false},
            {id : 'accepted', title : 'Accepted', is_selected : false},
            {id : 'complained', title : 'Complained', is_selected : false},
            {id : 'canceled', title : 'Canceled', is_selected : false},
        ],
        order_data_arr : [],
        page : 1,
        total_data : 0,
        per_page : 0,
        order_selected : {id : ''},
        action_type : '',
        is_disabled : false,
        countdown_timer : '',
        bank_info : [],
        grand_total : 0,
        is_loading : false,
    }

    async componentDidMount(){
        var token = localStorage.getItem('token')
        var optionsAxios = this.optionsAxios
        optionsAxios['headers']['Authorization'] = token
        await this.setState({token : token, optionsAxios : optionsAxios})

        var nav_menu = this.state.nav_menu
        for(var x in nav_menu){
            if(nav_menu[x].is_selected){
                await this.get_data_order(nav_menu[x].id)
            }
        }
    }

    async get_data_order(type){
        try {
            await this.setState({is_loading : true})

            var sort = [{"name": "created_at", "sort": "desc"}]

            var url = this.url + '/order?status=' + type + '&page=' + this.state.page + '&sort=' + JSON.stringify(sort)

            var response = await this.axios.get(url, this.state.optionsAxios);
      
            if (response.data.status === 'success') {
                var data = response.data.data.data
                var order_data_arr = this.state.order_data_arr
                for(var x in data){
                    data[x].order_date = this.moment(data[x].date).format('DD MMM YYYY')
                    var order_detail = data[x].detail
                    data[x].total_weight = 0
                    data[x].image_display = this.no_image
                    data[x].total_invoice = 0
                    var total_invoice = 0
                    var currency = '', currency_format = '', is_dollar = false

                    for(var y in order_detail){
                        is_dollar = (order_detail[y].currency.name === 'USD' ? 1 : 0)

                        data[x].total_weight += order_detail[y].product.weight
                        order_detail[y].image_display = this.no_image
                        currency = order_detail[y].currency.name === 'IDR' ? 'Rp. ' : order_detail[y].currency.name === 'USD' ? '$' : ''
                        currency_format = order_detail[y].currency.name === 'IDR' ? this.currencyIDR : order_detail[y].currency.name === 'USD' ? this.currencyUSD : ''
                        order_detail[y].subTotal = currency + parseFloat(order_detail[y].price * order_detail[y].amount).toLocaleString(currency_format)
                        order_detail[y].display_price = currency + parseFloat(order_detail[y].price).toLocaleString(currency_format)
                        order_detail[y].is_dollar = is_dollar

                        if(is_dollar){
                            order_detail[y].subTotal_in_rupiah = 'Rp. ' + parseFloat(order_detail[y].price_in_rupiah * order_detail[y].amount).toLocaleString(this.currencyIDR)
                            order_detail[y].display_price_in_rupiah = 'Rp. ' + parseFloat(order_detail[y].price_in_rupiah).toLocaleString(this.currencyIDR)
                        }

                        var photo_product = []

                        if(photo_product.length === 0){
                            photo_product = order_detail[y].product.photo
                        }
                        else{
                            photo_product = order_detail[y].product_final.photo
                        }
                        for(var z in photo_product){
                            if(photo_product[z].is_primary){
                                order_detail[y].image_display = this.url_image + '/product?file_name=' + photo_product[z].file_name
                            }
                        }
                    }

                    data[x].footer_list = []
                    data[x].total_invoice = currency + parseFloat(data[x].grand_total).toLocaleString(currency_format)
                    if(is_dollar){
                        data[x].total_invoice += ' (Rp. ' + parseFloat(data[x].grand_total_in_rupiah).toLocaleString(this.currencyIDR) + ')'
                    }

                    if(data[x].shipping_discount != null || data[x].shipping_discount != 0){
                        var shipping_discount = 'Rp. ' + parseFloat(data[x].shipping_discount).toLocaleString(this.currencyIDR)
                        data[x].footer_list.push({title : 'Potongan Ongkir', value : shipping_discount})
                    }

                    data[x].footer_list.push({title : 'Total Belanja', value : data[x].total_invoice})


                    order_data_arr.push(data[x])
                }
                await this.setState({order_data_arr : order_data_arr, total_data : response.data.data.total, per_page : response.data.data.per_page})
                setTimeout(async () => {
                    await this.setState({is_loading : false})
                }, 750);
            }
            else{
                await this.setState({is_loading : false})
            }

        } catch (e) {
            await this.setState({is_loading : false})
            // console.log(e.message)
        }
    }

    async changeNav(index){
        // this.props.setAlert(JSON.stringify({is_show : false, message : '', type : ''}))
        var nav_menu = this.state.nav_menu
        for(var x in nav_menu){
            nav_menu[x].is_selected = false
        }
        nav_menu[index].is_selected = true

        await this.setState({nav_menu : nav_menu, order_data_arr : [], page : 1})

        await this.get_data_order(nav_menu[index].id)
    }

    async handlePageChange(page){
        var nav_menu = this.state.nav_menu
        var selected_menu = {id : ''}
        for(var x in nav_menu){
            if(nav_menu[x].is_selected){
                selected_menu = nav_menu[x]
            }
        }

        await this.setState({page : page})
        await this.get_data_order(selected_menu.id)
        window.scrollTo(0, 0);
    }

    async actionOrder(index, type){
        var order_data = this.state.order_data_arr
        order_data[index].due_date_format = this.moment(order_data[index].due_date).format('DD MMMM YYYY - HH:mm')

        var due_date = this.moment(order_data[index].due_date).unix()
        var current_time = this.moment().unix()
        var diff_time = due_date - current_time
        var duration = this.moment.duration(diff_time * 1000, 'milliseconds')
        var interval = 1000

        if(diff_time > 0){
            setInterval(async () => {
                duration = this.moment.duration(duration.asMilliseconds() - interval, 'millisecond')
                var h = this.moment.duration(duration).hours(), m = this.moment.duration(duration).minutes(), s = this.moment.duration(duration).seconds()

                h = this.$.trim(h).length === 1 ? '0' + h : h
                m = this.$.trim(m).length === 1 ? '0' + m : m
                s = this.$.trim(s).length === 1 ? '0' + s : s

                var countdown_timer = h + ':' + m + ':' + s
                await this.setState({countdown_timer : countdown_timer})

            }, interval);
        }

        var bank_info = []
        if(order_data[index].payment_method.code != null){
            var method = {title : order_data[index].payment_method.name, value : '', type : 'bank_name'}
            bank_info.push(method)
        }

        bank_info.push({title : 'Nomor' + (order_data[index].payment_method.type === 'VA' ? ' Virtual Account' : ' E-Wallet'), value : order_data[index].no_va_payment, type : 'account_number', method_type : order_data[index].payment_method.type})
        var grand_total = 0
        grand_total = order_data[index].grand_total

        console.log(order_data[index])

        await this.setState({order_selected : order_data[index], action_type : type, bank_info : bank_info, grand_total : grand_total})

        if(type === 'pay'){
            this.$('#modalPayNow').modal('show')
        }
        else if(type === 'complain'){
            this.$('#modalOrderComplain').modal('show')
        }
        else{
            this.$('#modalOrderConfirm').modal('show')
        }

    }

    async changeStatus(){
        var type = this.state.action_type
        var order_data = this.state.order_selected
        var data = {}
        data.id = order_data.id
        await this.setState({is_disabled : true})
        if(type === 'confirm'){

        }
        else if(type === 'cancel'){
            data.status = 'canceled'
        }
        else if(type === 'done'){
            data.status = 'accepted'
        }
        else if(type === 'complain'){
            data.status = 'complained'
        }
        try {
            var url = this.url + '/order'
            
            var response = await this.axios.put(url, data, this.state.optionsAxios);
      
            if (response.data.status === 'success') {
                window.location.reload()
            }
            else{
                await this.setState({is_disabled : false})
            }

        } catch (e) {
            await this.setState({is_disabled : false})
            // console.log(e.message)
        }
    }

    render(){
        const {
            nav_menu,
            order_data_arr,
            page,
            total_data,
            per_page,
            action_type,
            is_disabled,
            countdown_timer,
            order_selected,
            bank_info,
            grand_total,
            is_loading
        } = this.state
        return (
            <>
            <div className="row">

				<div className="col-12 mb-2">
					<h4>List Order</h4>
				</div>
                <div className="col-12">
                    <nav className='navTabProfile'>
                    <ul className="nav nav-tabs" id="myProfileTab" role="tablist">
                    {
                        nav_menu.map((data, index)=>(
                            <li className="nav-item" key={index}>
                                <a href={"#" + data.id} className={"nav-link" + (data.is_selected ? ' active' : '')} id={data.id + "-tab"} data-toggle="tab" role="tab" aria-controls={data.id} aria-selected={data.is_selected} onClick={()=>this.changeNav(index)}>{data.title}</a>
                            </li>
                        ))
                    }
                    </ul>
                    </nav>

                    <div className="tab-content mt-3" id="myProfileTabContent">
                        {
                            nav_menu.map((dataMenu, indexMenu)=>(
                                <div key={indexMenu} className={"tab-pane fade" + (dataMenu.is_selected ? ' show active' : '')} id={dataMenu.id} role="tabpanel" aria-labelledby={dataMenu.id + "-tab"}>
                                    <div className='row'>
                                        <div className="col-12">
                                            {
                                                !is_loading ?
                                                <>
                                                {
                                                    order_data_arr.length === 0 ?
                                                    <div className='text-center p-3 mt-3'>
                                                        <h5>No Data Found</h5>
                                                    </div> :
                                                    <>
                                                    {
                                                        order_data_arr.map((data, index)=>(
                                                            <ul className="list-group list-group-flush p-3 p-lg-4 mb-3 border" key={index}>
                                                                <li className="list-group-item pt-0 pb-3 pb-lg-4 border-top-0">
                                                                    <div className="row">
                                                                        <div className="col-6 p-0">
                                                                            <p className="m-0 font-weight-bold">{data.order_date}</p>
                                                                            <p className="m-0 font-weight-bold">#{data.id}</p>
                                                                        </div>
                                                                        <div className="col-6 p-0 text-right">
                                                                            <p className="m-0 font-weight-bold">{data.courier.code} - {data.packet}</p>
                                                                            {
                                                                                data.shipping_receipt_no != null ?
                                                                                <p className="m-0 shippingNumber">Shipping No.: {data.shipping_receipt_no}</p> : <></>
                                                                            }
                                                                            <p className="m-0 font-weight-bold d-none d-md-block">Rp. {parseFloat(data.shipping_fee).toLocaleString(this.currencyIDR)} ({ data.total_weight }Gr)</p>
                                                                        </div>
                                                                    </div>
                                                                </li>

                                                                {
                                                                    data.detail.map((dataDetail, indexDetail)=>(
                                                                        <li className="list-group-item py-3 py-lg-4" key={indexDetail}>
                                                                            <div className="row">
                                                                                <div className="col-12 col-lg-6">
                                                                                    <div className="row">
                                                                                        <div className="col-3 pl-0">
                                                                                            <div className="ratio-11" style={{backgroundImage : "url(" + dataDetail.image_display +")", backgroundRepeat : 'no-repeat'}} ></div>
                                                                                        </div>
                                                                                        <div className="col pl-0">
                                                                                            <div className="row d-flex h-100">
                                                                                                <div className="col-12 align-self-start">
                                                                                                    <div className="row">
                                                                                                        <div className="col-12">
                                                                                                            <p className="text-capitalize">{dataDetail.no_variant_name}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12 align-self-end">
                                                                                                    <p className="text-primary d-inline-block m-0 font-weight-bold">{dataDetail.display_price}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6 pr-0 d-none d-lg-flex flex-column justify-content-end align-items-end">
                                                                                    <div className="d-flex justify-content-start">
                                                                                        <p className="m-0">Qty: </p>
                                                                                        <p className="m-0 ml-2 text-primary font-weight-bold">{dataDetail.amount}</p>
                                                                                    </div>
                                                                                    <div className="d-flex">
                                                                                        <p className="m-0">Subtotal</p>
                                                                                        <p className="m-0 ml-2 text-primary font-weight-bold">{dataDetail.subTotal} {dataDetail.is_dollar ? '(' + dataDetail.subTotal_in_rupiah + ')' : ''}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    ))
                                                                }
                                                                <li className={"list-group-item p-0" + (dataMenu.id === 'wait_payment' || dataMenu.id === 'arrived' ? ' py-3 py-lg-4' : ' pt-3 pt-lg-4')}>
                                                                    <div className="row">
                                                                        <div className="col-auto d-flex align-middle align-items-center justify-content-end listOrder_footer">
                                                                            <a href={this.url + '/download/order?id=' + data.id} target='_blank' className="text-primary">View Invoice</a>
                                                                        </div>                                                                
                                                                        <div className="col text-right">
                                                                            {
                                                                                data.footer_list.map((dataFooter, indexFooter)=>(
                                                                                    <div key={indexFooter} className='listOrder_footer'>
                                                                                        <p className="d-inline-block m-0">{dataFooter.title}:</p>
                                                                                        <p className="d-inline-block m-0 ml-2 text-primary font-weight-bold">{dataFooter.value}</p>   
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </li>

                                                                {
                                                                    dataMenu.id === 'wait_payment' ? 
                                                                    <li className="list-group-item p-0 pt-3 pt-lg-4 border-bottom-0">
                                                                        <div className="row">
                                                                            <div className="col-auto d-none d-md-block">
                                                                                <p className="d-inline-block m-0"><b>Due Date : {this.moment(data.due_date).format('DD MMM YYYY - HH:mm')}</b></p>
                                                                            </div>
                                                                            <div className="col text-right">
                                                                                <button className="btn btn-sm btn-primary mr-2" onClick={()=>this.actionOrder(index, 'pay')}>Pay</button>
                                                                                <button className="btn btn-sm btn-outline-primary" onClick={()=>this.actionOrder(index, 'cancel')}>Cancel</button>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    :
                                                                    dataMenu.id === 'arrived' ?
                                                                    <li className="list-group-item p-0 pt-3 pt-lg-4 border-bottom-0">
                                                                        <div className="row">
                                                                            <div className="col text-right">
                                                                                <button className="btn btn-sm btn-danger mr-2" onClick={()=>this.actionOrder(index, 'complain')}>Complain</button>
                                                                                <button className="btn btn-sm btn-outline-primary" onClick={()=>this.actionOrder(index, 'done')}>Done</button>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    : <></>
                                                                }
                                                            </ul>

                                                        ))
                                                    }
                                                    </>
                                                }
                                                </>
                                                :
                                                <div className='text-center'>
                                                    <img src={this.loading} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
					</div>
                </div>
                

                {
                    total_data > 0 ?
                    <div className={'col-12 d-flex align-items-end flex-column'}>
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={this.state.page}
                            itemsCountPerPage={20}
                            totalItemsCount={this.state.total_data}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                        />
                    </div>
                    : <></>
                }

                <ModalConfirm actionBtn={()=>this.changeStatus()} action_type={action_type} is_disabled={is_disabled}  />
                <ModalOrderComplain order={order_selected} />

                <ModalPayNow countdown_timer={countdown_timer} order_selected={order_selected} due_date={order_selected.due_date_format} view_from={'list_order'} bank_info={bank_info} grand_total={grand_total} />

            </div>

            </>
        )
    }
}