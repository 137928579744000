import React from 'react';
import Base from '../Utils/Base';
import Alert from './Alert'

export default class ModalChooseAddress extends Base{

	state = {
		optionsAxios : this.optionsAxios,
        alert : {type : '', message : '', is_show : false},
        is_disabled : false,
        voucher_arr : [],
        selected_voucher : {id : ''},
        total_discount : 0,
        isLoading : true,
        voucher_code: '',
        voucher_data_type: '',
	}

	async componentDidMount(){
        var token = await localStorage.getItem('token')
        var optionsAxios = this.state.optionsAxios
        optionsAxios['headers']['Authorization'] = token
        await this.setState({optionsAxios : optionsAxios})

        var this1 = this
        this.$('#modalVoucher').on('show.bs.modal', async function (event) {
            this1.setState({alert : {type : '', message : '', is_show : false}})
            if(this1.state.voucher_arr.length == 0){
                if(this1.props.is_get_shipping_voucher){
                  await this1.get_voucher('shipping')
                  await this1.setState({ voucher_data_type: 'shipping', })
                }
                await this1.get_voucher()

                await this1.setState({isLoading : false})
            }
        })
        this.$('#modalVoucher').on('hide.bs.modal', async function (event) {
            var voucher = this1.state.selected_voucher
            this1.props.save_voucher(JSON.stringify(voucher), this1.state.total_discount)
        })
	}

    async get_voucher(type=''){
        try {
            var url = this.url + (type === '' ? '/voucher/all?type=web&is_show=1' : type === 'shipping' ? '/shipping-voucher/all?is_show=1' : '')
            url += '&code=' + this.state.voucher_code
      
            var response = await this.axios.get(url, this.state.optionsAxios);
      
            if (response.data.status === 'success') {
                var data = response.data.data
                var voucher_arr = this.state.voucher_arr
                for(var x in data){
                    data[x].is_selected = false
                    if(data[x].id === this.props.selected_voucher.id){
                        data[x].is_selected = true
                    }
                    data[x].voucherType = 'regular'
                    if(type === 'shipping'){
                        data[x].voucherType = 'shipping'
                    }
                    voucher_arr.push(data[x])
                }
                await this.setState({voucher_arr : voucher_arr})
            }

        } catch (e) {
            // console.log(e.message)
        }
    }

    async chooseVoucher(index){
        await this.setState({alert : {type : '', message : '', is_show : false}})
        var voucher = this.state.voucher_arr
        var subTotal = this.props.subTotal

        for(var x in voucher){
            if(x != index)
                voucher[x].is_selected = false
        }

        voucher[index].is_selected = !voucher[index].is_selected

        var selected_voucher = {id : ''}

        if(subTotal > voucher[index].min_payment){
            if(voucher[index].is_selected){
                selected_voucher = voucher[index]
            }

            var total_discount = 0

            if(selected_voucher.type_discount === "percentage"){
                total_discount = subTotal * (selected_voucher.discount/100)
                if(total_discount > selected_voucher.max_discount){
                    total_discount = selected_voucher.max_discount
                }
            }
            else if(selected_voucher.type_discount === "fixed"){
                total_discount = selected_voucher.discount
            }
            await this.setState({selected_voucher : selected_voucher, total_discount : total_discount})
        }
        else{
            await this.setState({alert : {type : 'danger', message : 'Min. Purchase Rp. ' + parseFloat(voucher[index].min_payment).toLocaleString(this.currencyIDR), is_show : true}})
        }

        await this.setState({voucher_arr : voucher})

    }

    async saveVoucher(){
        var voucher = this.state.selected_voucher

        if(!this.state.alert.is_show){
            this.props.save_voucher(JSON.stringify(voucher), this.state.total_discount)
            this.$('#modalVoucher').modal('hide')
        }
    }
    
    async onSearchCode(){
      this.setState({ voucher_arr: [] })
      this.get_voucher('shipping')
      this.get_voucher()
    }

    render(){
        const {
            voucher_arr,
            selected_voucher,
            alert,
            total_discount,
            isLoading
        } = this.state
        return (
        <>
            <div className="modal fade" id="modalVoucher" tabIndex="-1" aria-labelledby="modalVoucherLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalVoucherLabel">Voucher</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                      <div className='row'>
                        <div className='col-8'>
                          <input type="text" className="form-control text-capitalize" placeholder="Voucher Code" value={this.state.voucher_code} onChange={(e)=>this.setState({ voucher_code: e.target.value })} />
                        </div>
                        <div className='col-4'>
                          <button className='btn btn-primary w-100' onClick={() => this.onSearchCode()}>Search</button>
                        </div>
                      </div>
                        <div className='row m-0 mt-3'>
                            {
                                alert.is_show ? 
                                <div className='col-12 p-0'>
                                    <Alert type={alert.type} message={alert.message} />
                                </div>
                                : <></>
                            }
                            
                            <div className='col-12'>
                                {
                                    !isLoading &&
                                    <div className='row'>
                                        {
                                            voucher_arr.map((data, index)=>(
                                                <div className={'col-12 p-2 border rounded voucherList mb-2' + (data.is_selected ? ' selected' : '')} key={index} onClick={()=>this.chooseVoucher(index)}>
                                                    <p className='m-0 text-capitalize'><b>{data.name}</b></p>
                                                    <p className='m-0 text-capitalize'>{data.description}</p>
                                                </div>
                                            ))
                                        }

                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    {
                        selected_voucher.id !== '' && !alert.is_show ?
                        <>
                        <div className="modal-footer justify-content-start">
                            <div className='row w-100'>
                                <div className='col-auto'>
                                    <p className='m-0'>Total Discount</p>
                                    <p className='m-0'>Rp. {total_discount.toLocaleString(this.currencyIDR)}</p>
                                </div>
                                <div className='col'>
                                    <div className='align-items-center d-flex h-100 justify-content-end'>
                                    <button type="button" className="btn btn-sm btn-primary btn-text-sm" onClick={()=>this.saveVoucher()}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                        : <></>
                    }
                    </div>
                </div>
            </div>
        </>
        );
    }
}
