import React from 'react';
import Base from '../Utils/Base';

export default class CartList extends Base {

	state = {
	}

	async componentDidMount() {
	}

	render() {
		const {
			viewFrom,
			data,
			toProductDetail,
			removeCart,
			updateQty
		} = this.props
		return (
			<>
				<div className={'col-12 py-3'} style={{ borderBottom: '1px solid' }}>
					<div className='row'>
						<div className='col-4 col-md-3'>
							<div className='ratio-43' style={{ backgroundImage: "url(" + data.image_display + ")", cursor: 'pointer' }} onClick={() => toProductDetail()}></div>
							{/* <img src={data.image_display} style={{width : '100px', height : '69px'}} onClick={()=>toProductDetail()} /> */}
						</div>
						<div className='col'>
							<div className='row'>
								<div className='col'>
									<p className='m-0 text-capitalize'>{data.display_name}</p>
									{
										data.is_discount ?
											<>
												<p className='m-0'><del>{data.price_display}</del> <b>{data.price_display_after_disc}</b></p>
												<p className='m-0'><del>{data.idr_price_display}</del></p>
												<p className='m-0'><b>{data.idr_price_display_after_disc}</b></p>
											</>
											:
											<>
												<p className='m-0'>
												{this.props.current_currency === "USD" ? '$' : "Rp."} 
												{(this.props.current_currency === data.currency.name ? data.price : (data.currency.name === "IDR" ? data.price / this.props.current_rate : data.price * this.props.current_rate)).toLocaleString(this.props.current_currency === "IDR" ? 'id-ID' : 'en-US')}
												</p>
												<p className='m-0'>{data.idr_price_display}</p>
											</>
									}
									{
										viewFrom === 'checkout' ?
											<p className='m-0'>Order Qty : {data.amount}</p>
											: <></>
									}
								</div>
								<div className='col d-none d-md-block'>
									<div className='row'>
										<div className='col text-right'>
											<p className='m-0'>
												{this.props.current_currency === "USD" ? '$' : "Rp."} 
												{(this.props.current_currency === data.currency.name ? (data.price_after_discount != null ? data.price_after_discount : data.price) * data.amount : (data.currency.name === "IDR" ? (data.price_after_discount != null ? data.price_after_discount : data.price) * data.amount / this.props.current_rate : (data.price_after_discount != null ? data.price_after_discount : data.price) * data.amount * this.props.current_rate)).toLocaleString(this.props.current_currency === "IDR" ? 'id-ID' : 'en-US')}
											</p>
											<p className='m-0'>{data.idr_totalPrice}</p>
										</div>
										{
											viewFrom === 'cart' ?
												<div className='col-auto'>
													<span onClick={() => removeCart()}>
														<i className="fas fa-times" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
													</span>
												</div>
												: <></>
										}
									</div>
								</div>
								<div className='col d-block d-md-none'>
									<div className='row'>
										{
											viewFrom === 'cart' ?
												<div className='col text-right'>
													<span onClick={() => removeCart()}>
														<i className="fas fa-times" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
													</span>
												</div>
												: <></>
										}
									</div>
								</div>
							</div>

							{
								viewFrom === 'cart' ?
									<div className='row'>
										<div className='col-auto'>
											<div className="input-group border mt-3">
												<div className="input-group-prepend">
													<span className="input-group-text bg-white border-0" style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => updateQty('min')}>-</span>
												</div>
												<input type="text" className="form-control form-control-sm text-center border-0" value={data.amount} onChange={(e) => updateQty('type', e.target.value)} />
												<div className="input-group-append">
													<span className="input-group-text bg-white border-0" style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => updateQty('plus')}>+</span>
												</div>
											</div>
											{
												data.amount === data.qty ?
													<p><small><b>Stock availability reach maximum</b></small></p>
													: <></>
											}
										</div>
										<div className='col-12 d-block d-md-none text-right'>
											<p className='m-0'>{data.totalPrice}</p>
											<p className='m-0'>{data.idr_totalPrice}</p>
										</div>
									</div>
									: <></>
							}
						</div>
					</div>
				</div>
			</>
		);
	}
}
