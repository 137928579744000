import React from 'react'
import Base from '../Utils/Base'


export default class NaturallySimpleSection extends Base {

	async componentDidMount() {
	}

	render() {
		const {
			section2,
			section2_testimony_arr
		} = this.props

		return (
			<>
				<div className='container' id="naturallysimple">
					<div className='row'>

						{
							section2.map((data, index) => (
								<div className='col-12 ' key={index}>
									<div className='row'>
										<div className='col-12 mt-4 mt-md-5 px-4 px-md-0 section2 d-flex justify-content-center'>
											<p className='lh-18 fs-18px mw-90ch' dangerouslySetInnerHTML={{ __html: data.content }}></p>
										</div>
										<div className="col-12 mt-md-5">
											<div className='row mx-auto justify-content-center' id="ns_1">
												<div className='col-12 px-2 col-md-6 mt-5 mt-md-0 text-right'>
													<div className="ratio-43 d-md-block" style={{backgroundImage : "url(" + data.image +")"}} ></div>
													{/* <img src={data.image} style={{ height: '18rem' }} /> */}
													{/* <button className='btn position-absolute btn_primary_secondary' style={{ right: '-10%', bottom: '15%', zIndex: 1000, fontWeight: 'bold' }}>Learn More</button> */}
												</div>
												{
													section2_testimony_arr.map((dataTestimony, indexTestimony) => (
														<div className='col-12 order-first order-md-last col-md-5 p-2 px-md-5 p-md-0 section2' key={indexTestimony}>
															<p className='caption mw-45ch fs-13px' dangerouslySetInnerHTML={{ __html: dataTestimony.content }}></p>
															<h6 className='caption1'>{dataTestimony.title}</h6>
															<button className='btn btn_primary_secondary mt-3' style={{zIndex: 1000, fontWeight: 'bold' }} onClick={()=>window.location.href = '/sustainability'}>Learn More</button>
														</div>
													))
												}
											</div>
										</div> 
									</div>
								</div>
							))
						}
					</div>
				</div>
			</>
		)
	}
	}