import React from 'react';
import Base from '../Utils/Base';
import Alert from './Alert'

export default class ModalChooseAddress extends Base{

	state = {
		optionsAxios : this.optionsAxios,
        alert : {type : '', message : '', is_show : false},
        is_disabled : false,
        address_arr : [],
        selected_addr : {id : ''},
	}

	async componentDidMount(){
        var token = await localStorage.getItem('token')
        var optionsAxios = this.state.optionsAxios
        optionsAxios['headers']['Authorization'] = token
        await this.setState({optionsAxios : optionsAxios})

        var this1 = this
        this.$('#modalChooseAddress').on('show.bs.modal', async function (event) {
            await this1.setState({selected_addr : this1.props.selected_address})
            await this1.get_address()
        })
	}

    async get_address(){
        try {
            var url = this.url + '/user/address/all'
      
            var response = await this.axios.get(url, this.state.optionsAxios,
            );
      
            if (response.data.status === 'success') {
                var data = response.data.data
                for(var x in data){
                    data[x].is_selected = false
                    if(this.props.selected_address.id !== ''){
                        if(data[x].id === this.props.selected_address.id){
                            data[x].is_selected = true
                        }
                    }

                    if(data[x].city != null)
                      data[x].place = data[x].city.name + ', ' + data[x].city.province.name + ', ' + data[x].city.province.country.name
                    else if(data[x].province != null)
                      data[x].place = data[x].province.name + ', ' + data[x].province.country.name
                    else if(data[x].country != null)
                      data[x].place = data[x].country.name
                    // data[x].place = data[x].city.name + ', ' + data[x].city.province.name + ', ' + data[x].city.province.country.name
                }
                await this.setState({address_arr : data})
            }

        } catch (e) {
            // console.log(e.message)
        }
    }

    async setAlert(type, message){
        await this.setState({alert : {type : type, message : message, is_show : true}})
    }

    async chooseAddress(index){
        await this.setState({alert : {type : '', message : '', is_show : false}})
        var address = this.state.address_arr
        for(var x in address){
            address[x].is_selected = false
        }
        address[index].is_selected = true

        await this.setState({address_arr : address, selected_addr : address[index]})
    }

    async saveAddress(){
        var selected_addr = this.state.selected_addr
        if(selected_addr.id === ''){
            this.setAlert('danger', 'Choose 1 Address')
        }
        else{
            await this.props.saveAddress(selected_addr)
        }
    }

    render(){
        const {
            alert,
            address_arr,
        } = this.state
        return (
        <>
            <div className="modal fade" id="modalChooseAddress" tabIndex="-1" aria-labelledby="modalChooseAddressLabel" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalChooseAddressLabel">Choose Address</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className='row'>
                            <div className='col-12'>
                                {
                                    alert.is_show ? 
                                    <Alert type={alert.type} message={alert.message} />
                                    : <></>
                                }
                                <ul className="list-group">
                                    {
                                        address_arr.map((data, index)=>(
                                            <li className="list-group-item" key={index}>
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" id={"address" + index} className="custom-control-input" name='selectAddress' value={data.id} checked={data.is_selected} onChange={()=>this.chooseAddress(index)} />
                                                    <label className="custom-control-label w-100 pl-2" htmlFor={"address" + index}>
                                                        <p className="m-0"><b>{data.address}</b></p>
                                                        {
                                                          data.kecamatan != null && data.kelurahan != null &&
                                                          <p className="m-0">{data.kecamatan}, {data.kelurahan}</p>
                                                        }
                                                        <p className="m-0">{data.place}</p>
                                                        <p className="m-0">{data.detail}</p>
                                                    </label>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                                </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-secondary btn-text-sm" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-sm btn-primary btn-text-sm" onClick={()=>this.saveAddress()}>Save</button>
                    </div>
                    </div>
                </div>
            </div>
        </>
        );
    }
}
