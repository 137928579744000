import React from 'react'
import Base from '../Utils/Base'
import { tns } from 'tiny-slider/src/tiny-slider'

export default class HelpDetail extends Base{
    state = {
        data_arr : [{
            title : 'SHIPPING POLICY',
            detail : `I’m a shipping policy section. I’m a great place to update your customers about your shipping methods, packaging and costs. Use plain, straightforward language to build trust and make sure that your customers stay loyal!<br/><br/>I'm the second paragraph in your shipping policy section. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add details about your policy and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you.`
        },
        {
            title : 'Return & Exchange Policy',
            detail : `I’m a shipping policy section. I’m a great place to update your customers about your shipping methods, packaging and costs. Use plain, straightforward language to build trust and make sure that your customers stay loyal!<br/><br/>I'm the second paragraph in your shipping policy section. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add details about your policy and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you.`
        }],

    }

    async componentDidMount(){
    }

    render(){
        const {
            data_detail
        } = this.props
        return (
            <>
                <div className='row'>

                    {
                        data_detail.map((data, index)=>(
                            <div className='col-12' key={index}>
                                {
                                    data.title != '' ?
                                    <h4>{data.title}</h4>
                                    : <></>
                                }
                                <p className={index > 0 ? 'mt-4' : ''} dangerouslySetInnerHTML={{__html: data.detail}}></p>
                            </div>
                        ))
                    }

                </div>

            </>
        )
    }
}