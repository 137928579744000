import React from 'react'
import Base from '../Utils/Base'


export default class AboutUs extends Base{
    state = {
        data_about_us : {title : '', content : '', image : ''},
        data_detail_about_us : {title : '', content : '', image : ''},
    }

    async componentDidMount(){
        try {
            var url = this.url + '/blog/home?blog_home_type_name=about_us&is_publish=1'
      
            var response = await this.axios.get(url, this.optionsAxios);
      
            if (response.data.status == 'success') {
                var data = response.data.data.data
                for(var x in data){
                    if(data[x].file_name != null){
                        data[x].image = this.url_image + '/blog?file_name=' + data[x].file_name
                    }
                }
                if(data.length > 0){
                    await this.setState({data_about_us : data[0], data_detail_about_us : data[1]})
                }

            }

        } catch (e) {
            console.log(e.message)
        }
    }

    render(){
        const {
            data_about_us,
            data_detail_about_us
        } = this.state
        return (
            <>
            <section className="mt-5">
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <h2 className='text-uppercase'>A story of IDEALISM</h2>
                        </div>

                        <div className='col-12 d-block d-md-none mt-3'>
                            <div className="ratio-11" style={{backgroundImage : "url(" + data_detail_about_us.image +")", backgroundRepeat : 'no-repeat', backgroundSize : 'cover'}} ></div>
                        </div>

                        <div className='col-12 mt-5 d-none d-md-block'>
                            <h5 className='text-uppercase'>{data_about_us.title}</h5>
                            <p dangerouslySetInnerHTML={{__html: data_about_us.content}}></p>
                        </div>

                        <div className='col-12 mt-3 mt-md-5'>
                            <div className='row'>
                                <div className='col-6 d-none d-md-block'>
                                    <div className="ratio-11" style={{backgroundImage : "url(" + data_detail_about_us.image +")", backgroundRepeat : 'no-repeat', backgroundSize : 'cover'}} ></div>
                                </div>
                                <div className='col-12 col-md-6 px-md-3'>
                                    <h3 className='text-uppercase text-right'>{data_detail_about_us.title}</h3>
                                    <div className='mt-4 px-md-5' dangerouslySetInnerHTML={{__html: data_detail_about_us.content}}></div>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 mt-5 d-block d-md-none sustainAboutContent'>
                            <h5 className='text-uppercase'>{data_about_us.title}</h5>
                            <p dangerouslySetInnerHTML={{__html: data_about_us.content}}></p>
                        </div>

                    </div>
            </section>

            </>
        )
    }
}