import React from 'react';
import BaseLayout from './Layout/base';
// import logo from './logo.svg';
import Base from './Utils/Base'
// import './App.css';
// import Router from './Router/base_router'


export default class App extends Base {
  render(){
    return(
      <div className="App">
        {/* <Router /> */}

        <BaseLayout />
      </div>
    )
  }
}