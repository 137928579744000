import React from 'react'
import Base from '../Utils/Base'
import { tns } from 'tiny-slider/src/tiny-slider'

import DetailBlog from '../Components/Blog/Detail'
import BlogComment from '../Components/Blog/Comment'

export default class InThePressDetail extends Base{
    state = {
        token : '',
        optionsAxios : '',
        data_blog : {title : '', content : '', is_like : false, total_viewer : 0, total_comment : 0},
        is_disabled : true,
        comment : '',
        recent_arr : [],
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')

        var optionsAxios = this.optionsAxios
        if(token != null){
            optionsAxios['headers']['Authorization'] = token
        }

        await this.setState({token : token, optionsAxios : optionsAxios})

        await this.setView('blog', this.props.match.params.id)

        await this.get_data()
        await this.get_data('recent')

        if(this.state.recent_arr.length > 0){
            tns({
                responsive: {
                    0: {
                      items: 1,
                      gutter: 0,
                      edgePadding: 60,
                    },
                    992: {
                      items: 3,
                      gutter: 0,
                      edgePadding: 0,
                    },
                },
                container: ".recentBlog-carousel",
                center: true,
                loop: true,
                swipeAngle: false,
                nav : false,
                speed: 400,
                controls : false,
                autoplay : false,
                slideBy : 'page'
            });
        }
    }

    async get_data(type=''){
        try {
            var url = this.url + '/blog/in-press'
            if(type === ''){
                url += '?id=' + this.props.match.params.id
            }
            else if(type === 'recent'){
                var sort = [{"name": "created_at", "sort": "desc"}]
                url += '?not_id=' + this.props.match.params.id + '&sort=' + JSON.stringify(sort)
            }
      
            var response = await this.axios.get(url + '&is_publish=1', this.optionsAxios);
      
            if (response.data.status == 'success') {
                var data = response.data.data

                if(type === ''){
                    data.image_display = this.no_image
                    if(data.file_name != null){
                        data.image_display = this.url_image + '/blog?file_name=' + data.file_name
                    }
                    console.log(data)
                    data.is_like = (data.already_like != null ? data.already_like : false)
                    await this.setState({data_blog : data})
                }
                else if(type === 'recent'){
                    var data_recent = data.data
                    var arr = []
                    for(var x in data_recent){
                        if(arr.length < 10){
                            data_recent.image = this.no_image
                            if(data_recent[x].file_name != null){
                                data_recent[x].image = this.url_image + '/blog?file_name=' + data_recent[x].file_name
                            }
                            data_recent[x].is_like = (data_recent[x].already_like != null ? data_recent[x].already_like : false)
                            arr.push(data_recent[x])
                        }
                    }
                    await this.setState({recent_arr : arr})
                }
            }

        } catch (e) {
            console.log(e.message)
        }
    }

    async changeInput(value){
        var comment = value
        await this.setState({comment : comment, is_disabled : false})
    }

    async publishComment(){
        var token = this.state.token
        if(token == null){
            this.$('#modalAuth').modal('show')
        }
        else{
            try {
                var data = {
                    comment : this.state.comment,
                    blog_in_press : {id : this.props.match.params.id}
                }
    
                var url = this.url + '/blog/comment'
          
                var response = await this.axios.post(url, data, this.state.optionsAxios);
          
                if (response.data.status == 'success') {
                    window.location.reload()
                }
    
            } catch (e) {
                console.log(e.message)
            }
        }
    }

    async selectedRecent(index, type){
        var recent_arr = this.state.recent_arr
        if(type === 'view'){
            window.location.href = '/blog/' + recent_arr[index].id
        }
        else if(type === 'like'){
            var token = this.state.token
            if(token == null){
                this.$('#modalAuth').modal('show')
            }
            else{
                try {
                    var data = {
                        blog_in_press : {id : index === '' ? this.props.match.params.id : recent_arr[index].id}
                    }
        
                    var url = this.url + '/blog/like'
              
                    var response = await this.axios.post(url, data, this.state.optionsAxios);
              
                    if (response.data.status == 'success') {
                        window.location.reload()
                    }
        
                } catch (e) {
                    console.log(e.message)
                }
            }
        }
    }

    render(){
        const {
            data_blog,
            recent_arr,
            is_disabled
        } = this.state
        return (
            <>
            <section className="mt-5">
                    <div className='row'>

                        <div className='col-12 border p-3 p-md-5'>
                            <DetailBlog data_blog={data_blog} selectedRecent={()=>this.selectedRecent('', 'like')} />
                        </div>

                        <div className='col-12 p-0 mt-4'>
                            <div className="recentBlog-container position-relative">
                                <div className="recentBlog-carousel">
                                    {
                                        recent_arr.map((data, index)=>(
                                            <div className="card mb-3 px-2 border-0">
                                                <div className='border'>
                                                    <div className="ratio-43" style={{backgroundImage : "url(" + data.image +")", backgroundRepeat : 'no-repeat', cursor : 'pointer'}} onClick={()=>this.selectedRecent(index, 'view')}></div>
                                                    <div className="card-body">
                                                        <h5 className="card-title recentPost_title" onClick={()=>this.selectedRecent(index, 'view')}>{data.title}</h5>
                                                    </div>
                                                    <div className="card-footer bg-white border-0">
                                                        <div className='row m-0'>
                                                            <div className='col-12 border-top'>
                                                                <div className='row mt-2'>
                                                                    <div className='col-auto'>
                                                                        <p className='m-0'><i className="far fa-eye"></i> {data.total_viewer}</p>
                                                                    </div>
                                                                    <div className='col-auto'>
                                                                        <p className='m-0'><i className="far fa-comment-alt"></i> {data.total_comment}</p>
                                                                    </div>
                                                                    <div className='col text-right'>
                                                                        <span style={{cursor : 'pointer'}} onClick={()=>this.selectedRecent(index, 'like')}>
                                                                            {
                                                                                data.is_like ? 
                                                                                <i className={"fas fa-heart text-danger"}></i>
                                                                                :
                                                                                <i className={"far fa-heart text-danger"}></i>
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>


                        <div className='col-12 border p-5 mt-4'>
                            <BlogComment changeInput={(value)=>this.changeInput(value)} publishComment={()=>this.publishComment()} is_disabled={is_disabled} />
                        </div>

                    </div>
            </section>

            </>
        )
    }
}