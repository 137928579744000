import React from 'react'
import Base from '../Utils/Base'

import Pagination from "react-js-pagination";
import BlogList from '../Components/Blog/Blog'
import SearchBlog from '../Components/Blog/SearchBlog'

export default class InThePress extends Base{
    state = {
        data_blog : [],
        token : '',
        optionsAxios : this.optionsAxios,
        is_search : false,
        search : '',
        page : 1,
        total_data : 0,
        per_page : 0
    }
    search_callback = ''

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        var optionsAxios = this.optionsAxios

        if(token != null){
            optionsAxios['headers']['Authorization'] = token
        }

        await this.setState({token : token, optionsAxios : optionsAxios})
        await this.get_blog()
    }

    async get_blog(){
        try {
            var url = this.url + '/blog/in-press?search=' + this.state.search + '&page=' + this.state.page + '&is_publish=1'
      
            var response = await this.axios.get(url, this.state.optionsAxios);
      
            if (response.data.status == 'success') {
                var data_blog = []

                var data = response.data.data.data
                for(var x in data){
                    data[x].image_display = this.no_image
                    if(data[x].file_name != null){
                        data[x].image_display = this.url_image + '/blog?file_name=' + data[x].file_name
                    }
                    data[x].is_like = (data[x].already_like != null ? data[x].already_like : false)
                    
                    data_blog.push(data[x])
                }
                await this.setState({data_blog : data_blog, total_data : response.data.data.total, per_page : response.data.data.per_page})
            }

        } catch (e) {
            console.log(e.message)
        }
    }

    async selectedBlog(index, type){
        var data_blog = this.state.data_blog
        await this.setState({data_blog : data_blog})
        if(type === 'view'){
            window.location.href = '/in-the-press/' + data_blog[index].id
        }
        else if(type === 'like'){
            var token = this.state.token
            if(token == null){
                this.$('#modalAuth').modal('show')
            }
            else{
                try {
                    var data = {
                        blog_in_press : {id : data_blog[index].id}
                    }
        
                    var url = this.url + '/blog/like'
              
                    var response = await this.axios.post(url, data, this.state.optionsAxios);
              
                    if (response.data.status == 'success') {
                        window.location.reload()
                    }
        
                } catch (e) {
                    console.log(e.message)
                }
            }
        }
    }

    async searchBlog(){
        if(this.state.search !== ''){
            await this.setState({search : ''})
        }
        else if(this.state.search === ''){
            await this.setState({is_search : !this.state.is_search})
        }
    }

    async changeSearch(value){
        await this.setState({search : value, page : 1, data_blog : []})
        clearTimeout(this.search_callback)
        this.search_callback = setTimeout(async () => {
            await this.get_blog()
        }, 750);
    }

    async handlePageChange(page){
        await this.setState({page : page})
        await this.get_blog()
        window.scrollTo(0, 0);
    }

    render(){
        const {
            data_blog,
            is_search,
            search,
            per_page,
        } = this.state
        return (
            <>
            <section className="mt-5">
                    <div className='row'>

                        <div className='col-12 text-right'>
                            <SearchBlog is_search={is_search} searchBlog={()=>this.searchBlog()} changeSearch={(value)=>this.changeSearch(value)} search={search} />
                        </div>

                        <div className='col-12 mt-4'>
                            <div className='row'>

                                {
                                    data_blog.map((data, index)=>(
                                        <div className='col-12 mb-4' key={index} style={{border : '1px solid black'}}>
                                            <BlogList data={data} selectedBlog={(type)=>this.selectedBlog(index, type)} />
                                        </div>
                                    ))
                                }

                            </div>
                        </div>

                        <div className={'col-12 d-flex align-items-end flex-column p-0'}>
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.page}
                                itemsCountPerPage={20}
                                totalItemsCount={this.state.total_data}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>

                    </div>
            </section>

            </>
        )
    }
}