import React from 'react'
import Base from '../../Utils/Base'
import Alert from '../../Components/Alert'

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

export default class ProfilePersonalInfo extends Base{
    state = {
        is_disabled : false,
        alert : {is_show : false, message : '', type : ''},
    }

    async setAlert(is_show, type='', message=''){
        var alert = {is_show : is_show, message : message, type : type}
        await this.props.setAlert(JSON.stringify(alert))
    }

    async saveData(){
        var data = this.props.user_data
        if(data.name === ''){
            await this.setAlert(true, 'danger', 'Name cannot be empty')
        }
        else if(data.phone === ''){
            await this.setAlert(true, 'danger', 'Phone cannot be empty')
        }
        else if(data.birth_date === ''){
            await this.setAlert(true, 'danger', 'Birth date cannot be empty')
        }
        else{
            try {
                await this.setState({is_disabled : true})
                var url = this.url + '/auth/change-profile'
    
                var token = await localStorage.getItem('token')
                var optionsAxios = this.optionsAxios
                optionsAxios['headers']['Authorization'] = token

                data.birth_date = this.moment(data.birth_date).format()

                console.log(JSON.stringify(data))
          
                var response = await this.axios.put(url, data, optionsAxios,);
    
                if (response.data.status === 'success') {
                    await this.setAlert(true, 'success', 'Success. Please Wait...')

                    var completeProfile = await localStorage.getItem('completeProfile')
                    if(completeProfile != null){
                        setTimeout(() => {
                            window.history.back()
                        }, 1000);
                    }
                    else{
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000);
                    }
                }
                else{
                    await this.setState({is_disabled : false})
                }
    
            } catch (e) {
                await this.setState({is_disabled : false})
                // console.log(e.message)
            }
        }
    }

    render(){
        const {
            user_data,
            changePersonalInfo,
            alert,
            changePicture,
            birth_date
        } = this.props
        const {
            is_disabled,
        } = this.state
        return (
            <>
            <div className="row m-0">
                <div className='col-12 p-3'>
                    <div className="row">
                        {
                            alert.is_show ? 
                            <div className='col-12'>
                                <Alert type={alert.type} message={alert.message} />
                            </div>
                            : <></>
                        }
                        <div className='col-12'>

                            <div className='row'>
                                <div className='col-12 col-md-3'>
                                    <div className='row'>
                                        <div className='col-6 offset-3 col-md-12 offset-md-0'>
                                            <div className="ratio-11 rounded-circle" style={{backgroundImage : "url(" + user_data.image_display +")", backgroundRepeat : 'no-repeat'}} ></div>
                                        </div>
                                        <div className='col-12 mt-3'>
                                            <input type="file" accept="image/*" className="d-none" id="changeProfilePicture" onChange={(event)=>changePicture(event)} />
                                            <button className='btn btn-outline-primary btn-text-sm w-100' onClick={()=>this.$('#changeProfilePicture').trigger('click')}>Change Picture</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-9 mt-3 mt-md-0'>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <div className="row px-3">
                                                <label className="col-lg-4 pl-0 m-0 d-flex align-items-center">Full Name</label>
                                                <input type="text" className="form-control col-lg-8 text-capitalize" placeholder="Full Name" value={user_data.name} onChange={(e)=>changePersonalInfo(e.target.value, 'name')} />
                                            </div>
                                        </div>

                                        <div className="form-group col-12">
                                            <div className="row px-3">
                                                <label className="col-lg-4 pl-0 m-0 d-flex align-items-center">Email</label>
                                                <input type="email" className="form-control col-lg-8" placeholder="example@example.com" disabled value={user_data.email} onChange={(e)=>changePersonalInfo(e.target.value, 'email')} />
                                            </div>
                                        </div>

                                        <div className="form-group col-12">
                                            <div className="row px-3">
                                                <label className="col-lg-4 pl-0 m-0 d-flex align-items-center">Phone</label>
                                                <input type="tel" className="form-control col-lg-8" placeholder="0812345678xxx" value={user_data.phone} onChange={(e)=>changePersonalInfo(e.target.value, 'phone')} />
                                            </div>
                                        </div>
                                        <div className="form-group col-12">
                                            <div className="row px-3">
                                                <label className="col-lg-4 pl-0 m-0 d-flex align-items-center">Date of Birth</label>
                                                <div className='col-lg-8 p-0' id='editProfileBirthDate'>
                                                    <DatePicker
                                                        selected={birth_date}
                                                        onChange={date => changePersonalInfo(date, 'birth_date')}
                                                        maxDate={new Date()}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        timeFormat="HH:mm"
                                                        dateFormat="dd MMMM yyyy"
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="form-group col-12 text-right">
                                            <button type="button" className="btn btn-primary" onClick={()=>this.saveData()} disabled={is_disabled}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                        </div>

                    </div>
                </div>
            </div>

            </>
        )
    }
}