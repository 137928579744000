import React from 'react'
import Base from '../Utils/Base'

export default class NoData extends Base{
    state = {
    }

    async componentDidMount(){
    }

    render(){
        return (
            <>
            <div className="text-center">
                <h5>No Data Found</h5>
            </div>
            </>
        )
    }
}