import React, { Component } from 'react'
// import '../App.css'
import axios from 'axios'
import moment from 'moment'
import Jquery from 'jquery'

import 'popper.js'
import 'bootstrap'
import Helper from './Helper'

export default class Base extends Component {

	url = 'https://api.lumoshliving.com';
	url_image = this.url + '/image';
	timeoutAxios = 300000
	currencyIDR = 'id-ID'
	currencyUSD = 'us-US'
	current_currency = ''
	helper = new Helper()

	current_rate = ''

	optionsAxios = {
		timeout: this.timeoutAxios,
		headers: { 'Content-Type': 'application/json' },
	}

	loading = require('../assets/images/loading.svg')

	img_lumosh_logo = require('../assets/images/lumosh_logo.jpeg')

	no_image_profile = require('../assets/images/no_profile_picture.png')

	no_image = require('../assets/images/no_image_available.jpeg')
	img_banner = require('../assets/images/banner.webp')
	img_naturally_simple = require('../assets/images/naturally_simple.webp')

	img_handcrafted = require('../assets/images/handcrafted.webp')
	img_food = require('../assets/images/food.webp')

	img_shop_header = require('../assets/images/shop_header.webp')

	axios = axios
	moment = moment
	$ = Jquery

	async setView(type, id = '') {
		try {
			var url = this.url + '/' + type + '-viewer'
			var data = {}

			if (type === 'product') {
				data.product = { id: id }
			}
			else if (type === 'blog') {
				data.blog = { id: id }
			}

			var response = await this.axios.post(url, data, this.optionsAxios);

			// if (response.data.status === 'success') {
			// }
		} catch (e) {
			// console.log(e.message)
		}
	}
	
	// getBase64(file, callback) {
	// 	let reader = new FileReader();
	// 	reader.readAsDataURL(file);
	// 	reader.onload = function () {
	// 		callback(reader.result)
	// 	};
	// 	reader.onerror = function (error) {
	// 		console.log('Error: ', error);
	// 	};
	// }

	async preloader(type) {
		if (type === 'open') {
			this.$('#modalPreloader').modal('show')
		}
		else {
			setTimeout(() => {
				this.$('#modalPreloader').modal('hide')
			}, 750);
		}
	}

	
}