import React from 'react'
import Base from '../../Utils/Base'
import Alert from '../../Components/Alert'

export default class ProfilePassword extends Base{
    state = {
        is_disabled : false,
        alert : {is_show : false, message : '', type : ''},
        old_password_is_show : false,
        new_password_is_show : false,
    }

    async setAlert(is_show, type='', message=''){
        var alert = {is_show : is_show, message : message, type : type}
        await this.props.setAlert(JSON.stringify(alert))
    }

    async saveData(){
        var data = this.props.password_data
        if(data.old_password === ''){
            await this.setAlert(true, 'danger', 'Old Password cannot be empty')
        }
        else if(data.new_password === ''){
            await this.setAlert(true, 'danger', 'New Password cannot be empty')
        }
        else if(data.new_password.length < 6){
            await this.setAlert(true, 'danger', `Password must be 6 characters or more`)
        }
        else{
            try {
                await this.setState({is_disabled : true})
                var url = this.url + '/auth/change-password'
    
                var token = await localStorage.getItem('token')
                var optionsAxios = this.optionsAxios
                optionsAxios['headers']['Authorization'] = token
          
                var response = await this.axios.put(url, data, optionsAxios,);
    
                if (response.data.status === 'success') {
                    await this.setAlert(true, 'success', 'Success. Please Wait...')
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                }
                else{
                    await this.setAlert(true, 'danger', response.data.message)
                    await this.setState({is_disabled : false})
                }
    
            } catch (e) {
                await this.setState({is_disabled : false})
                // console.log(e.message)
            }
        }
    }

    async showPassword(type){
        if(type === 'old'){
            await this.setState({old_password_is_show : !this.state.old_password_is_show})
        }
        else if(type === 'new'){
            await this.setState({new_password_is_show : !this.state.new_password_is_show})
        }
    }

    render(){
        const {
            alert,
            password_data,
            changePassword
        } = this.props
        const {
            is_disabled,
            old_password_is_show,
            new_password_is_show,
        } = this.state
        return (
            <>
            <div className="row m-0">
                <div className='col-12 p-3'>
                    <div className="row">
                        {
                            alert.is_show ? 
                            <div className='col-12'>
                                <Alert type={alert.type} message={alert.message} />
                            </div>
                            : <></>
                        }
                        <div className='col-12'>
                            
                            <div className="row">
                                <div className="form-group col-12">
                                    <div className="row px-3">
                                        <div className="input-group">
                                            <label className="col-lg-4 pl-0 m-0 d-flex align-items-center">Old Password</label>
                                            <input type={!old_password_is_show ? 'password' : 'text'} className="form-control col-lg-8 border-right-0" placeholder="Old Password" value={password_data.old_password} onChange={(e)=>changePassword(e.target.value, 'old_password')} />
                                            <div className="input-group-append">
                                                <span className={"input-group-text bg-transparent border-left-0 " + (!old_password_is_show ? 'd-none' : 'd-block')} onClick={()=>this.showPassword('old')}>
                                                    <i className="fas fa-eye"></i>
                                                </span>
                                                <span className={"input-group-text bg-transparent border-left-0 " + (old_password_is_show ? 'd-none' : 'd-block')} onClick={()=>this.showPassword('old')}>
                                                    <i className="fas fa-eye-slash"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-12">
                                    <div className="row px-3">
                                        <div className="input-group">
                                            <label className="col-lg-4 pl-0 m-0 d-flex align-items-center">New Password</label>
                                            <input type={!new_password_is_show ? 'password' : 'text'} className="form-control col-lg-8 border-right-0" placeholder="New Password" value={password_data.new_password} onChange={(e)=>changePassword(e.target.value, 'new_password')} />
                                            <div className="input-group-append">
                                                <span className={"input-group-text bg-transparent border-left-0 " + (!new_password_is_show ? 'd-none' : 'd-block')} onClick={()=>this.showPassword('new')}>
                                                    <i className="fas fa-eye"></i>
                                                </span>
                                                <span className={"input-group-text bg-transparent border-left-0 " + (new_password_is_show ? 'd-none' : 'd-block')} onClick={()=>this.showPassword('new')}>
                                                    <i className="fas fa-eye-slash"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-12 text-right">
                                    <button type="button" className="btn btn-primary" onClick={()=>this.saveData()} disabled={is_disabled}>Save</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            </>
        )
    }
}