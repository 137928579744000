import React from 'react'
import Base from '../../Utils/Base'
import Alert from '../../Components/Alert'

import ModalAddAddress from '../../Components/ModalAddAddress'
import ModalConfirmDelete from '../../Components/ModalConfirmDelete'
import ModalConfirm from '../../Components/ModalOrderConfirm'

export default class ProfileAddressList extends Base{
    state = {
        is_disabled : false,
        alert : {is_show : false, message : '', type : ''},
        selected_address : {
            country : {id : ''},
            province : {id : ''},
            city : {id : ''},
            address : '',
            detail : '',
            id : '',
        },
        action : '',
    }

    async setAlert(is_show, type='', message=''){
        var alert = {is_show : is_show, message : message, type : type}
        await this.props.setAlert(JSON.stringify(alert))
    }

    async deleteAddress(){
        var data = this.state.selected_address
        try {
            await this.setState({is_disabled : true})
            var url = this.url + '/user/address?id=' + data.id

            var token = await localStorage.getItem('token')
            var optionsAxios = this.optionsAxios
            optionsAxios['headers']['Authorization'] = token
      
            var response = await this.axios.delete(url, optionsAxios);

            if (response.data.status === 'success') {
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
            else{
                await this.setState({is_disabled : false})
            }

        } catch (e) {
            await this.setState({is_disabled : false})
            // console.log(e.message)
        }
    }

    async addressAction(type, index=''){
        await this.setState({action : type})
        if(type === 'edit' || type === 'delete'){
            var address = this.props.address_list
            await this.setState({selected_address : address[index]})

            if(type === 'edit'){
                this.$('#modalAddAddress').modal('show')
            }
            else if(type === 'delete'){
                this.$('#modalConfirmDelete').modal('show')
            }
        }
        else if(type === 'add'){
            await this.setState({selected_address : {
                country : {id : ''},
                province : {id : ''},
                city : {id : ''},
                address : '',
                detail : '',
                id : '',
            }})
            this.$('#modalAddAddress').modal('show')
        }
        else if(type === 'set_primary'){
            var address = this.props.address_list
            await this.setState({selected_address : address[index]})
            this.$('#modalOrderConfirm').modal('show')
        }
    }

    async setPrimary(){
        var data = this.state.selected_address
        data.is_primary = 1
        try {
            await this.setState({is_disabled : true})
            var url = this.url + '/user/address'

            var token = await localStorage.getItem('token')
            var optionsAxios = this.optionsAxios
            optionsAxios['headers']['Authorization'] = token
      
            var response = await this.axios.put(url, data, optionsAxios);

            if (response.data.status === 'success') {
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
            else{
                await this.setState({is_disabled : false})
            }

        } catch (e) {
            await this.setState({is_disabled : false})
            // console.log(e.message)
        }
    }

    render(){
        const {
            address_list
        } = this.props
        const {
            is_disabled,
            selected_address,
            action
        } = this.state
        return (
            <>
            <div className="row m-0">
                <div className='col-12 p-3'>
                    <div className="row">
                        <div className='col-12'>
                            
                            <ul className="list-group list-group-flush">

                                {
                                    address_list.map((data, index)=>(
                                        <>
                                        <li className="list-group-item px-0 px-lg-3 py-3" key={index}>
                                            <p className="m-0 d-inline-block"><b>#{ index+1 }</b></p>
                                            <p className="mb-0 d-inline-block ml-1 text-capitalize"><b>{data.address}</b></p>
                                            {
                                              data.kecamatan != null && data.kelurahan != null &&
                                              <p className="mb-0 text-capitalize">{data.kecamatan}, {data.kelurahan}</p>
                                            }
                                            <p className="mb-0 text-capitalize">{data.place}</p>
                                            <p className="mb-2 text-capitalize">{data.zipcode == null || data.zipcode == '' ? '-' : data.zipcode}</p>
                                            <p className="mb-2 text-capitalize">{data.detail}</p>
                                            <a className="btn btn-outline-primary d-inline-block px-2 px-md-4" href="#!" role="button" onClick={()=>this.addressAction('edit', index)}>Edit</a>
                                            {
                                                !data.is_primary ? 
                                                <>
                                                <a className="btn btn-outline-primary d-inline-block ml-2 px-2 px-md-4" href="#!" role="button" onClick={()=>this.addressAction('delete', index)}>Delete</a>
                                                <a className="btn btn-primary d-inline-block ml-2 px-2 px-md-4" href="#!" role="a" onClick={()=>this.addressAction('set_primary', index)}>Set as Primary</a>
                                                </>
                                                : <></>
                                            }
                                        </li>
                                        </>
                                    ))
                                }

                                <li className="list-group-item px-0 px-lg-3 py-3">
                                    <a id="Add" className="btn btn-primary px-4" href="#!" data-toggle="modal" data-target="#modalAddress" onClick={()=>this.addressAction('add')}>Tambah Alamat</a>
                                </li>
                            </ul>

                        </div>

                    </div>
                </div>
            </div>

            <ModalAddAddress action={action} selected_address={selected_address} />
            <ModalConfirmDelete deleteBtn={()=>this.deleteAddress()} is_disabled={is_disabled} />
            <ModalConfirm action_type={'confirm'} actionBtn={()=>this.setPrimary()} is_disabled={is_disabled} />

            </>
        )
    }
}