import React from 'react'
import Base from '../Utils/Base'
import ModalAddAddress from '../Components/ModalAddAddress'
import ModalChooseAddress from '../Components/ModalChooseAddress'
import ModalVoucher from '../Components/ModalVoucher'
import ModalPayment from '../Components/ModalPayment'
import ModalCardPayment from '../Components/ModalCardPayment'
import ModalCompleteProfile from '../Components/ModalCompleteProfile'

import Select from '../Components/Select'

import ListCart from '../Components/CartList'
import OrderSummary from '../Components/OrderSummary'

import ModalPayNow from '../Components/ModalPayNow'
import { data } from 'jquery'
import Xendit from 'xendit-js-node';

export default class Checkout extends Base {
	state = {
		token: '',
		optionsAxios: this.optionsAxios,
		data_arr: [],
		subTotal: 0,
		grand_total: 0,
		selected_address: { id: '' },
		cart_arr: [],
		courier_arr: [],
		courier_selected: { id: '' },
		courier_packet_arr: [],
		courier_packet_selected: { id: '' },
		courier_alert: { is_show: false, message: '' },
		order_data: {},

		current_currency : '',
		current_rate: 0,
		card_number: '',
		card_expired_month: '',
		card_expired_year: '',
		card_cvv: '',
		card_token: '',

		selected_paymentMethod: { id: '' },
		is_method_alert: { type: '', message: '', is_show: false },
		is_disabled: false,
		address_arr: [],
		selected_voucher: { id: '' },
		loading_courier: false,

		order_summary_data: [],
		total_discount: 0,

		due_date: '',
		countdown_timer: '',
		total_weight: 0,

		bank_info: [],
		user_data: { id: '', name: '', email: '', phone: '' },
		type_addressModal: '',
		is_loading: false,
		order_alert: '',
		order_response: { id: '' },
		selected_voucher_type: '',
		is_get_shipping_voucher: false,
		setting_data: { id: '', shipping_discount_max: '', min_purchase_shipping_discount: '', shipping_discount_is_publish: 0 }
	}

	async componentDidMount() {
		// await localStorage.clear()
		var token = await localStorage.getItem('token')
		if (token == null && this.state.token === '') {
			window.history.back()
		}

		var currency = await localStorage.getItem('currency_selected')
    if(currency != null){
			await this.setState({current_currency : currency})
			if(currency === 'USD'){
				await this.get_rate()
			}
    }
    else{
			await this.setState({current_currency : 'IDR'})
    }

		var completeProfile = await localStorage.getItem('completeProfile')
		if (completeProfile != null) {
			window.location.reload()
			await localStorage.removeItem('completeProfile')
		}

		var cart_data = await localStorage.getItem('cart')
		cart_data = JSON.parse(cart_data)

		var order_data = await localStorage.getItem('order')
		order_data = JSON.parse(order_data)

		if (cart_data == null) {
			window.location.href = '/'
		}

		var optionsAxios = this.state.optionsAxios
		optionsAxios['headers']['Authorization'] = token
		await this.setState({ token: token, optionsAxios: optionsAxios, order_data: order_data })

		await this.get_setting()
		await this.get_auth()

		var subTotal = 0

		var total_weight = 0

		for (var x in cart_data) {
			cart_data[x].currencyFormat = (cart_data[x].currency.name === 'IDR' ? this.currencyIDR : this.currencyUSD)

			var idr_price = ''

			var price_currency = cart_data[x].currency.name === 'IDR' ? 'Rp. ' : '$'
			cart_data[x].is_discount = false
			cart_data[x].price_display = price_currency + parseFloat(cart_data[x].price).toLocaleString(cart_data[x].currency.name === 'IDR' ? this.currencyIDR : this.currencyUSD)
			idr_price = cart_data[x].price

			if (cart_data[x].price_after_discount != null) {
				cart_data[x].is_discount = true
				cart_data[x].price_display_after_disc = price_currency + parseFloat(cart_data[x].price_after_discount).toLocaleString(cart_data[x].currency.name === 'IDR' ? this.currencyIDR : this.currencyUSD)
				idr_price = cart_data[x].price_after_discount
			}

			var price = cart_data[x].is_discount ? cart_data[x].price_after_discount : cart_data[x].price
			cart_data[x].totalPrice = (cart_data[x].currency.name === 'IDR' ? 'Rp. ' : '$') + parseFloat(price * cart_data[x].amount).toLocaleString(cart_data[x].currencyFormat)

			cart_data[x].idr_totalPrice = ''
			cart_data[x].idr_price_display = ''
			cart_data[x].idr_price_display_after_disc = ''
			if (cart_data[x].currency.name === 'USD') {
				idr_price = cart_data[x].price_in_rupiah
				var idr_price_discount = cart_data[x].price_in_rupiah_after_discount
				cart_data[x].idr_price_display = 'Rp. ' + parseFloat(idr_price).toLocaleString(this.currencyIDR)
				if (cart_data[x].is_discount) {
					cart_data[x].idr_price_display_after_disc = 'Rp. ' + parseFloat(idr_price_discount).toLocaleString(this.currencyIDR)
					idr_price = idr_price_discount
				}
				cart_data[x].idr_totalPrice = 'Rp. ' + parseFloat(idr_price * cart_data[x].amount).toLocaleString(this.currencyIDR)
			}

			subTotal += cart_data[x].amount * idr_price

			total_weight += cart_data[x].product.weight * cart_data[x].amount
		}
		console.log(cart_data)

		var grand_total = subTotal

		if (cart_data == null) {
			cart_data = []
		}

		var order_summary_data = [{ title: 'Subtotal', value: subTotal, type: 'plus' }]
		var setting = this.state.setting_data
		if (setting.shipping_discount_is_publish == 1) {
			if (subTotal > setting.min_purchase_shipping_discount) {
				order_summary_data.push({ title: 'Discount Shipping', value: setting.shipping_discount_max, type: 'min' })
				grand_total -= setting.shipping_discount_max
			}
		}


		await this.setState({ cart_arr: cart_data, subTotal: subTotal, grand_total: grand_total, order_summary_data: order_summary_data, total_weight: total_weight })

		await this.get_address()
		await this.get_courier()
	}

	async get_rate(){
    try {
        var url = this.url + '/exchange/last'

        var response = await this.axios.get(url, this.optionsAxios);

        if (response.data.status == 'success') {
            await this.setState({current_rate : response.data.data.price})
        }

    } catch (e) {
        console.log(e.message)
    }
  }

	async get_auth() {
		try {
			var url = this.url + '/auth/profile'

			var response = await this.axios.get(url, this.state.optionsAxios);

			if (response.data.status == 'success') {
				var data = response.data.data
				await this.setState({ user_data: data })
			}
			else if (response.data.status == 'error') {
				await localStorage.clear()
				await this.setState({ token: null, user_data: { id: '', name: '', email: '' } })
			}

		} catch (e) {
			console.log(e.message)
		}
	}

	async get_setting() {
		try {
			var url = this.url + '/setting'

			var response = await this.axios.get(url, this.state.optionsAxios);

			if (response.data.status == 'success') {
				var data = response.data.data
				if (!data.shipping_discount_is_publish) {
					await this.setState({ is_get_shipping_voucher: true })
				}

				await this.setState({ setting_data: data })
			}

		} catch (e) {
			console.log(e.message)
		}
	}

	async get_address() {
		try {
			var url = this.url + '/user/address/all'

			var response = await this.axios.get(url, this.state.optionsAxios,
			);

			if (response.data.status === 'success') {
				var data = response.data.data
				var selected_addr = { id: '' }
				for (var x in data) {
					if (data[x].is_primary) {
						selected_addr = data[x]
						if(selected_addr.city != null)
              selected_addr.place = selected_addr.city.name + ', ' + selected_addr.city.province.name + ', ' + selected_addr.city.province.country.name
            else if(selected_addr.province != null)
              selected_addr.place = selected_addr.province.name + ', ' + selected_addr.province.country.name
            else if(selected_addr.country != null)
              selected_addr.place = selected_addr.country.name
						// selected_addr.place = data[x].city.name + ', ' + data[x].city.province.name + ', ' + data[x].city.province.country.name
					}
				}

				await this.setState({ selected_address: selected_addr, address_arr: data })
			}

		} catch (e) {
			// console.log(e.message)
		}
	}

	async get_courier(type = '') {
		try {
			var url = this.url + '/courier/all?is_enabled=1'

			if (type === 'packet') {
				url = this.url + '/order/shipping-cost?destination=' + this.state.selected_address.city_id + '&weight=' + this.state.total_weight + '&courier_id=' + this.state.courier_selected.id
			}

			var response = await this.axios.get(url, this.optionsAxios,
			);

			if (response.data.status === 'success') {
				var data = response.data.data

				if (type === '') {
					for (var x in data) {
						data[x].name = data[x].code + ' (' + data[x].name + ')'
					}
					await this.setState({ courier_arr: data })
				}
				else if (type === 'packet') {
					for (var x in data) {
						data[x].is_selected = false
						data[x].fee = data[x].cost[0].value
						data[x].etd = (data[x].cost[0].etd === '1-1' ? '1' : data[x].cost[0].etd)
					}

					await this.setState({ loading_courier: false })
					await this.setState({ courier_packet_arr: data })
				}
			}

		} catch (e) {
			// console.log(e.message)
		}
	}

	async pickAddress(type) {
		if (type === 'new') {
			await this.setState({ type_addressModal: 'add' })
			this.$('#modalAddAddress').modal('show')
		}
		else if (type === 'choose') {
			if (this.state.address_arr.length > 0) {
				this.$('#modalChooseAddress').modal('show')
			}
			else {
				await this.setState({ type_addressModal: 'add' })
				this.$('#modalAddAddress').modal('show')
			}
		}
		else if (type === 'edit') {
			await this.setState({ type_addressModal: 'edit' })
			this.$('#modalAddAddress').modal('show')
		}
	}

	async saveSelectedAddr(value) {
		await this.setState({ selected_address: value })
		this.$('#modalChooseAddress').modal('hide')
	}

	async changeCourier(value, type) {
		await this.setState({ courier_alert: { is_show: false, message: '' } })
		if (type === 'courier') {
			var subTotal = this.state.subTotal
			grand_total = subTotal - this.state.total_discount

			var order_summary_data = this.state.order_summary_data
			var flag = false
			for (var x in order_summary_data) {
				if (order_summary_data[x].title === 'Shipping') {
					order_summary_data.splice(x, 1)
				}
			}

			await this.setState({ courier_selected: { id: value }, grand_total: grand_total, order_summary_data: order_summary_data, courier_packet_selected: { id: '' } })

			if (this.state.selected_address.id !== '') {
				await this.setState({ loading_courier: true, courier_packet_arr: [] })
				await this.get_courier('packet')
			}
		}
		else if (type === 'packet') {
			var courier_packet = this.state.courier_packet_arr
			for (var x in courier_packet) {
				courier_packet[x].is_selected = false
			}
			courier_packet[value].is_selected = true

			var grand_total = 0
			var subTotal = this.state.subTotal
			grand_total = subTotal + courier_packet[value].fee

			var order_summary_data = [{ title: 'Subtotal', value: this.state.subTotal, type: 'plus' }]
			var flag = false

			if (this.state.total_discount != 0) {
				if (this.state.selected_voucher_type === 'regular') {
					order_summary_data.push({ title: 'Discount Voucher', value: this.state.total_discount, type: 'min' })
					grand_total -= this.state.total_discount
				}
			}
			for (var x in order_summary_data) {
				if (order_summary_data[x].title === 'Shipping') {
					order_summary_data[x].value = courier_packet[value].fee
					flag = true
				}
			}

			if (!flag) {
				order_summary_data.push({ title: 'Shipping', value: courier_packet[value].fee, type: 'plus' })
			}
			var shipping_discount = 0
			if (this.state.total_discount != 0) {
				if (this.state.selected_voucher_type === 'shipping') {
					shipping_discount = (courier_packet[value].fee < this.state.total_discount ? courier_packet[value].fee : this.state.total_discount)
					order_summary_data.push({ title: 'Discount Shipping', value: shipping_discount, type: 'min' })
					grand_total -= shipping_discount
				}
			}
			var setting = this.state.setting_data
			if (setting.shipping_discount_is_publish == 1) {
				if (subTotal > setting.min_purchase_shipping_discount) {
					shipping_discount = (courier_packet[value].fee < setting.shipping_discount_max ? courier_packet[value].fee : setting.shipping_discount_max)
					order_summary_data.push({ title: 'Discount Shipping', value: shipping_discount, type: 'min' })
					grand_total -= shipping_discount
				}
			}

			await this.setState({ courier_packet_arr: courier_packet, courier_packet_selected: courier_packet[value], grand_total: grand_total, order_summary_data: order_summary_data })
		}

	}

	async useVoucher() {
		this.$('#modalVoucher').modal('show')
	}

	async toPayment() {
		var user_data = this.state.user_data
		if (user_data.name === '' || user_data.phone === '') {
			await localStorage.setItem('completeProfile', 1)
			this.$('#modalCompleteProfile').modal('show')
		}
		else if (this.state.selected_address.id === '') {
			if (this.state.address_arr.length > 0) {
				this.$('#modalChooseAddress').modal('show')
			}
			else {
				this.$('#modalAddAddress').modal('show')
			}
		}
		else if (this.state.courier_selected.id === '') {
			await this.setState({ courier_alert: { is_show: true, message: 'Choose 1 Courier' } })
		}
		else if (this.state.courier_packet_selected.id === '') {
			await this.setState({ courier_alert: { is_show: true, message: 'Choose 1 Courier Packet' } })
		}
		else {
			this.$('#modalPayment').modal('show')
		}
	}

	async changePaymentMethod(value) {
		await this.setState({ selected_paymentMethod: value, is_method_alert: { type: '', message: '', is_show: false } })
	}

	async pay() {
		var selected_paymentMethod = this.state.selected_paymentMethod
		if (selected_paymentMethod.id === '') {
			await this.setState({ is_method_alert: { type: 'danger', message: 'Choose 1 Method', is_show: true } })
		}
		// else if (selected_paymentMethod.type === 'Credit_Card' && this.state.card_token === ""){
		// 	this.$('#modalPayment').modal('hide')
		// 	this.$('#modalCardPayment').modal('show')
		// }
		else {
			var dataPayment = this.state.order_data
			var courier_packet = this.state.courier_packet_selected
			dataPayment.courier = { id: this.state.courier_selected.id }
			dataPayment.packet = courier_packet
			dataPayment.user_address = { id: this.state.selected_address.id }
			dataPayment.payment_method = { id: selected_paymentMethod.id }
			dataPayment.notes = dataPayment.notes == null ? '' : dataPayment.notes
			dataPayment.credit_card_token = this.state.card_token

			console.log(this.state.selected_voucher)

			if (this.state.selected_voucher.id !== '') {
				if (this.state.selected_voucher.voucherType === 'regular') {
					dataPayment.voucher = { id: this.state.selected_voucher.id }
				}
				else if (this.state.selected_voucher.voucherType === 'shipping') {
					dataPayment.shipping_voucher = { id: this.state.selected_voucher.id }
				}
			}
			try {
				await this.setState({ is_loading: true })
				var url = this.url + '/order'

				var response = await this.axios.post(url, dataPayment, this.state.optionsAxios);

				if (response.data.status === 'success') {
					this.$('#modalPayment').modal('hide')
					this.$('#modalPayNow').modal('show')

					var data = response.data.data

					await this.setState({ due_date: this.moment(data.due_date).format('DD MMMM YYYY - HH:mm') })

					var due_date = this.moment(data.due_date).unix()
					var current_time = this.moment().unix()
					var diff_time = due_date - current_time
					var duration = this.moment.duration(diff_time * 1000, 'milliseconds')
					var interval = 1000

					if (diff_time > 0) {
						setInterval(async () => {
							duration = this.moment.duration(duration.asMilliseconds() - interval, 'millisecond')
							var h = this.moment.duration(duration).hours(), m = this.moment.duration(duration).minutes(), s = this.moment.duration(duration).seconds()

							h = this.$.trim(h).length === 1 ? '0' + h : h
							m = this.$.trim(m).length === 1 ? '0' + m : m
							s = this.$.trim(s).length === 1 ? '0' + s : s

							var countdown_timer = h + ':' + m + ':' + s
							await this.setState({ countdown_timer: countdown_timer })

						}, interval);
					}

					// bank_info : {method : '', account_number : ''},
					var bank_info = []
					if (data.payment_method.code != null) {
						var method = { title: data.payment_method.name, value: '', type: 'bank_name' }
						bank_info.push(method)
					}

					var acc_number = data.payment_method.type === 'VA' ? data.no_va_payment : this.state.user_data.phone
					bank_info.push({ title: 'Nomor' + (data.payment_method.type === 'VA' ? ' Virtual Account' : ' E-Wallet'), value: acc_number, type: 'account_number', method_type: data.payment_method.type })

					await this.setState({ bank_info: bank_info, order_response: data })


					await localStorage.removeItem('cart')
					await localStorage.removeItem('order')
				}
				else {
					this.$('#modalPayment').modal('hide')
					await this.setState({ is_loading: false, order_alert: response.data.message })
					this.$('#modalOrderError').modal('show')
				}

			} catch (e) {
				await this.setState({ is_loading: false })
				// console.log(e.message)
			}
		}
	}

	async save_voucher(value, total_discount) {
		var voucher = JSON.parse(value)
		var subTotal = this.state.subTotal
		var order_summary_data = [{ title: 'Subtotal', value: subTotal, type: 'plus' }]

		if (voucher.voucherType === 'regular') {
			order_summary_data.push({ title: 'Discount Voucher', value: total_discount, type: 'min' })
		}

		var courier_fee = 0

		if (this.state.courier_packet_selected.id !== '') {
			order_summary_data.push({ title: 'Shipping', value: this.state.courier_packet_selected.fee, type: 'plus' })
			courier_fee = this.state.courier_packet_selected.fee
		}

		if (voucher.voucherType === 'shipping') {
			order_summary_data.push({ title: 'Discount Shipping', value: total_discount, type: 'min' })
		}

		var grand_total = 0
		grand_total = subTotal - total_discount + courier_fee

		var setting = this.state.setting_data
		if (setting.shipping_discount_is_publish == 1) {
			if (subTotal > setting.min_purchase_shipping_discount) {
				var shipping_discount = (this.state.courier_packet_selected.fee < setting.shipping_discount_max ? this.state.courier_packet_selected.fee : setting.shipping_discount_max)
				order_summary_data.push({ title: 'Discount Shipping', value: shipping_discount, type: 'min' })
				grand_total -= shipping_discount
			}
		}


		await this.setState({ selected_voucher: voucher, order_summary_data: order_summary_data, total_discount: total_discount, grand_total: grand_total, selected_voucher_type: voucher.voucherType })
	}

	async credit_card_token(){
		Xendit.setPublishableKey(this.helper.xendit_api_key);

		Xendit.card.createToken({
			amount: 10000,
      card_number: this.state.card_number,
      card_exp_month: this.state.card_expired_month,
      card_exp_year: '20' + this.state.card_expired_year,
      card_cvn: this.state.card_cvv,
      is_multiple_use: false,
      should_authenticate: false,
		}, async (err, token) => {
			// console.log(err)
			// console.log(token)
			if(token != null && token.id != null){
				await this.setState({ card_token: token.id })
				this.pay()
			}
		});
	}

	async card_change_input(val, type){
		// console.log(val1)
		if(type === "card_number"){
			var val1 = this.helper.number_string_validation(val, 16)
			await this.setState({ card_number: val1 })
		}
		else if(type === "card_expired_month"){
			var val1 = this.helper.number_string_validation(val, 2, 0, 12)
			await this.setState({ card_expired_month: val1 })
		}
		else if(type === "card_expired_year"){
			var val1 = this.helper.number_string_validation(val, 2, 0, 28)
			await this.setState({ card_expired_year: val1 })
		}
		else if(type === "card_cvv"){
			var val1 = this.helper.number_string_validation(val, 3)
			await this.setState({ card_cvv: val1 })
		}
	}

	async copyAccountNumber() {
		var copyText = document.getElementById("accountNumber");
		console.log(copyText)
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		document.execCommand("copy");
		console.log('copied ' + copyText.value)
	}

	render() {
		const {
			selected_address,
			cart_arr,
			courier_arr,
			courier_selected,
			courier_packet_arr,
			courier_packet_selected,
			subTotal,
			grand_total,
			is_method_alert,
			is_disabled,
			order_summary_data,
			due_date,
			countdown_timer,
			courier_alert,
			bank_info,
			loading_courier,
			type_addressModal,
			selected_voucher,
			is_loading,
			user_data,
			order_alert,
			order_response,
			is_get_shipping_voucher
		} = this.state
		return (
			<>

				<div className='row'>

					<div className='col-12 p-3 p-md-5'>
						<div className='row'>

							<div className='col-12 col-md-8 px-4'>
								<p className='pb-3' style={{ borderBottom: '1px solid' }}>Checkout</p>

								<div className='row m-0'>

									<div className='col-12 border p-3'>
										<p className='m-0'>Alamat Pengiriman : </p>
										<div className='row'>
											<div className='col-12'>
												<p className="m-0 text-capitalize"><b>{selected_address.address}</b></p>
												{
													selected_address.kecamatan != null && selected_address.kelurahan != null &&
													<p className="m-0 text-capitalize">{selected_address.kecamatan}, {selected_address.kelurahan}</p>
												}
												<p className="m-0 text-capitalize">{selected_address.place}</p>
												<p className="m-0 text-capitalize">{selected_address.detail}</p>
											</div>
											<div className='col-12 mt-3 text-right'>
												{
													selected_address.id != '' ?
														<button className='btn btn-sm btn-outline-primary d-inline-block ml-1' onClick={() => this.pickAddress('edit')}>Edit Alamat</button>
														: <></>
												}
												<button className='btn btn-sm btn-outline-primary d-inline-block ml-1' onClick={() => this.pickAddress('new')}>Tambah Alamat</button>
												<button className='btn btn-sm btn-outline-primary d-inline-block ml-1' onClick={() => this.pickAddress('choose')}>Pilih Alamat</button>
											</div>
										</div>
									</div>

									<div className='col-12 border p-3 mt-3'>
										<p className='m-0'>Cart : </p>
										<div className='row mt-2'>
											{
												cart_arr.map((data, index) => (
													<>
														<ListCart
															viewFrom={'checkout'}
															current_currency={this.state.current_currency}
															current_rate={this.state.current_rate}
															data={data}
															key={index} />
													</>
												))
											}
										</div>
									</div>

									<div className='col-12 border p-3 mt-3'>
										<p className='m-0'>Courier :
											{
												courier_alert.is_show ?
													<small className='text-danger'> <b>{courier_alert.message}</b></small>
													: <></>
											}
										</p>
										<div className='row mt-2'>
											<div className='col-12'>
												<div className="form-group">
													<Select data={courier_selected} id_select={'courier_select'} changeInput={(value) => this.changeCourier(value, 'courier')} data_arr={courier_arr} title={'Choose Courier'} />
												</div>
											</div>
											{
												loading_courier ?
													<div className='col-12'>
														<img src={this.loading} style={{ height: '4rem' }} />
													</div>
													: <></>
											}
											<div className='col-12'>
												{
													courier_packet_arr.map((data, index) => (
														<div className="custom-control custom-radio">
															<input type="radio" id={"courier" + index} className="custom-control-input" name='selectCourier' value={data.id} checked={data.is_selected} onChange={() => this.changeCourier(index, 'packet')} />
															<label className="custom-control-label w-100 pl-2" htmlFor={"courier" + index}>
																<p className="m-0"><b>{data.service} - Rp. {parseFloat(data.fee).toLocaleString(this.currencyIDR)}</b></p>
																<p style={{ margin: '-.5rem 0 0' }}><small>Estimation : {data.etd} day(s)</small></p>
															</label>
														</div>
													))
												}
											</div>
										</div>
									</div>

								</div>
							</div>

							<OrderSummary
								viewFrom={'checkout'}
								current_currency={this.state.current_currency}
								current_rate={this.state.current_rate}
								order_summary_data={order_summary_data}
								grand_total={grand_total}
								useVoucher={() => this.useVoucher()}
								toPayment={() => this.toPayment()} />
						</div>
					</div>

				</div>

				<ModalAddAddress action={type_addressModal} selected_address={selected_address} set_primary={true} view_from={'checkout'} />
				<ModalChooseAddress selected_address={selected_address} saveAddress={(value) => this.saveSelectedAddr(value)} />
				<ModalVoucher subTotal={subTotal} save_voucher={(value, total_discount) => this.save_voucher(value, total_discount)} selected_voucher={selected_voucher} is_get_shipping_voucher={is_get_shipping_voucher} />
				<ModalPayment 
					change_payment={(value) => this.changePaymentMethod(value)} 
					pay={() => this.pay()} 
					current_currency={this.state.current_currency}
					current_rate={this.state.current_rate}
					is_method_alert={is_method_alert} 
					is_disabled={is_disabled} 
					is_loading={is_loading} />
				<ModalCardPayment 
					grand_total={grand_total / this.state.current_rate}
					card_number={this.state.card_number}
					card_expired_month={this.state.card_expired_month}
					card_expired_year={this.state.card_expired_year}
					card_cvv={this.state.card_cvv}
					changeInput={(val, type) => this.card_change_input(val, type)}
					pay={() => this.pay()}
					is_method_alert={is_method_alert} 
					is_disabled={is_disabled} 
					is_loading={is_loading} />

				<ModalPayNow countdown_timer={countdown_timer} order_selected={order_response} due_date={due_date} view_from={'checkout'} bank_info={bank_info} grand_total={grand_total} user_data={user_data} />
				<ModalCompleteProfile />

				<div className="modal fade" id="modalOrderError" tabIndex="-1" aria-labelledby="modalOrderErrorLabel" aria-hidden="true" data-backdrop="static" data-keyboard='false'>
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-body">
								<h6 className='m-0'>{order_alert}</h6>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-sm btn-secondary btn-text-sm" onClick={() => window.location.href = '/transaction/cart'}>Close</button>
								{/* <a href='/profile' className="btn btn-primary">Edit Profile</a> */}
							</div>
						</div>
					</div>
				</div>

			</>
		)
	}
}