import React from 'react'
import Base from '../../Utils/Base'

export default class BlogList extends Base{
    state = {
    }

    async componentDidMount(){
    }

    render(){
        const {
            data,
            selectedBlog
        } = this.props
        return (
            <>
            <div className='row'>
                <div className='col-12 col-md-5 p-0' onClick={()=>selectedBlog('view')}>
                    <div className="ratio-43 d-md-block" style={{backgroundImage : "url(" + data.image_display +")", backgroundRepeat : 'no-repeat', cursor : 'pointer'}} ></div>
                </div>
                <div className='col-12 col-md-7 p-0'>
                    <div className='row m-0'>
                        <div className='col-12 p-4'>
                            <div className='row m-0'>
                                <div className='col-12 mb-3'>
                                    <div className='row'>
                                        <div className='col-auto' style={{cursor : 'pointer'}} onClick={()=>console.log('profile')}>
                                            <div className='row'>
                                                <div className='col-auto'>
                                                    <img src={this.no_image_profile} className='rounded-circle' style={{height : '2.75rem'}} />
                                                </div>
                                                <div className='col-auto p-0'>
                                                    <small>Lumosh Ceramics <i className="fas fa-crown"></i></small><br/>
                                                    {/* <small>Feb 26 - 1 min</small> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col text-right'>
                                            <span onClick={()=>console.log('more')} style={{cursor : 'pointer'}}><i className="fas fa-ellipsis-v"></i></span>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 p-0' style={{cursor : 'pointer'}}>
                                    <div className='row m-0'>
                                        <div className='col-12'>
                                            <h4 className='blogList_title' onClick={()=>selectedBlog('view')}>{data.title}</h4>
                                        </div>
                                        <div className='col-12 mt-2'>
                                            <p className='m-0 blogList_description' dangerouslySetInnerHTML={{__html: data.content}}></p>
                                        </div>

                                        <div className='col-12 mt-4'>
                                            <div className='row m-0'>
                                                <div className='col-12 border-top p-0'>

                                                    <div className='row mt-2'>
                                                        <div className='col-auto'>
                                                            <p>{data.total_viewer} views</p>
                                                        </div>
                                                        <div className='col-auto'>
                                                            <p>{data.total_comment} comments</p>
                                                        </div>
                                                        <div className='col text-right'>
                                                            <span style={{cursor : 'pointer'}} onClick={()=>selectedBlog('like')}>
                                                                {
                                                                    data.is_like ? 
                                                                    <i className={"fas fa-heart text-danger"}></i>
                                                                    :
                                                                    <i className={"far fa-heart text-danger"}></i>
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}