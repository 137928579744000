import React from 'react';
import Base from '../Utils/Base';
import Alert from './Alert'
// import Select from './Select'
import Select from 'react-select'

export default class ModalAddAddress extends Base{

	state = {
		optionsAxios : this.optionsAxios,
        add_form : {
            country : {id : '', value : ''},
            province : {id : '', value : ''},
            city : {id : '', value : ''},
            address : '',
            detail : '',
            zipcode : '',
            kecamatan : '',
            kelurahan : '',
        },
        country_arr : [],
        province_arr : [],
        city_arr : [],
        alert : {type : '', message : '', is_show : false},
        is_disabled : false,
        is_loading : false,
	}

	async componentDidMount(){
        var token = await localStorage.getItem('token')
        var optionsAxios = this.state.optionsAxios
        optionsAxios['headers']['Authorization'] = token
        await this.setState({optionsAxios : optionsAxios})

        var this1 = this
        this.$('#modalAddAddress').on('show.bs.modal', async function (event) {
            await this1.setState({alert : {type : '', message : '', is_show : false}})
            await this1.get_place('country')
            if(this1.props.action === 'add'){
                await this1.setState({add_form : {country : {id : '', value : ''}, province : {id : '', value : ''}, city : {id : '', value : ''}, address : '', detail : '', zipcode : ''}})
            }
            else if(this1.props.action === 'edit'){
                var selected_address = this1.props.selected_address

                if(selected_address.id !== ''){
                    await this1.setState({is_loading : true})
                    selected_address.city = selected_address.city
                    selected_address.city.label = selected_address.city.name
                    selected_address.city.value = selected_address.city.id

                    selected_address.province = selected_address.city.province
                    selected_address.province.label = selected_address.city.province.name
                    selected_address.province.value = selected_address.city.province.id

                    selected_address.country = selected_address.city.province.country
                    selected_address.country.label = selected_address.city.province.country.name
                    selected_address.country.value = selected_address.city.province.country.id

                    selected_address.zipcode = (selected_address.zipcode == null ? '' : selected_address.zipcode)

                    
                    await this1.setState({add_form : selected_address})

                    await this1.get_place('province')
                    await this1.get_place('city')

                    setTimeout(async () => {
                        await this1.setState({is_loading : false})
                    }, 500);
                }
                
            }
        })
	}

    async get_place(type){
        try {
            var url = this.url + '/' + type + '/all'

            if(type === 'province'){
                url += '?country_id=' + this.state.add_form.country.id
            }
            else if(type === 'city'){
                url += '?province_id=' + this.state.add_form.province.id
            }
      
            var response = await this.axios.get(url, this.state.optionsAxios,
            );
      
            if (response.data.status === 'success') {
                var data = response.data.data

                for(var x in data){
                    data[x].label = data[x].name
                    data[x].value = data[x].id
                }
                if(type === 'country')
                    await this.setState({country_arr : data, province_arr: [], city_arr: [],})
                else if(type === 'province')
                    await this.setState({province_arr : data, city_arr: [],})
                else if(type === 'city')
                    await this.setState({city_arr : data})
            }

        } catch (e) {
            // console.log(e.message)
        }
    }

    async changeInput(value, type){
        await this.setState({alert : {type : '', message : '', is_show : false, is_disabled : false}})

        var add_form = this.state.add_form
        if(type === 'country'){
            var val = JSON.parse(value)
            add_form[type] = val
            
            add_form['province'] = {id : '', value : ''}
            add_form['city'] = {id : '', value : ''}

            await this.setState({province_arr : []})
            await this.get_place('province')
        }
        else if(type === 'province'){
            var val = JSON.parse(value)
            add_form[type] = val

            add_form['city'] = {id : '', value : ''}

            await this.setState({city_arr : []})
            await this.get_place('city')
        }
        else if(type === 'city'){
            var val = JSON.parse(value)
            add_form[type] = val            
        }
        else if(type === 'zipcode'){
            if(value.length <= 5){
                add_form[type] = value
            }
        }
        else{
            add_form[type] = value
        }

        await this.setState({add_form : add_form})
    }

    async saveAddress(){
        var add_form = this.state.add_form
        if(add_form.country.id === '')
            await this.setAlert('danger', 'Country cannot be empty')
        else if(this.state.province_arr.length > 0 && add_form.province.id === '')
            await this.setAlert('danger', 'Province cannot be empty')
        else if(this.state.city_arr.length > 0 && add_form.city.id === '')
            await this.setAlert('danger', 'City cannot be empty')
        else if(add_form.address === '')
            await this.setAlert('danger', 'Address cannot be empty')
        else if(this.state.city_arr.length > 0 && add_form.kecamatan === '')
            await this.setAlert('danger', 'Kecamatan cannot be empty')
        else if(this.state.city_arr.length > 0 && add_form.kelurahan === '')
            await this.setAlert('danger', 'Kelurahan cannot be empty')
        else if(add_form.zipcode === '')
            await this.setAlert('danger', 'Zip code cannot be empty')
        else{
            await this.setState({is_disabled : true})
            try {
                var url = this.url + '/user/address'
                var data = add_form
                data.detail = (data.detail == '' ? null : data.detail)
                data.is_primary = this.props.set_primary != null ? this.props.set_primary : false

                var response = ''
                if(this.props.action === 'add'){
                    response = await this.axios.post(url, data, this.state.optionsAxios);
                }
                else{
                    if(this.props.view_from == 'checkout'){
                        data.is_primary = 1
                    }

                    response = await this.axios.put(url, data, this.state.optionsAxios);
                }
        
        
                if (response.data.status === 'success') {
                    await this.setAlert('success', 'Success')

                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                }
                else{
                    await this.setState({is_disabled : false})
                }
    
            } catch (e) {
                await this.setState({is_disabled : false})
                // console.log(e.message)
            }
        }   
    }

    async setAlert(type, message){
        await this.setState({alert : {type : type, message : message, is_show : true}})
    }

    render(){
        const {
            add_form,
            country_arr,
            province_arr,
            city_arr,
            alert,
            is_disabled,
            is_loading
        } = this.state
        return (
        <>
            <div className="modal fade" id="modalAddAddress" tabIndex="-1" aria-labelledby="modalAddAddressLabel" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalAddAddressLabel">{this.props.action === 'add' ? 'Add' : 'Edit'} New Address</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className='row'>
                            <div className='col-12'>
                                {
                                    alert.is_show ? 
                                    <Alert type={alert.type} message={alert.message} />
                                    : <></>
                                }

                                {
                                    is_loading ? 
                                    <div className='text-center'>
                                        <img src={this.loading} />
                                    </div>
                                    :
                                    <form>
                                        <div className="form-group">
                                            <label>Country<sup className='text-danger'>*</sup></label>
                                            {/* <Select data={add_form.country} changeInput={(value)=>this.changeInput(value, 'country')} data_arr={country_arr} title={'Choose Country'} /> */}
                                            <Select options={country_arr} value={add_form.country} onChange={(value)=>this.changeInput(JSON.stringify(value), 'country')} />
                                        </div>
                                        <div className={"form-group" + (province_arr.length == 0 ? ' d-none' : '')}>
                                            <label>Province<sup className='text-danger'>*</sup></label>
                                            {/* <Select data={add_form.province} changeInput={(value)=>this.changeInput(value, 'province')} data_arr={province_arr} title={'Choose Province'} /> */}
                                            <Select options={province_arr} value={add_form.province} onChange={(value)=>this.changeInput(JSON.stringify(value), 'province')} />
                                        </div>
                                        <div className={"form-group" + (city_arr.length == 0 ? ' d-none' : '')}>
                                            <label>City<sup className='text-danger'>*</sup></label>
                                            {/* <Select data={add_form.city} changeInput={(value)=>this.changeInput(value, 'city')} data_arr={city_arr} title={'Choose City'} /> */}
                                            <Select options={city_arr} value={add_form.city} onChange={(value)=>this.changeInput(JSON.stringify(value), 'city')} />
                                        </div>
                                        <div className={"form-group" + (add_form.city.id != null && add_form.city.id != '' ? '' : ' d-none')}>
                                            <label>Kecamatan<sup className='text-danger'>*</sup></label>
                                            <input type="text" className="form-control form-control-sm text-capitalize" placeholder="Kecamatan" onChange={(value)=>this.changeInput(value.target.value, 'kecamatan')} value={add_form.kecamatan} />
                                        </div>
                                        <div className={"form-group" + (add_form.city.id != null && add_form.city.id != '' ? '' : ' d-none')}>
                                            <label>Kelurahan<sup className='text-danger'>*</sup></label>
                                            <input type="text" className="form-control form-control-sm text-capitalize" placeholder="Kelurahan" onChange={(value)=>this.changeInput(value.target.value, 'kelurahan')} value={add_form.kelurahan} />
                                        </div>
                                        <div className="form-group">
                                            <label>Zip Code <sup className='text-danger'>*</sup></label>
                                            <input type="number" className="form-control form-control-sm" placeholder="Zip Code" onChange={(value)=>this.changeInput(value.target.value, 'zipcode')} value={add_form.zipcode} />
                                        </div>
                                        <div className="form-group">
                                            <label>Address<sup className='text-danger'>*</sup></label>
                                            <input type="text" className="form-control form-control-sm text-capitalize" placeholder="Address" onChange={(value)=>this.changeInput(value.target.value, 'address')} value={add_form.address} />
                                        </div>
                                        <div className="form-group">
                                            <label>Detail</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Detail" onChange={(value)=>this.changeInput(value.target.value, 'detail')} value={add_form.detail} />
                                        </div>
                                        
                                    </form>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-secondary btn-text-sm" disabled={is_disabled} data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-sm btn-primary btn-text-sm" disabled={is_disabled} onClick={()=>this.saveAddress()}>Save</button>
                    </div>
                    </div>
                </div>
            </div>
        </>
        );
    }
}
