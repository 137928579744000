import React from 'react'
import Base from '../../Utils/Base'

import PersonalInformation from './PersonalInformation'
import AddressList from './AddressList'
import Password from './Password'

export default class ProfileView extends Base{
    state = {
        nav_menu : [
            {id : 'personalInformation', title : 'Personal Information', is_selected : true},
            {id : 'daftarAlamat', title : 'Address List', is_selected : false},
            {id : 'password', title : 'Password', is_selected : false},
        ],
        address_arr : [],
        password_data : {
            old_password : '',
            new_password : '',
        },
    }

    async componentDidMount(){

    }

    async changeNav(id){
        this.props.setAlert(JSON.stringify({is_show : false, message : '', type : ''}))
        if(id === 'personalInformation'){
            this.props.get_auth()
        }
        else if(id === 'daftarAlamat'){
            await this.get_address()
        }
        else if(id === 'password'){
            await this.setState({password_data : {
                old_password : '',
                new_password : '',
            }})
        }
    }

    async get_address(){
        try {
            var url = this.url + '/user/address'

            var token = localStorage.getItem('token')
            var optionsAxios = this.optionsAxios
            optionsAxios['headers']['Authorization'] = token
      
            var response = await this.axios.get(url, optionsAxios,
            );
      
            if (response.data.status === 'success') {
                var data = response.data.data.data

                for(var x in data){
                    if(data[x].city != null)
                      data[x].place = data[x].city.name + ', ' + data[x].city.province.name + ', ' + data[x].city.province.country.name
                    else if(data[x].province != null)
                      data[x].place = data[x].province.name + ', ' + data[x].province.country.name
                    else if(data[x].country != null)
                      data[x].place = data[x].country.name
                }

                await this.setState({address_arr : data})
            }

        } catch (e) {
            // console.log(e.message)
        }
    }

    async changePassword(value, type){
        this.props.setAlert(JSON.stringify({is_show : false, message : '', type : ''}))
        var password = this.state.password_data
        password[type] = value
        await this.setState({password_data : password})
    }

    render(){
        const {
            nav_menu,
            address_arr,
            password_data
        } = this.state
        const {
            user_data,
            changePersonalInfo,
            setAlert,
            alert,
            changePicture,
            birth_date
        } = this.props
        return (
            <>
            <div className="row">

				<div className="col-12 mb-2">
					<h4>Profile</h4>
				</div>
                <div className="col-12">
                    <nav className='navTabProfile'>
                    <ul className="nav nav-tabs" id="myProfileTab" role="tablist">
                    {
                        nav_menu.map((data, index)=>(
                            <li className="nav-item" key={index}>
                                <a href={"#" + data.id} className={"nav-link" + (data.is_selected ? ' active' : '')} id={data.id + "-tab"} data-toggle="tab" role="tab" aria-controls={data.id} aria-selected={data.is_selected} onClick={()=>this.changeNav(data.id)}>{data.title}</a>
                            </li>
                        ))
                    }
                    </ul>
                    </nav>

                    <div className="tab-content" id="myProfileTabContent">
                        {
                            nav_menu.map((data, index)=>(
                                <div key={index} className={"tab-pane fade" + (data.is_selected ? ' show active' : '')} id={data.id} role="tabpanel" aria-labelledby={data.id + "-tab"}>
                                    {
                                        data.id === 'personalInformation' ? 
                                        <PersonalInformation user_data={user_data} changePersonalInfo={(value, type)=>changePersonalInfo(value, type)} setAlert={(alertData)=>setAlert(alertData)} alert={alert} changePicture={(event)=>changePicture(event)} birth_date={birth_date} />
                                        : data.id === 'daftarAlamat' ? 
                                        <AddressList address_list={address_arr} />
                                        : 
                                        data.id === 'password' ? 
                                        <Password setAlert={(alertData)=>setAlert(alertData)} alert={alert} password_data={password_data} changePassword={(value, type)=>this.changePassword(value, type)} />
                                        : 
                                        <></>
                                    }
                                </div>
                            ))
                        }
					</div>
                </div>

            </div>

            </>
        )
    }
}