import React from 'react'
import Base from '../Utils/Base'


export default class FavoritesSection extends Base{

    async componentDidMount(){
    }

    async toShop(){
        window.location.href = '/shop-all'
    }

    render(){
        const {
            section1,
        } = this.props

        return (
            <>
            <div className='row'>
                {
                    section1.map((data, index)=>(
                        <>
                        <div className="col-12 p-lg-4 index_favorites_section" key={index}>
                            <div className={'home p-4 p-md-5 ' + (index % 2 === 0 ? 'n1' : 'n3')}>
                                <h3 className='text-uppercase'><span>{data.title}</span></h3>
                                <p className='description' dangerouslySetInnerHTML={{__html: data.content}}></p>
                                <button className='btn btn_primary_secondary' onClick={()=>this.toShop()}>Shop Now</button>
                            </div>
                            <div className={'p-4 p-md-0 ' + (index % 2 === 0 ? 'n2' : 'n4')}>
                                {/* <img className="fav_img" src={data.image} /> */}
                                <div className="fav_img d-md-block" style={{backgroundImage : "url(" + data.image +")"}} ></div>
                            </div>
                        </div>
                        </>
                    ))
                }
            </div>
            </>
        )
    }
}