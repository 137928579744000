import React from 'react';
import Base from '../Utils/Base';
import Alert from '../Components/Alert'

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

export default class ModalAuth extends Base{

	state = {
		optionsAxios : this.optionsAxios,
		forget_pass_email : {value : '', is_alert : false, error_message : ''},
		authModal : {title : 'Sign Up', type : 'signup', view : 'auth_btn'},
		auth_form : [
			{title : 'Email', input_type : 'email', type : 'email', value : '', is_alert : false, error_message : '', is_show : true, is_show_char : true},
			{title : 'Password', input_type : 'password', type : 'password', value : '', is_alert : false, error_message : '', is_show : true, is_show_char : false},
			{title : 'Re-Type Password', input_type : 'password', type : 'retype_password', value : '', is_alert : false, error_message : '', is_show : true, is_show_char : false},
			{title : 'Date of Birth', input_type : 'text', type : 'birth_date', value : '', is_alert : false, error_message : '', is_show : true, is_show_char : true},
		],
		auth_alert : {is_show : false, type : '', message : ''},
		is_disable : false,
		currentForm : '',
		is_learn_more : false,
		birth_date : ''
	}

	async componentDidMount(){
		var this1 = this
        this.$('#modalAuth').on('show.bs.modal', async function (event) {
			var authModal = {title : 'Sign Up', type : 'signup', view : 'auth_btn'}
			var auth_form = [
				{title : 'Email', input_type : 'email', type : 'email', value : '', is_alert : false, error_message : '', is_show : true, is_show_char : true},
				{title : 'Password', input_type : 'password', type : 'password', value : '', is_alert : false, error_message : '', is_show : true, is_show_char : false},
				{title : 'Re-Type Password', input_type : 'password', type : 'retype_password', value : '', is_alert : false, error_message : '', is_show : true, is_show_char : false},
				{title : 'Date of Birth', input_type : 'text', type : 'birth_date', value : '', is_alert : false, error_message : '', is_show : true, is_show_char : true},
			]
			var auth_alert = {is_show : false, type : '', message : ''}

            await this1.setState({authModal : authModal, auth_form : auth_form, auth_alert : auth_alert, is_learn_more : false})
        })
	}

	async modalAuth(type){
		var authModal = this.state.authModal
		var auth_form = this.state.auth_form

		for(var x in auth_form){
			auth_form[x].value = ''
			auth_form[x].is_alert = false
			auth_form[x].error_message = ''
			auth_form[x].is_show = true
			if(auth_form[x].type === 'password'){
				auth_form[x].is_show = (type === 'toForgetPass' ? false : true)
			}
			else if(auth_form[x].type === 'retype_password'){
				auth_form[x].is_show = (type === 'toSignUp' ? true : false)
			}
			else if(auth_form[x].type === 'birth_date'){
				auth_form[x].is_show = (type === 'toSignUp' ? true : false)
			}
		}
		await this.setState({auth_form : auth_form, auth_alert : {is_show : false, type : '', message : ''}})

		if(type === 'toLogin'){
			authModal.title = 'Log In'
			authModal.type = 'login'
			authModal.view = 'auth_btn'
		}
		else if(type === 'toSignUp'){
			authModal.title = 'Sign Up'
			authModal.type = 'signup'
			authModal.view = 'auth_btn'
		}
		else if(type === 'toForgetPass'){
			authModal.title = 'Create New Password'
			authModal.type = 'forget_pass'
			authModal.view = 'form'
		}
		else if(type === 'toFormsignup' || type === 'toFormlogin'){
			authModal.view = 'form'
		}

		await this.setState({authModal : authModal, currentForm : type, is_learn_more : false})
	}

	async changeAuthInput(value, index){
		await this.setState({auth_alert : {is_show : false, type : '', message : ''}})

		var auth_form = this.state.auth_form
		auth_form[index].value = value
		auth_form[index].is_alert = false
		if(auth_form[index].type === 'birth_date'){
			await this.setState({birth_date : value})
		}

		for(var x in auth_form){
			auth_form[x].is_alert = false
		}
		await this.setState({auth_form : auth_form})
	}

	async submitAuth(type){
		var data = {email : '', password : '', retype_password : '', birth_date : ''}
		var flag = 1
		var auth_form = this.state.auth_form

		var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

		for(var x in auth_form){
			if(auth_form[x].value === ''){
				auth_form[x].is_alert = true
				auth_form[x].error_message = (auth_form[x].type === 'email' ? 'Email cannot be blank' : auth_form[x].type === 'birth_date' ? 'Birth Date cannot be blank' : 'Make sure you enter a password.')
				if(auth_form[x].is_show){
					flag = 0
				}
			}
			else{
				data[auth_form[x]['type']] = auth_form[x].value
				if(!data['email'].match(mailformat)){
					auth_form[x].error_message = (auth_form[x].type === 'email' ? 'Email Format is wrong' : '')
					auth_form[x].is_alert = true
					flag = 0
				}
			}
		}

		if(this.state.currentForm === 'toFormsignup'){
			if(data.password !== data.retype_password){
				for(var x in auth_form){
					if(auth_form[x].type === 'password' || auth_form[x].type === 'retype_password'){
						auth_form[x].error_message = `Password doesn't match`
						auth_form[x].is_alert = true
						flag = 0
					}
				}
			}
			else if(data.password.length < 6 || data.retype_password.length < 6){
				for(var x in auth_form){
					if(auth_form[x].type === 'password' || auth_form[x].type === 'retype_password'){
						auth_form[x].error_message = `Password must be 6 characters or more`
						auth_form[x].is_alert = true
						flag = 0
					}
				}
			}
		}

		await this.setState({auth_form : auth_form})

		if(flag){
			console.log(data)
			try {
				var url = this.url
				if(type === 'signup'){
					url += '/auth/register'
				}
				else if(type === 'login'){
					url += '/auth/login'
				}
				else if(type === 'forget_pass'){
					url += '/auth/forget-password'
				}

				await this.setState({is_disable : true})

				data.birth_date = this.moment(data.birth_date).format()
				
				var response = await this.axios.post(url, data, this.optionsAxios);
		
				if (response.data.status == 'success') {
					await localStorage.setItem('token', response.data.token)

					var message = 'Success'
					if(type === 'forget_pass'){
						message = 'Please check your email for your new password'
					}
					
					await this.setState({auth_alert : {is_show : true, type : 'success', message : message}})

					setTimeout(() => {
						window.location.reload()
					}, 1500);
				}
				else if(response.data.status == 'error'){
					await this.setState({is_disable : false})
					if(type === 'forget_pass'){
						for(var x in auth_form){
							if(auth_form[x].type === 'email'){
								auth_form[x].error_message = response.data.message
								auth_form[x].is_alert = true
							}
						}
						await this.setState({auth_form : auth_form})
					}
					else if(type !== 'forget_pass'){
						await this.setState({auth_alert : {is_show : true, type : 'danger', message : response.data.message}})
					}
				}

			} catch (e) {
				await this.setState({is_disable : false})
				console.log(e.message)
			}
		}
	}

	async learn_more(){
		await this.setState({is_learn_more : !this.state.is_learn_more})
	}

	async showPassword(index){
		var auth_form = this.state.auth_form
		auth_form[index].is_show_char = !auth_form[index].is_show_char
		auth_form[index].input_type = 'password'
		if(auth_form[index].is_show_char){
			auth_form[index].input_type = 'text'
		}

		await this.setState({auth_form : auth_form})
	}

  render(){
	const {
		authModal,
		auth_form,
		auth_alert,
		is_disable,
		birth_date,
		is_learn_more
	} = this.state
	return (
	  <>
		<div className="modal fade bg-white" id="modalAuth" tabIndex="-1" role="dialog" aria-labelledby="modalAuthTitle" aria-hidden="true" data-backdrop="static">
			<div className='position-absolute p-5' style={{right : 0, top : '5%'}}>
			<button type="button" className="close" data-dismiss="modal" aria-label="Close">
				<i className="fas fa-times" style={{fontSize : '2rem'}}></i>
			</button>
			</div>
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content border-0">
					<div className="modal-header border-0 text-center">
						<div className='row'>
							<div className='col-12'>
								<h1 style={{fontFamily : 'LuloClean'}}>{authModal.title}</h1>
							</div>
							<div className='col-12'>
								<span>
								{authModal.type === 'login' ? 'New to this site? ' : authModal.type === 'signup' ? 'Already a member? ' : authModal.type === 'forget_pass' ? 'Please enter your email address' : ''}
								{
									authModal.type === 'signup' || authModal.type === 'login' ? 
									<a href='#!' style={{color : 'rgb(116,128,146)'}} onClick={()=>this.modalAuth(authModal.type === 'login' ? 'toSignUp' : 'toLogin')}>{authModal.type === 'login' ? 'Sign Up' : 'Log In'}</a>
									:<></>
								}
								</span>
							</div>
						</div>
					</div>
					
					<div className="modal-body">
						{/* { */}
							{/* // authModal.view === 'auth_btn' ?  */}
							{/* <>
							<div className='row'>
								<div className='col-12 py-5 text-center'>
									<button className='btn btn-outline-primary' onClick={()=>this.modalAuth('toForm'+authModal.type)}>{authModal.title} with Email</button>
								</div>
							</div>
							</> */}
							{/* // : */}
							{/* // authModal.view === 'form' ?  */}
							<div className='row'>
								<div className='col-12 px-5'>
									{
										auth_alert.is_show ? 
										<Alert type={auth_alert.type} message={auth_alert.message} />
										: <></>
									}
									<form>
										{
											auth_form.map((data, index)=>(
												<>
												{
													data.is_show ?
													<div className="form-group" key={index}>
														<label className='m-0'>{data.title}</label>
														<div className="input-group input-group-sm border-bottom">
															{
																data.type === 'birth_date' ?
																<>
																<div className='w-100' id='auth_birthDate'>
																	<DatePicker
																		selected={birth_date}
																		onChange={date => this.changeAuthInput(date, index)}
																		maxDate={new Date()}
																		peekNextMonth
																		showMonthDropdown
																		showYearDropdown
																		dropdownMode="select"
																		timeFormat="HH:mm"
																		dateFormat="dd MMMM yyyy"
																	/>
																</div>
																</>
																: 
																<>
																	<input type={data.input_type} className="form-control border-0" onChange={(e)=>this.changeAuthInput(e.target.value, index)} value={data.value} />
																	{
																		data.type === 'password' || data.type === 'retype_password' ? 
																		<div className="input-group-append">
																			<span className={"input-group-text border-0 bg-transparent " + (!data.is_show_char ? 'd-none' : 'd-block')} onClick={()=>this.showPassword(index)}>
																				<i className="fas fa-eye"></i>
																			</span>
																			<span className={"input-group-text border-0 bg-transparent " + (data.is_show_char ? 'd-none' : 'd-block')} onClick={()=>this.showPassword(index)}>
																				<i className="fas fa-eye-slash"></i>
																			</span>
																		</div>
																		:<></>
																	}
																</>
															}
														</div>
														{
															data.type === 'birth_date' ?
															<p className='m-0'>May or may not send You a birthday gift ;)</p>
															: <></>
														}
														<small className="form-text text-danger">{data.is_alert ? data.error_message : ''}</small>
													</div>
													: <></>
												}
												</>
											))

										}
										{
											authModal.type === 'login' ? <a href='#!' onClick={()=>this.modalAuth('toForgetPass')}><u>Forgot Password?</u></a> : <></>
										}
										<div className="form-group mt-3">
											<button type='button' className='btn btn-primary w-100' disabled={is_disable} onClick={()=>this.submitAuth(authModal.type)}>{authModal.type === 'forget_pass' ? 'Create Password' : authModal.title}</button>
										</div>
									</form>
								</div>
							</div>
							{/* // : <></> */}
						{/* } */}
						{
							authModal.type === 'signup' ?
							<div className='row'>
								<div className='col-12 text-center'>
									<div className="form-check">
										<input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
										<label className="form-check-label" htmlFor="defaultCheck1">Join this site's community. <a href='#!' onClick={()=>this.learn_more()}><u>Read {is_learn_more ? 'less' : 'more'}</u></a></label>
										{
											is_learn_more ?
											<p>Connect with members of our site. Leave comments, follow people and more. Your nickname, profile image, and public activity will be visible on our site.</p>
											: <></>
										}
									</div>
								</div>
							</div>
							: <></>
						}
					</div>
				</div>
			</div>
		</div>
	  </>
	);
  }
}
