import React from 'react'
import Base from '../../Utils/Base'

export default class BlogComment extends Base{
    state = {
    }

    async componentDidMount(){
    }

    render(){
        const {
            changeInput,
            publishComment,
            is_disabled
        } = this.props
        return (
            <>
            <div className='row m-0'>
                <div className='col-12'>
                    <h5 className='border-bottom pb-3'>Comments</h5>
                </div>
                <div className='col-12 mt-4'>
                    <div className='row'>
                        <div className='col-auto'>
                            <div className='align-middle d-flex align-items-center h-100 d-flex'>
                                <img src={this.no_image_profile} className='rounded-circle' style={{height : '3.5rem'}} />
                            </div>
                        </div>
                        <div className='col'>
                            <textarea className='form-control' placeholder='Write a comment...' rows={3} onChange={(e)=>changeInput(e.target.value)}></textarea>
                        </div>
                        <div className='col-12 mt-2 text-right'>
                            <button className='btn btn-sm btn-primary' disabled={is_disabled} onClick={()=>publishComment()}>Publish</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}