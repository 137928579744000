import React from 'react';
import Base from '../Utils/Base';

import Alert from '../Components/Alert'
import ModalAuth from './ModalAuth'

export default class Navbar extends Base {
    state = {
        token: '',
        header_nav_arr: [
            { title: 'Shop', url: '/shop-all' },
            { title: 'About Us', url: '/about' },
            { title: 'Sustainability', url: '/sustainability' },
            { title: 'Collection', url: '/collection' },
            { title: 'Bedtime Stories', url: '/blog' },
            { title: 'Contact Us', url: '/contact' },
        ],
        optionsAxios: this.optionsAxios,
        user_data: { id: '', name: '', email: '', image_display: '' },
        cart_data: [],
        count_cart: 0,
        is_sidebar: false,
        is_new_notif : false,

        promo_section : {id : '', title : ''},

        current_currency : ''
    }

    async componentDidMount() {
        var currency = await localStorage.getItem('currency_selected')
        if(currency != null){
            await this.setState({current_currency : currency})
            this.current_currency = currency
        }
        else{
            await this.setState({current_currency : 'IDR'})
            this.current_currency = 'IDR'
        }

        await this.get_promo()

        var token = await localStorage.getItem('token')
        var cart_data = await localStorage.getItem('cart')
        cart_data = JSON.parse(cart_data)
        var count_cart = this.state.count_cart

        for (var x in cart_data) {
            count_cart += parseInt(cart_data[x].amount)
        }

        await this.setState({ token: token, cart_data: cart_data, count_cart: count_cart })
        if (token != null) {
            var optionsAxios = this.state.optionsAxios
            optionsAxios['headers']['Authorization'] = token
            await this.setState({ optionsAxios: optionsAxios })
            await this.get_auth()
        }
				console.log(this.url)
    }

    async get_promo(){
        try {
            var url = this.url + '/blog/home?blog_home_type_name=promo&is_publish=1'

            var response = await this.axios.get(url, this.optionsAxios);

            if (response.data.status == 'success') {
                var data = response.data.data.data
                if(data.length > 0){
                    await this.setState({promo_section : data[0]})
                }

            }

        } catch (e) {
            console.log(e.message)
        }
    }

    async get_auth() {
        try {
            var url = this.url + '/auth/profile'

            var response = await this.axios.get(url, this.state.optionsAxios);

            if (response.data.status == 'success') {
                var data = response.data.data
                data.image_display = this.no_image_profile
                if (data.file_name != null) {
                    data.image_display = this.url_image + '/user?file_name=' + data.file_name
                }
                var notification = data.arr_notification
                var is_new_notif = false
                for(var x in notification){
                    if(notification[x].read_at == null){
                        is_new_notif = true
                        break
                    }
                }
                await this.setState({ user_data: data, is_new_notif : is_new_notif })
            }
            else if (response.data.status == 'error') {
                await localStorage.clear()
                await this.setState({ token: null, user_data: { id: '', name: '', email: '' } })
            }

        } catch (e) {
            console.log(e.message)
        }
    }

    async auth() {
        if (this.state.user_data.id != '') {
            window.location.href = '/profile'
        }
        else if (this.state.user_data.id == '') {
            this.$('#modalAuth').modal('show')
        }
    }

    async notif(){
        if(this.state.token != null){
            window.location.href = '/notification'
        }
        else{
            this.$('#modalAuth').modal('show')
        }
    }

    async sidebar(is_open) {
        await this.setState({ is_sidebar: is_open })
    }

    async changeCurrency(currency){
        this.current_currency = currency
        await this.setState({current_currency : currency})
        await localStorage.setItem('currency_selected', currency)

        window.location.reload()
    }

    render() {
        const {
            header_nav_arr,
            authModal, auth_form, auth_alert,
            user_data,
            is_disable,
            count_cart,
            is_sidebar,
            promo_section,
            is_new_notif
        } = this.state
        return (
            <>
                <div className='row m-0'>
                    {
                        promo_section.id !== '' ?
                        <div className='col-12 p-2' style={{ backgroundColor: '#8F8F8F' }}>
                            <p className='m-0 text-center text-monospace' style={{ color: 'black'}}>{promo_section.title}</p>
                        </div>
                        : <></>
                    }
                    <div className='col-12 pt-5 d-none d-lg-block'>
                        <div className='container text-right'>
                            <a className="nav-link js-scroll-trigger d-inline-block p-2" href='#!' onClick={() => this.auth()}>
                                <i className="fas fa-user-circle mr-2"></i>{user_data.id !== '' ? 'Profile' : 'Login'}
                            </a>
                            <a className="nav-link js-scroll-trigger d-inline-block p-2 position-relative" href='/transaction/cart'>
                                <i className="fas fa-shopping-bag"></i>
                                {
                                    count_cart > 0 ?
                                        <span className='badge badge-light position-absolute rounded-circle' style={{ right: '-.25rem', top: 0 }}>{count_cart}</span>
                                        : <></>
                                }
                            </a>
                            <a className="nav-link js-scroll-trigger d-inline-block p-2 position-relative" href='#!' onClick={() => this.notif()}>
                                <i className="fas fa-bell"></i>
                                {
                                    is_new_notif > 0 &&
                                    <i className="fas fa-circle mr-2 position-absolute" style={{fontSize : 12, right : '-.25rem', top : '.5rem', color : 'red'}}></i>
                                }
                            </a>
                            <a className="nav-link js-scroll-trigger d-inline-block p-2 position-relative" href='#!'>
                                <div className="btn-group">
                                    <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                        {this.current_currency}
                                    </button>
                                    <div className="dropdown-menu">
                                        <a className={"dropdown-item" + (this.current_currency === 'IDR' ? ' active' : '')} href="#!" onClick={()=>this.changeCurrency('IDR')}>IDR</a>
                                        <a className={"dropdown-item" + (this.current_currency === 'USD' ? ' active' : '')} href="#!" onClick={()=>this.changeCurrency('USD')}>USD</a>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <nav className="navbar navbar-expand-lg navbar-dark p-0 m-0">
                    <div className="container d-none d-lg-flex px-md-3">
                        <a className="navbar-brand m-0" href="/"><img src={this.img_lumosh_logo} className={'header_logo'} alt="LumoshLiving-Logo" style={{ width: '14rem' }} /></a>
                        <div className="d-flex flex-column">
                            {/* <div className='d-flex align-items-center justify-content-end'>
                                <a className="nav-link js-scroll-trigger d-inline-block p-2 position-relative" href='/transaction/cart'>
                                    <i className="fas fa-shopping-bag"></i>
                                    {
                                        count_cart > 0 ?
                                            <span className='badge badge-light position-absolute rounded-circle' style={{ right: '-.75rem', top: '-.5rem' }}>{count_cart}</span>
                                            : <></>
                                    }
                                </a>
                                <a className="nav-link js-scroll-trigger d-inline-block p-2" href='#!' onClick={() => this.auth()}>
                                    <i className="fas fa-user-circle mr-2"></i>{user_data.id !== '' ? 'Profile' : 'Login'}
                                </a>
                            </div> */}
                            <div className="collapse navbar-collapse" id="navbarResponsive" aria-labelledby="navbarResponsive">
                                <ul className="navbar-nav text-uppercase ml-auto pt-3 pt-lg-0">
                                    {
                                        header_nav_arr.map((data, index) => (
                                            <li className={"nav-item" + (index !== 0 ? ' ml-0 ml-xl-3 ml-lg-3' : '') + (index != header_nav_arr.length-1 ? ' mr-0 mr-xl-3 mr-lg-3' : '')} key={index}>
                                                <a className="nav-link js-scroll-trigger text-primary" href={data.url} style={{ fontSize: '.9rem' }}>{data.title}</a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                    <div className='d-flex justify-content-between d-lg-none m-0 w-100 mt-2 mx-3'>
                        <div className="d-flex align-items-center">
                            <a className="nav-link js-scroll-trigger d-inline-block p-0 position-relative" href='/transaction/cart'>
                                <i className="fas fa-shopping-bag" style={{ fontSize: '1.75rem' }}></i>
                                {
                                    count_cart > 0 ?
                                        <span className='badge badge-light position-absolute rounded-circle' style={{ right: '-.5rem', top: '-.25rem' }}>{count_cart}</span>
                                        : <></>
                                }
                            </a>
                        </div>
                        <div className="d-flex align-items-center">
                            <a className="navbar-brand m-0 pt-3" href="/"><img src={this.img_lumosh_logo} className={'header_logo'} alt="LumoshLiving-Logo" style={{ height: '2.3rem' }} /></a>
                        </div>
                        <div className="d-flex align-items-center">
                            <button className="navbar-toggler navbar-toggler-right border-0 p-0" type="button" onClick={() => this.sidebar(true)}>
                                <i className="fas fa-bars text-primary" style={{ fontSize: '1.75rem' }}></i>
                            </button>
                        </div>
                    </div>
                    <div className={"sidebar" + (is_sidebar ? ' active' : '')}>
                        <div className='position-absolute p-5 ' style={{ right: 0, top: '5%', 'zIndex': '1' }}>
                            <span onClick={() => this.sidebar(false)} >
                                <i className="fas fa-times" style={{ fontSize: '2rem', cursor: 'pointer' }}></i>
                            </span>
                            {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times" style={{ fontSize: '2rem' }}></i>
                            </button> */}
                        </div>
                        <div className='row m-0'>
                            <div className='col-12' style={{ height: '100vh' }}>
                                <div className='d-flex align-middle h-100 align-items-center'>

                                    <div className='row'>
                                        {/* <div className='col-12 text-center'>
                                            <a className="nav-link js-scroll-trigger d-inline-block p-0 mx-3" href='#!' onClick={() => this.auth()} style={{ fontSize: '1.25rem' }}>
                                                <i className="fas fa-user-circle mr-2" style={{ fontSize: '1.75rem' }}></i>{user_data.id !== '' ? 'Profile' : 'Login'}
                                            </a>
                                        </div> */}
                                        <div className='col-12 mt-3'>
                                            <div className='row m-0'>
                                                <div className='col-auto'>
                                                    <ul className="navbar-nav text-uppercase">
                                                        <li className="nav-item">
                                                            <a className="nav-link js-scroll-trigger text-primary" href='#!' onClick={() => this.auth()} style={{ fontSize: '1.5rem' }}>
                                                                <i className="fas fa-user-circle mr-2" style={{ fontSize: '1.75rem' }}></i>
                                                                {user_data.id !== '' ? 'Profile' : 'Login'}
                                                            </a>
                                                        </li>
                                                        {
                                                            header_nav_arr.map((data, index) => (
                                                                <li className={"nav-item" + (index !== 0 ? ' ml-0 ml-lg-3' : '')} key={index}>
                                                                    <a className="nav-link js-scroll-trigger text-primary" href={data.url} style={{ fontSize: '1.5rem' }}>
                                                                        <i className="fas fa-user-circle text-white mr-2" style={{ fontSize: '1.75rem' }}></i>
                                                                        {data.title}
                                                                    </a>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                                {/* <div className='col text-right'>
                                                    <span onClick={() => this.sidebar(false)}>
                                                        <i className="fas fa-times" style={{ fontSize: '1.5rem', cursor: 'pointer' }}></i>
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </nav>


                <ModalAuth />

            </>
        );
    }
}

