import React from 'react';
import Base from '../Utils/Base';
import Alert from './Alert'

export default class ModalCompleteProfile extends Base{

	state = {
	}

	async componentDidMount(){
	}

    render(){
        return (
        <>
            <div className="modal fade" id="modalCompleteProfile" tabIndex="-1" aria-labelledby="modalCompleteProfileLabel" aria-hidden="true" data-backdrop="static" data-keyboard='false'>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-body">
                        <h6 className='m-0'>PLEASE COMPLETE YOUR PROFILE</h6>
                    </div>
                    <div className="modal-footer">
                        <a href='/profile' className="btn btn-primary">Edit Profile</a>
                    </div>
                    </div>
                </div>
            </div>
        </>
        );
    }
}
