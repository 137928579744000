import React from 'react'
import Base from '../Utils/Base'
import { tns } from 'tiny-slider/src/tiny-slider'

import HelpDetail from './detail'

export default class Help extends Base{
    state = {
        data_arr : [],
        help_menu : [
            {title : 'Shipping & Returns', url : '/help/shipping-returns', is_active : false, type : 'shipping-returns'},
            {title : 'Store Policy', url : '/help/store-policy', is_active : false, type : 'store-policy'},
            {title : 'Payment Methods', url : '/help/payment-methods', is_active : false, type : 'payment-methods'},
            {title : 'FAQ', url : '/help/faq', is_active : false, type : 'faq'},
        ],
        selected_menu : {type : '', title : ''},
    }

    async componentDidMount(){
        var help_menu = this.state.help_menu
        var selected_menu = {}
        for(var x in help_menu){
            if(help_menu[x].type === this.props.match.params.type){
                help_menu[x].is_active = true
                selected_menu = help_menu[x]
            }
        }
        
        await this.setState({help_menu : help_menu, selected_menu : selected_menu})

        var data_arr = []
        if(selected_menu.type === 'shipping-returns'){
            data_arr.push({
                title : '',
                detail : `Afraid of receiving breakage goods?<br/><br/>
                Have no trust in third-party expedition due to carelessness?<br/><br/>
                Then trust in us and worry no more, because we are ensuring every packaging is safe enough to handle by them. Even when you receive a broken good, we will send the new one for you with free delivery fee. T&C Applied.`
            },
            {
                title : 'Freight Service Recommendations',
                detail : `We will recommend to use SiCepat due to good performance of delivering goods.<br/><br/>
                For bulk order, we recommend train service due to costing and safer.<br/><br/>
                Any recommendations for freight service are welcomed.`
            },
            {
                title : 'Product Returns & Exchanges',
                detail : `<h6 class='m-0'>Damage Policy</h6><br/>
                Any damage that occur during shipping must be reported within 5 days to lumosh.ceramic@gmail.com<br/><br/>
                Please attach your invoice code & pictures of the damaged goods in your email.<br/><br/>
                We are only exchanging goods due to carelessness of the freight service.<br/><br/>
                <h6 class='m-0'>Exchange & Refund Policy</h6><br/>
                Product exchanges are allowed for items that are falsely described from your purchase.<br/><br/>
                Any unexpected irregularity occur during usage, can be discussed with Us.<br/><br/>
                We might ask you to return it to Us for further observation, and if it happen caused by the flaw or our production, We will exchange it with new product.`
            })

            await this.setState({data_arr : data_arr})
        }
        else if(selected_menu.type === 'store-policy'){
            await this.get_data('store_policy')
        }
        else if(selected_menu.type === 'payment-methods'){
            await this.get_data('payment_method')
        }
        else if(selected_menu.type === 'faq'){
            data_arr.push({
                title : 'Is it local made or imported goods?',
                detail : `We flattered that lot of people mistakenly assume we import our products. In fact, it is locally made by Indonesian handcrafter. We do want to prove that our design could compete with the imported goods.`
            },
            {
                title : 'Do you accept custom orders?',
                detail : `Yes, We do. In fact, We always challenge ourselves to find the best design for You. If by any chance Our ready-stock products were not suitable enough, then custom is of course a necessary things. You can consult with Us directly by hit us through email, and attach your references and the requirement for your business. We will ask our designer team to contact You back as soon as possible.`
            },
            {
                title : 'Is it possible to put Company brand logo into ceramic?',
                detail : `We acknowledge the importance of Branding for your business, therefore, putting brand in tableware ceramic products are very possible with the minimum quantity of 100 pieces (can be mixed) in total. We do the overglazed transfer printing, then firing it into kiln, which means the printing will not be able to fade away. However, we will charge additional costs for it due to double firing technique.`
            },
            {
                title : 'Why do I find difference in color or pattern in each goods? Are those defected items?',
                detail : `What is the definition of perfect? And what is the standard for defect? It is a hardly answered questions. Because every products are crafted by hand, including painting and glazing the products. Even during firing process in the kiln, the finished result might be slightly different. In one batch of production, slightly different color and pattern are the natural result in pottery which is not possible to be controlled. So, when you notice the product you get is not exactly same from the photo, it is due to handmade process and natural result of Stoneware ceramic.`
            },
            {
                title : 'What are your shipping carriers?',
                detail : `We do usually recommend SiCepat due to good perfomance of handling our products. For bulk orders, We will recommend to use cargo services for cost purpose and safety. For overseas purchase, would be recommended to talk with us for further information.`
            },
            {
                title : 'What if the goods is broken or damaged during the delivery?',
                detail : `We are ensuring every purchase is well packaged and safe enough to be delivered. Just in case you receive any broken or damaged goods, please send Us a picture to our email, and we will send the new one for you with no additional shipping fee.`
            },
            {
                title : 'Do you have any showroom or store-visits?',
                detail : `We currently have a showroom in Surabaya at PlayRoast (Jl. Raya Ngagel Jaya No. 40). You are welcomed to drop by and see our range of products and conduct a meeting with us for consultation. We understand that most of Us are willing to see the real products, therefore, We do cooperation with several concept stores to put our products there. You can see it in our instagram @lumosh.living for updated location.`
            },
            {
                title : 'Bulk purchase? Any special price?',
                detail : `Yes we do serve both retail and wholesale purchases. There will be special price for bulk purchase with minimum quantity. For more info, hit us on email Lumosh.ceramic@gmail.com`
            },
            {
                title : 'How long do I need to wait for Bulk orders or custom made? Is it always ready stock?',
                detail : `Some of our products are ready to ship. However, the popular one will be easily out of stock. Our standard lead time production is around 6-8 weeks (sometimes faster than expected #fingercrossed). For bulk or custom orders, the production time is depending on how complicated of the work. Usually it takes 6-8 weeks as well, but could take longer for quality control.`
            },)

            await this.setState({data_arr : data_arr})
        }
    }

    async get_data(type){
        try {
            var url = this.url + '/blog/home?blog_home_type_name=' + type
      
            var response = await this.axios.get(url, this.optionsAxios);
      
            if (response.data.status == 'success') {
                var data = response.data.data.data
                var data_arr = []
                data_arr.push({
                    title : data[0].title,
                    detail : data[0].content
                })
                await this.setState({data_arr : data_arr})
            }

        } catch (e) {
            console.log(e.message)
        }
    }

    render(){
        const {
            help_menu,
            selected_menu,
            data_arr
        } = this.state
        return (
            <>
            <section className="mt-5">
                    <div className='row'>
                    
                        <div className='col-12'>
                            <div className='row'>
                                <div className="col-md-4 col-lg-3 d-none d-md-block">
                                    <div className="row border-right border-primary">
                                        {
                                            help_menu.map((data, index)=>(
                                                <a href={data.url} className={"col-12 pr-4 text-right mb-2 text-primary" + (data.is_active ? ' font-weight-bold' : '')} key={index}>{data.title}</a>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-md-8 col-lg-9">
                                    <div className='row'>
                                        <div className='col-12 pl-4'>
                                            <h3 className="mb-5 d-none d-md-block">{selected_menu.title}</h3>

                                            <div className='row mt-5'>
                                                <div className='col-12'>
                                                    <HelpDetail data_detail={data_arr} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
            </section>

            </>
        )
    }
}