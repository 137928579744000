import React from 'react'
import Base from '../Utils/Base'


export default class ImperfectionSection extends Base {

	async componentDidMount() {
	}

	render() {
		const {
			imperfection_arr,
		} = this.props

		return (
			<>
				<div className='p-4 mt-md-5'>
					<div className='row'>
						<div className='col-12'>
							<iframe src="https://cdn.lightwidget.com/widgets/5520487683bc5eff996668e0842819b3.html" scrolling="no" allowtransparency="true" className="lightwidget-widget" style={{width : '100%', border : 'none', overflow : 'hidden'}}></iframe>
						</div>
					</div>
					{/* {
						imperfection_arr.map((data, index) => (
							<>
								<div className='listImperfection' key={index}>
									<div style={{ overflow: 'hidden' }}>
										<div className='ratio-11' style={{ backgroundImage: "url(" + data.image + ")" }}></div>
									</div>
									{
										data.title != null && data.title != '' ?
											<div className=' titleView d-flex align-items-center justify-content-center'>
												<p className='m-0 p-2'>{data.title}</p>
												<p className='m-0 p-2' dangerouslySetInnerHTML={{ __html: data.description }}></p>
											</div>
											: <></>
									}
								</div>
							</>
						))
					} */}
				</div>
			</>
		)
	}
}