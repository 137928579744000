import React from 'react'
import Base from '../Utils/Base'

export default class Breadcrumbs extends Base{
    state = {
    }

    async componentDidMount(){
    }

    render(){
        const {
            breadcrumbs_arr
        } = this.props
        return (
            <>
            <nav aria-label="breadcrumb">
                <small>
                    <ol className="breadcrumb bg-transparent p-0 mb-0">
                        {
                            breadcrumbs_arr.map((data, index)=>(
                                <li className={"breadcrumb-item text-capitalize " + (data.is_active ? ' active' : '')} key={index}>
                                    {
                                        data.is_active ? data.title : 
                                        <a href={data.url}>{data.title}</a>
                                    }
                                </li>
                            ))
                        }
                    </ol>
                </small>
            </nav>
            </>
        )
    }
}