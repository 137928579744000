import React from 'react'
import Base from '../Utils/Base'

import ListCart from '../Components/CartList'
import OrderSummary from '../Components/OrderSummary'
import NoData from '../Components/NoData'

export default class Notification extends Base{
    state = {
        data_arr : [],
        token : '',
        optionsAxios : this.optionsAxios,
        isLoading : true
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        var optionsAxios = this.optionsAxios
        if(token != null){
            optionsAxios['headers']['Authorization'] = token
        }
        await this.setState({token : token, optionsAxios : optionsAxios})

        await this.get_auth()
        await this.read_notif()

        await this.setState({isLoading  : false})
    }

    async get_auth(){
        try {
            var url = this.url + '/auth/profile'
      
            var response = await this.axios.get(url, this.state.optionsAxios,
            );
      
            if (response.data.status === 'success') {
                var data = response.data.data
                var notification = data.arr_notification
                for(var x in notification){
                    notification[x].date_format = this.moment(notification[x].created_at).format('DD MMM YYYY HH:mm')
                }
                
                await this.setState({data_arr : notification})
            }

        } catch (e) {
            // console.log(e.message)
        }
    }

    async read_notif(){
        try {
            var url = this.url + '/auth/notification/read'
      
            var response = await this.axios.put(url, this.state.optionsAxios, {}
            );
      
            if (response.data.status === 'success') {
                console.log(response.data.status)
            }

        } catch (e) {
            // console.log(e.message)
        }
    }

    render(){
        const {
            data_arr,
            isLoading
        } = this.state
        return (
            <>

            <div className='pb-5'>
                <div className='row pb-5'>
                    
                    <div className='col-12 p-3 p-md-5'>
                        <div className='row pb-5'>

                            <div className='col-12 px-4'>
                                <p className='pb-3 m-0' style={{borderBottom : '1px solid'}}>Notification</p>

                                {
                                    !isLoading &&
                                    <div className='row'>
                                    {
                                        data_arr.length > 0 ? 
                                            <div className='col-12'>
                                                <ul className="list-group list-group-flush">
                                                {
                                                    data_arr.map((data, index)=>(
                                                        <>
                                                        <li className="list-group-item px-0 px-lg-3 py-3" key={index}>
                                                            <div className='row'>
                                                                <div className='col'>
                                                                    <p className="m-0 d-inline-block"><b>#{ index+1 }</b></p>
                                                                    <p className="mb-0 d-inline-block ml-1 text-capitalize"><b>{data.title}</b></p>
                                                                    <p className="mb-0 text-capitalize">{data.description}</p>
                                                                </div>
                                                                <div className='col-auto'>
                                                                    <p className="mb-0">{data.date_format}</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        </>
                                                    ))
                                                }
                                                </ul>
                                            </div>
                                        : 
                                        <>
                                            <div className='col-12 p-4'>
                                                <NoData />
                                            </div>
                                        </>
                                    }
                                    </div>
                                }
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            </>
        )
    }
}