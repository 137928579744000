import React from 'react';
import Base from '../Utils/Base';
// import Router from '../Router/router'
import Navbar from './navbar'
import Footer from './footer'

import ModalPreloader from '../Components/ModalPreloader'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from '../Index/index';
import NotFoundPage from './NotFoundPage';
import ShopAll from '../Shop';
import AboutUs from '../AboutUs';
import ContactUs from '../ContactUs';
import Sustainability from '../Sustainability';
import ProductDetail from '../Shop/ProductDetail';
import Cart from '../Transaction/Cart';
import Checkout from '../Transaction/Checkout';
import Payment from '../Transaction/Payment';
import Profile from '../Profile';
import Blog from '../Blog';
import Collection from '../Collection';
import BlogDetail from '../Blog/detail';
import Help from '../Help';
import InThePress from '../InThePress';
import InThePressDetail from '../InThePress/detail';
import Notification from '../Notification';

export default class BaseLayout extends Base{

  state = {
  }
  
  async componentDidMount(){
    // await localStorage.clear()
    await this.setView('website')
    // if(this.props.location.pathname !== '/profile'){
    //   await localStorage.removeItem('lastSideMenuSelected')
    // }
    // await this.countViewer()
  }

  async countViewer(){
    try {      
      var url = this.url + '/website-viewer'

      var response = await this.axios.post(url, {}, this.optionsAxios);

      if (response.data.status === 'success') {
      }
    } catch (e) {
        // console.log(e.message)
    }
  }

  render(){
    return (
      <>
        <Router>
        <div id="wrapper">
        
            <Navbar/>
            <div className='container'>
              <Switch>
                <Route exact path={"/"} component={Home} />
                <Route exact path={"/shop-all"} component={ShopAll} />
                <Route exact path={"/about"} component={AboutUs} />
                <Route exact path={"/contact"} component={ContactUs} />
                <Route exact path={"/sustainability"} component={Sustainability} />

                <Route exact path={"/product/:id"} component={ProductDetail} />

                <Route exact path={"/transaction/cart"} component={Cart} />
                <Route exact path={"/transaction/checkout"} component={Checkout} />
                <Route exact path={"/transaction/payment"} component={Payment} />

                <Route exact path={"/profile"} component={Profile} />
                <Route exact path={"/profile/:type"} component={Profile} />

                <Route exact path={"/blog"} component={Blog} />
                <Route exact path={"/blog/:id"} component={BlogDetail} />

                <Route exact path={"/in-the-press"} component={InThePress} />
                <Route exact path={"/in-the-press/:id"} component={InThePressDetail} />

                <Route exact path={"/collection"} component={Collection} />
                <Route exact path={"/help/:type"} component={Help} />
                <Route exact path={"/notification"} component={Notification} />
                <Route exact path={""} component={NotFoundPage} />
              </Switch>
              {/* <Router /> */}
            </div>

            <Footer />

            <ModalPreloader />

        </div>
        </Router>
      </>
    );
  }
}
