import React from 'react'
import Base from '../Utils/Base'


export default class ProductList extends Base {
	async componentDidMount() {

	}

	async toDetail(id) {
		window.location.href = '/product/' + id
	}

	render() {
		const {
			data,
			updateQty,
			addToCart,
		} = this.props

		return (
			<>
				<a href={'/product/' + data.id} className='text-decoration-none'>
					<div className='listProduct'>
						<div style={{ overflow: 'hidden' }}>
							<div className='ratio-11 listImg' style={{ backgroundImage: "url(" + data.image_display + ")" }}>
							</div>
							<div className='text-center quickView-btn'>
								<p className='m-0 p-2'>Quick View</p>
							</div>
						</div>
					</div>
					<p className='m-0 mt-3 text-capitalize'>{data.name}</p>
					{
						data.is_discount ?
							<>
								<p className='m-0'><del>{data.price_display}</del><br></br><b>{data.price_display_after_disc}</b></p>
							</>
							:
							<p className='m-0'>
								<b>
									{this.props.current_currency === "USD" ? '$' : "Rp."} 
									{(this.props.current_currency === data.currency.name ? data.price : (data.currency.name === "IDR" ? data.price / this.props.current_rate : data.price * this.props.current_rate)).toLocaleString(this.props.current_currency === "IDR" ? 'id-ID' : 'en-US', {
										maximumFractionDigits: this.props.current_currency === "IDR" ? 0 : 2,
										minimumFractionDigits: this.props.current_currency === "IDR" ? 0 : 2,
									})}
								</b>
							</p>
					}
				</a>
				{/* {
                    data.qty > 0 ?
                    <div className="input-group border mt-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text bg-white border-0" style={{cursor : 'pointer', userSelect : 'none'}} onClick={()=>updateQty('min')}>-</span>
                        </div>
                        <input type="text" className="form-control text-center border-0" value={data.orderQty} onChange={(e)=>updateQty('type', e.target.value)} />
                        <div className="input-group-append">
                            <span className="input-group-text bg-white border-0" style={{cursor : 'pointer', userSelect : 'none'}} onClick={()=>updateQty('plus')}>+</span>
                        </div>
                    </div>
                    : <></>
                }
                <div style={{justifyContent : 'flex-end'}}>
                {
                    data.qty > 0 ?
                    <button type='button' className='btn btn-sm btn-primary w-100 mt-2 btn_primary p-1' onClick={()=>addToCart()}>Add To Cart</button>
                    :
                    <button type='button' className='btn btn-sm btn-secondary w-100 mt-2 p-1' disabled>Out Of Stock</button>
                }
                </div> */}
			</>
		)
	}
}