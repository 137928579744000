import React, { Component } from 'react'
// import '../App.css'
import axios from 'axios'
import moment from 'moment'
import Jquery from 'jquery'

import 'popper.js'
import 'bootstrap'

export default class Helper {
	xendit_api_key = 'xnd_public_development_OYqIfOUth+GowsY6LeJOHzLCZtSj84J9kXDn+Rxj/mbf/LCoCQdxgA=='

	str_to_double(data, default_value = 0){
		var value
		if(data != '')
			value = parseFloat(data.replace(/\./g,'').replace(/,/g,'.'))
		else
			value = default_value
		
		return value
	}

	number_string_validation(data, max_length = 0, min_char = 0, max_char = 0){
		if(isNaN(data[data.length - 1]))
			data = data.substring(0, data.length - 1)
		if(max_length > 0)
			data = data.substring(0, max_length)
		if(max_char > 0 && this.str_to_double(data) > max_char)
			data = String(max_char)
		if(min_char > 0 && this.str_to_double(data) < min_char)
			data = String(min_char)
		// console.log(this.str_to_double(data))
		return data
	}

	async phone_validation(data){
		data = String(this.str_to_double(data, ''))
		if(isNaN(data))
			data = '0'
		if(data.charAt(0) === '0')
			data = data.slice(1)
		return data
	}

	async to_currency_format(data){
		var currency = await localStorage.getItem('currency_selected')

		var value = this.str_to_double(data)
		if(isNaN(value))
			value = 0

		return value.toLocaleString(currency === "USD" ? this.currencyUSD : this.currencyIDR)
	}
}