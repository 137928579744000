import React from 'react'
import Base from '../Utils/Base'
import { tns } from 'tiny-slider/src/tiny-slider'

export default class Collection extends Base{
    state = {
        collection_arr : []
    }

    async componentDidMount(){
        try {
            var url = this.url + '/blog/home?blog_home_type_name=collection&is_publish=1'
      
            var response = await this.axios.get(url, this.optionsAxios);
      
            if (response.data.status == 'success') {
                var data = response.data.data.data
                for(var x in data){
                    data[x].image = this.url_image + '/blog?file_name=' + data[x].file_name
                }
                await this.setState({collection_arr : data})
            }

        } catch (e) {
            console.log(e.message)
        }
    }

    async enlargeImg(index){
        this.$('#modalLargeImg').modal('show')
        if(this.state.collection_arr.length > 0){
            tns({
                container: ".collectionView-carousel",
                mode : 'gallery',
                items: 1,
                loop: true,
                slideBy: "1",
                nav: false,
                animateIn : 'tns-fadeIn',
                animateOut : 'tns-fadeOut',
                controlsContainer: '#collectionView-controls',
                controls : true,
                swipeAngle : false,
                mouseDrag : false,
                startIndex : index
            });
        }
    }

    render(){
        const {
            collection_arr
        } = this.state
        return (
            <>
            <section className="mt-5">
                    <div className='row'>
                    
                        <div className='col-12'>
                            <div className='row'>
                                {
                                    collection_arr.map((data, index)=>(
                                        <div className='col-6 col-md-4 mb-3' key={index}>
                                            <div className='listCollection' onClick={()=>this.enlargeImg(index)}>
                                                    <div style={{overflow : 'hidden'}}>
                                                        <div className='ratio-11 collectionImg' style={{backgroundImage : "url(" + data.image +")"}}>
                                                    </div>
                                                    {
                                                        data.title != null && data.title != '' ?
                                                        <div className='text-center titleCollection'>
                                                            <p className='m-0 p-2'>{data.title}</p>
                                                        </div>
                                                        : <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                    </div>
            </section>

            <div className="modal fade" id="modalLargeImg" tabIndex="-1" aria-labelledby="modalLargeImgLabel" aria-hidden="true" data-backdrop="static" style={{backgroundColor : 'rgba(0,0,0, .75)'}}>
                <div className='position-absolute p-5' style={{right : 0, top : 0}}>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <i className="fas fa-times" style={{fontSize : '2rem', color : 'white'}}></i>
                </button>
                </div>
                <div id="collectionView-controls" className="controls collectionView-container-control" aria-label="Carousel Navigation" tabIndex="0">
                    <div className="prev valign-wrapper d-flex align-items-center justify-content-center" data-controls="prev" aria-controls="collectionView-carousel" tabIndex="-1">
                    <i className="fas fa-angle-left"></i>
                    </div>
                    <div className="next valign-wrapper d-flex align-items-center justify-content-center" data-controls="next" aria-controls="collectionView-carousel" tabIndex="-1">
                    <i className="fas fa-angle-right"></i>
                    </div>
                </div>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content bg-transparent">
                    <div className="modal-body">
                        <div className='row m-0'>
                            <div className='col-12 px-5'>
                                <div className="collectionView-container position-relative px-3">
                                    <div className="collectionView-carousel">
                                    {
                                        collection_arr.map((data, index)=>(
                                            <>
                                            <div key={index}>
                                                <div className='ratio-11 collectionImg' style={{backgroundImage : "url(" + data.image +")"}}></div>
                                            </div>
                                            </>
                                        ))
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            </>
        )
    }
}