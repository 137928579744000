import React from 'react'
import Base from '../../Utils/Base'

export default class BlogDetail extends Base{
    state = {
    }

    async componentDidMount(){
    }

    render(){
        const {
            data_blog,
            selectedRecent
        } = this.props
        return (
            <>
            <div className='row m-0'>

                <div className='col-12'>
                    <h4>{data_blog.title}</h4>
                </div>
                <div className='col-12 col-md-7 offset-md-3 mt-3 p-0'>
                    <div className="ratio-43 d-md-block" style={{backgroundImage : "url(" + data_blog.image_display +")", backgroundRepeat : 'no-repeat', backgroundSize : 'contain', cursor : 'pointer'}} ></div>
                </div>

                <div className='col-12 mt-4' dangerouslySetInnerHTML={{__html: data_blog.content}}></div>

                <div className='col-12 border-top mt-4'>
                    <div className='row mt-2'>
                        <div className='col-auto'>
                            <p className='m-0'>{data_blog.total_viewer} views</p>
                        </div>
                        <div className='col-auto'>
                            <p className='m-0'>{data_blog.total_comment} comments</p>
                        </div>
                        <div className='col text-right'>
                            <span style={{cursor : 'pointer'}} onClick={()=>selectedRecent()}>
                                {
                                    data_blog.is_like ? 
                                    <i className={"fas fa-heart text-danger"}></i>
                                    :
                                    <i className={"far fa-heart text-danger"}></i>
                                }
                            </span>
                        </div>
                    </div>
                </div>

            </div>
            </>
        )
    }
}