import React from 'react'
import Base from '../../Utils/Base'

export default class SearchBlog extends Base{
    state = {
    }

    async componentDidMount(){
    }

    render(){
        const {
            is_search,
            searchBlog,
            search,
            changeSearch
        } = this.props
        return (
            <>
            {
                !is_search ? 
                <span style={{cursor : 'pointer'}} onClick={()=>searchBlog()}><i className="fas fa-search"></i></span>
                : 
                <>
                    <div className='form-inline justify-content-end'>
                        <div className="input-group border-bottom">
                            <div className="input-group-prepend">
                                <span className="input-group-text bg-transparent border-0"><i className="fas fa-search"></i></span>
                            </div>
                            <input type="text" className="form-control form-control-sm bg-transparent border-0 p-0" placeholder='Search' value={search} onChange={(e)=>changeSearch(e.target.value)} />
                            <div className="input-group-append">
                                <span className="input-group-text bg-transparent border-0" onClick={()=>searchBlog()}><i className="fas fa-times"></i></span>
                            </div>
                        </div>
                    </div>
                </>
            }
            </>
        )
    }
}