import React from 'react'
import Base from '../Utils/Base'

import ListCart from '../Components/CartList'
import OrderSummary from '../Components/OrderSummary'

export default class Cart extends Base {
	state = {
		data_arr: [],
		subTotal: 0,
		grand_total: 0,
		is_notes: false,
		notes: '',
		order_summary_data: [],
		current_currency : '',
		current_rate: 0,
	}

	async componentDidMount() {
		var cart_data = await localStorage.getItem('cart')
		cart_data = JSON.parse(cart_data)
		var subTotal = 0

		var currency = await localStorage.getItem('currency_selected')
    if(currency != null){
			await this.setState({current_currency : currency})
			if(currency === 'USD'){
				await this.get_rate()
			}
    }
    else{
			await this.setState({current_currency : 'IDR'})
    }

		for (var x in cart_data) {
			cart_data[x].currencyFormat = (cart_data[x].currency.name === 'IDR' ? this.currencyIDR : this.currencyUSD)

			var idr_price = ''

			var price_currency = cart_data[x].currency.name === 'IDR' ? 'Rp. ' : '$'
			cart_data[x].is_discount = false
			cart_data[x].price_display = price_currency + parseFloat(cart_data[x].price).toLocaleString(cart_data[x].currency.name === 'IDR' ? this.currencyIDR : this.currencyUSD)
			idr_price = cart_data[x].price

			if (cart_data[x].price_after_discount != null) {
				cart_data[x].is_discount = true
				cart_data[x].price_display_after_disc = price_currency + parseFloat(cart_data[x].price_after_discount).toLocaleString(cart_data[x].currency.name === 'IDR' ? this.currencyIDR : this.currencyUSD)
				idr_price = cart_data[x].price_after_discount
			}

			var price = cart_data[x].is_discount ? cart_data[x].price_after_discount : cart_data[x].price
			cart_data[x].totalPrice = (cart_data[x].currency.name === 'IDR' ? 'Rp. ' : '$') + parseFloat(price * cart_data[x].amount).toLocaleString(cart_data[x].currencyFormat)


			cart_data[x].idr_totalPrice = ''
			cart_data[x].idr_price_display = ''
			cart_data[x].idr_price_display_after_disc = ''

			if (cart_data[x].currency.name === 'USD') {
				idr_price = cart_data[x].price_in_rupiah
				var idr_price_discount = cart_data[x].price_in_rupiah_after_discount
				cart_data[x].idr_price_display = 'Rp. ' + parseFloat(idr_price).toLocaleString(this.currencyIDR)
				if (cart_data[x].is_discount) {
					cart_data[x].idr_price_display_after_disc = 'Rp. ' + parseFloat(idr_price_discount).toLocaleString(this.currencyIDR)
					idr_price = idr_price_discount
				}
				cart_data[x].idr_totalPrice = 'Rp. ' + parseFloat(idr_price * cart_data[x].amount).toLocaleString(this.currencyIDR)
			}

			subTotal += cart_data[x].amount * idr_price
		}

		if (cart_data == null) {
			cart_data = []
		}

		var grand_total = subTotal

		var order_summary_data = [{ title: 'Subtotal', value: subTotal, type: 'plus' }]

		if (cart_data.length > 0) {
			this.preloader('open')
		}

		setTimeout(async () => {
			await this.setState({ data_arr: cart_data, subTotal: subTotal, grand_total: grand_total, order_summary_data: order_summary_data })
			this.preloader('close')
		}, 1500);
	}

	async get_rate(){
    try {
        var url = this.url + '/exchange/last'

        var response = await this.axios.get(url, this.optionsAxios);

        if (response.data.status == 'success') {
            await this.setState({current_rate : response.data.data.price})
        }

    } catch (e) {
        console.log(e.message)
    }
  }

	async updateQty(type, index, qty = 0) {
		var data = this.state.data_arr
		if (type === 'min') {

			if (data[index].amount != 1) {
				data[index].amount = parseInt(data[index].amount) - 1
			}
		}
		else if (type === 'plus') {
			if (data[index].amount < data[index].qty) {
				data[index].amount = parseInt(data[index].amount) + 1
			}
		}
		else if (type === 'type') {
			if (qty === '') {
				qty = 1
			}

			data[index].amount = parseInt(qty)
			if (qty > data[index].qty) {
				data[index].amount = data[index].qty
			}
		}

		var subTotal = 0
		for (var x in data) {
			data[x].currencyFormat = (data[x].currency.name === 'IDR' ? this.currencyIDR : this.currencyUSD)

			var idr_price = ''

			var price = data[x].price_after_discount != null ? data[x].price_after_discount : data[x].price
			data[x].totalPrice = (data[x].currency.name === 'IDR' ? 'Rp. ' : '$') + parseFloat(price * data[x].amount).toLocaleString(data[x].currencyFormat)
			idr_price = price

			if (data[x].currency.name === 'USD') {
				idr_price = data[x].price_in_rupiah
				var idr_price_discount = data[x].price_in_rupiah_after_discount
				data[x].idr_price_display = 'Rp. ' + parseFloat(idr_price).toLocaleString(this.currencyIDR)
				if (data[x].is_discount) {
					data[x].idr_price_display_after_disc = 'Rp. ' + parseFloat(idr_price_discount).toLocaleString(this.currencyIDR)
					idr_price = idr_price_discount
				}
				data[x].idr_totalPrice = 'Rp. ' + parseFloat(idr_price * data[x].amount).toLocaleString(this.currencyIDR)
			}

			subTotal += data[x].amount * idr_price
		}

		var order_summary_data = [{ title: 'Subtotal', value: subTotal, type: 'plus' }]

		await this.setState({ data_arr: data, subTotal: subTotal, grand_total: subTotal, order_summary_data: order_summary_data })
		await localStorage.setItem('cart', JSON.stringify(data))
	}

	async toProductDetail(index) {
		var data = this.state.data_arr
		window.location.href = '/product/' + data[index].product_id
	}

	async removeCart(index) {
		var data = this.state.data_arr
		data.splice(index, 1)
		await this.setState({ data_arr: data })

		await localStorage.setItem('cart', JSON.stringify(data))
	}

	async toCheckout() {
		var token = await localStorage.getItem('token')
		if (token == null) {
			this.$('#modalAuth').modal('show')
		}
		else {
			var order = {}
			order.notes = this.state.notes
			order.arr_cart = []
			var cart = this.state.data_arr
			for (var x in cart) {
				var data = {}

				data.price = cart[x].price_after_discount != null ? cart[x].price_after_discount : cart[x].price
				data.amount = cart[x].amount
				data.product_final = { id: cart[x].id }
				data.image_display = cart[x].image_display
				order.arr_cart.push(data)
			}
			await localStorage.setItem('order', JSON.stringify(order))
			window.location.href = '/transaction/checkout'
		}
	}

	async addNote() {
		await this.setState({ is_notes: !this.state.is_notes })

		if (this.state.is_notes) {
			this.$('#notesTextArea').focus()
		}
	}

	async changeInputNote(value) {
		await this.setState({ notes: value })
	}

	render() {
		const {
			data_arr,
			subTotal,
			grand_total,
			is_notes,
			notes,
			order_summary_data
		} = this.state
		return (
			<>

				<div className='pb-5'>
					<div className='row pb-5'>

						<div className='col-12 p-3 p-md-5'>
							<div className='row pb-5'>

								{
									data_arr.length > 0 ?
										<>
											<div className='col-12 col-md-8 px-4'>
												<p className='pb-3 m-0' style={{ borderBottom: '1px solid' }}>My Cart</p>

												<div className='row'>
													{
														data_arr.map((data, index) => (
															<>
																<ListCart
																	viewFrom={'cart'}
																	data={data}
																	key={index}
																	current_currency={this.state.current_currency}
																	current_rate={this.state.current_rate}
																	removeCart={() => this.removeCart(index)}
																	toProductDetail={() => this.toProductDetail(index)}
																	updateQty={(type, value) => this.updateQty(type, index, value)} />
															</>
														))
													}

													<div className='col-12 mt-4'>
														<span className='m-0' style={{ cursor: 'pointer' }} onClick={() => this.addNote()}><i className="fas fa-sticky-note"></i> Add a Note</span>
														{
															is_notes ?
																<textarea className="form-control mt-3" id="notesTextArea" rows="4" placeholder={'Instructions? Special Request? Add them here.'} onChange={(e) => this.changeInputNote(e.target.value)}>{notes}</textarea>
																: <></>
														}
													</div>
												</div>
											</div>

											<OrderSummary
												viewFrom={'cart'}
												current_currency={this.state.current_currency}
												current_rate={this.state.current_rate}
												order_summary_data={order_summary_data}
												grand_total={grand_total}
												toCheckout={() => this.toCheckout()} />
										</>
										:
										<>
											<div className='col-12 px-4'>
												<p className='pb-3 m-0' style={{ borderBottom: '1px solid' }}>My Cart</p>
												<div className='row m-0 text-center p-5 border-bottom d-flex align-middle align-items-center'>
													<div className='col-12'>
														<h5 className='m-0'>Cart is empty</h5>
													</div>
													<div className='col-12'>
														<a href='/'><u>Continue Shopping</u></a>
													</div>
												</div>
											</div>
										</>
								}

							</div>
						</div>

					</div>
				</div>

			</>
		)
	}
}