import React from 'react';
import Base from '../Utils/Base';
import Alert from './Alert'

export default class ModalCardPayment extends Base {

	state = {
		optionsAxios: this.optionsAxios,
		alert: { type: '', message: '', is_show: false },
		is_disabled: false,
		payment_method_arr: [],
	}

	async componentDidMount() {
		var token = await localStorage.getItem('token')
		var optionsAxios = this.state.optionsAxios
		optionsAxios['headers']['Authorization'] = token
		await this.setState({ optionsAxios: optionsAxios })
	}

	render() {
		const {
			alert,
			payment_method_arr
		} = this.state
		const {
			pay,
			is_method_alert,
			is_disabled,
			is_loading
		} = this.props
		return (
			<>
				<div className="modal fade" id="modalCardPayment" tabIndex="-1" aria-labelledby="modalCardPaymentLabel" aria-hidden="true" data-backdrop="static" data-keyboard='false'>
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="modalCardPaymentLabel">Credit Card</h5>
							</div>
							{
								!is_loading ?
									<>
										<div className="modal-body">
											<div className='row'>
												<div className='col-12'>
													{
														is_method_alert.is_show &&
														<Alert type={is_method_alert.type} message={is_method_alert.message} />
													}
													<p className='m-0'>Enter Credit Card : </p>
													<div className='row'>
														<div className='col-12'>
															<p className='m-0'>Card Number</p>
															<input type="text" 
																className="form-control" 
																name='cardNumber' 
																value={this.props.card_number}
																onChange={(value) => this.props.changeInput(value.target.value, 'card_number')}/>
														</div>

														<div className='col-6 mt-3'>
															<p className='m-0'>Card Expired</p>
															<div className='d-flex align-items-center'>
																<input type="text" 
																	className="form-control" 
																	name='cardExpiredMonth' 
																	value={this.props.card_expired_month}
																	onChange={(value) => this.props.changeInput(value.target.value, 'card_expired_month')}/>
																<p className='m-0 mx-3'>/</p>
																<input type="text" 
																	className="form-control" 
																	name='cardExpiredYear' 
																	value={this.props.card_expired_year}
																	onChange={(value) => this.props.changeInput(value.target.value, 'card_expired_year')}/>
															</div>
														</div>

														<div className='col-6 mt-3'>
															<p className='m-0'>Card CVV</p>
															<div className='d-flex'>
																<input type="text" 
																	className="form-control" 
																	name='cardCVV' 
																	value={this.props.card_cvv}
																	onChange={(value) => this.props.changeInput(value.target.value, 'card_cvv')}/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="modal-footer">
											<button type="button" className="btn btn-sm btn-secondary btn-text-sm" data-dismiss="modal" disabled={is_disabled}>Back</button>
											<button type="button" className="btn btn-sm btn-primary btn-text-sm" onClick={() => this.props.pay()} disabled={is_disabled}>Pay</button>
										</div>
									</>
									:
									<>
										<div className="modal-body">
											<div className='row'>
												<div className='col-12 text-center'>
													<img src={this.loading} style={{ height: '8rem' }} />
												</div>
											</div>
										</div>
									</>
							}

						</div>
					</div>
				</div>
			</>
		);
	}
}
