import React from 'react'
import Base from '../Utils/Base'

export default class Alert extends Base{
    state = {
    }

    async componentDidMount(){
    }

    render(){
        const {
            type,
            message
        } = this.props
        return (
            <>
            <div className={"alert alert-" + type} role="alert">{message}</div>
            </>
        )
    }
}