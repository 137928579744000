import React from 'react';
import Base from '../Utils/Base';
import Alert from './Alert'

export default class ModalChooseAddress extends Base {

	state = {
		optionsAxios: this.optionsAxios,
		alert: { type: '', message: '', is_show: false },
		is_disabled: false,
		payment_method_arr: [],
	}

	async componentDidMount() {
		var token = await localStorage.getItem('token')
		var optionsAxios = this.state.optionsAxios
		optionsAxios['headers']['Authorization'] = token
		await this.setState({ optionsAxios: optionsAxios })

		var context = this
		this.$('#modalPayment').on('show.bs.modal', async function (event) {
			await context.get_paymentMethod()
		})
	}

	async get_paymentMethod() {
		try {
			var url = this.url + '/payment-method/all?is_available=1'

			var response = await this.axios.get(url, this.state.optionsAxios,
			);

			if (response.data.status === 'success') {
				var data = response.data.data
				for (var x in data) {
					data[x].is_selected = false
				}
				await this.setState({ payment_method_arr: data })
			}

		} catch (e) {
			// console.log(e.message)
		}
	}

	async changePaymentMethod(index) {
		var payment_method = this.state.payment_method_arr
		for (var x in payment_method) {
			payment_method[x].is_selected = false
		}
		payment_method[index].is_selected = true

		await this.setState({ payment_method_arr: payment_method, payment_method_selected: payment_method[index] })

		await this.props.change_payment(payment_method[index])
	}

	render() {
		const {
			alert,
			payment_method_arr
		} = this.state
		const {
			pay,
			is_method_alert,
			is_disabled,
			is_loading
		} = this.props
		return (
			<>
				<div className="modal fade" id="modalPayment" tabIndex="-1" aria-labelledby="modalPaymentLabel" aria-hidden="true" data-backdrop="static" data-keyboard='false'>
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="modalPaymentLabel">Payment Method</h5>
							</div>
							{
								!is_loading ?
									<>
										<div className="modal-body">
											<div className='row'>
												<div className='col-12'>
													{
														is_method_alert.is_show ?
															<Alert type={is_method_alert.type} message={is_method_alert.message} />
															: <></>
													}
													<p className='m-0'>Choose Payment Method : </p>
													<div className='row mt-2'>
														<div className='col-12'>
															{
																payment_method_arr.map((data, index) => (
																	<div className="custom-control custom-radio">
																		<input type="radio" 
																			id={"paymentMethod" + index} 
																			className="custom-control-input" 
																			name='selectPaymentMethod' 
																			disabled={this.props.current_currency === "USD" && data.type !== 'Credit_Card'} 
																			value={data.id} 
																			checked={data.is_selected} 
																			onChange={() => this.changePaymentMethod(index)} />
																		<label className="custom-control-label w-100 pl-2" htmlFor={"paymentMethod" + index}>
																			<p className="m-0"><b>{data.name}</b></p>
																		</label>
																	</div>
																))
															}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="modal-footer">
											<button type="button" className="btn btn-sm btn-secondary btn-text-sm" data-dismiss="modal" disabled={is_disabled}>Close</button>
											<button type="button" className="btn btn-sm btn-primary btn-text-sm" onClick={() => pay()} disabled={is_disabled}>Pay</button>
										</div>
									</>
									:
									<>
										<div className="modal-body">
											<div className='row'>
												<div className='col-12 text-center'>
													<img src={this.loading} style={{ height: '8rem' }} />
												</div>
											</div>
										</div>
									</>
							}

						</div>
					</div>
				</div>
			</>
		);
	}
}
