import React from 'react';
import Base from '../Utils/Base';
import Alert from './Alert'

import QRCode from "react-qr-code";

export default class ModalChooseAddress extends Base{

	state = {
        token : '',
		optionsAxios : this.optionsAxios,
        payment_alert_arr : [
            {value : 'No rekening/kode pembayaran ini HANYA DIGUNAKAN UNTUK 1 KALI transaksi/transfer.'},
            {value : 'JANGAN melakukan pembayaran ke no rekening/kode pembayaran ini lagi.'},
            {value : 'Tidak masuknya dana karena kelalaian user bukan tanggung jawab Lumosh Living'},
        ]
	}

	async componentDidMount(){
        var token = await localStorage.getItem('token')
        var optionsAxios = this.state.optionsAxios
        optionsAxios['headers']['Authorization'] = token
        await this.setState({optionsAxios : optionsAxios})
	}

    async copyAccountNumber(value){
        var temp = this.$("<input id='accountNumber'>")
        this.$("#modalPayNow").append(temp);
        temp.val(value)

        var copyText = document.getElementById("accountNumber");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        alert("VA Number Copied to clipboard");

        temp.remove()
    }

    async toListOrder(){
        // await localStorage.setItem('lastSideMenuSelected', 'list-order')
        window.location.href = '/profile/list-order'
    }

    render(){
        const {
            due_date,
            countdown_timer,
            view_from,
            bank_info,
            grand_total,
            user_data,
            order_selected,
        } = this.props
        const {
            payment_alert_arr
        } = this.state
        return (
        <>
            <div className="modal fade" id="modalPayNow" tabIndex="-1" aria-labelledby="modalPayNowLabel" aria-hidden="true" data-backdrop="static" data-keyboard='false'>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='row m-0'>
                                <div className='col-12 text-center'>
                                    <h6>Selesaikan Pembayaran Dalam</h6>
                                    <h5>{countdown_timer}</h5>

                                    <p className='m-0 mt-3'>Batas Akhir Pembayaran</p>
                                    <p>{due_date}</p>
                                </div>
                                <div className='col-12 border rounded p-3'>
                                    {
                                        order_selected.qr_string != null ?
                                        <>
                                        <div className='text-center mb-3'>
                                            <QRCode value={order_selected.qr_string} />
                                        </div>
                                        </>
                                        :
                                        order_selected.url_payment != null  ?
                                        <div className='mb-3'>
                                            <a href={order_selected.url_payment}><u>Link Pembayaran</u></a>
                                        </div>
                                        :
                                        <>
                                        {
                                            bank_info.map((data, index)=>(
                                                <>
                                                <div className='row mb-3' key={index}>
                                                    <div className='col'>
                                                        <p className='m-0'>{data.title}</p>
                                                    </div>
                                                    {
                                                        data.type === 'account_number' ? 
                                                        <>
                                                        {
                                                            data.method_type === 'VA' ? 
                                                            <div className='col text-right'>
                                                                <a href='#!' className='m-0' onClick={()=>this.copyAccountNumber(data.value)}>Salin</a>
                                                            </div>
                                                            : <></>
                                                        }
                                                        </>
                                                        : <></>
                                                    }
                                                    <div className='col-12'>
                                                        <p className='m-0'>{data.value}</p>
                                                    </div>
                                                </div>
                                                </>
                                            ))
                                        }
                                        </>
                                    }

                                    <p className='m-0'>Total Pembayaran</p>
                                    <p className='m-0'>Rp. {parseFloat(grand_total).toLocaleString(this.currencyIDR)}</p>
                                </div>

                                {
                                    order_selected.qr_string != null ?
                                    <div className='col-12 mt-3 p-0'>
                                        <div className={"alert alert-danger border border-danger paymentAlert"} role="alert">
                                            <h6 className='text-uppercase'>Penting :</h6>
                                            <ol className='m-0'>
                                            {
                                                payment_alert_arr.map((data, index)=>(
                                                    <li>{data.value}</li>
                                                ))
                                            }
                                            {
                                                view_from !== 'list_order' ?
                                                    <li><a href='#!' onClick={()=>this.toListOrder()}><u>Klik disini</u></a> untuk melihat informasi dan status pembayaran</li>
                                                :
                                                <></>
                                            }
                                            </ol>
                                        </div>
                                    </div>
                                    : <></>
                                }
                                <div className='col-12 text-center mt-3'>
                                    {
                                        view_from === 'list_order' ? 
                                        <button type="button" className="btn btn-sm btn-secondary btn-text-sm" data-dismiss="modal">Close</button>
                                        :
                                        <a href='/' className='btn btn-primary'>Back to Home</a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        );
    }
}
