import React from 'react'
import Base from '../Utils/Base'

import MyProfile from './MyProfile'
import ListOrder from './ListOrder'

export default class Profile extends Base{
    state = {
        token : '',
        optionsAxios : this.optionsAxios,
        user_data : {id : '', name : '', email : '', phone : '', birth_date : '', image_display : '', image_type : 'old'},
        sideMenu : [
            {title : 'Profile', is_active : true, type : 'profile'},
            {title : 'List Order', is_active : false, type : 'list-order'},
            {title : 'Logout', is_active : false, type : 'logout'},
        ],
        sideMenu_active : {type : ''},
        birth_date : '',

        personal_data_form : {id : '', name : '', phone : '', email : '', birth_date : '', image_display : '', image_type : 'old'},
        alert : {is_show : false, message : '', type : ''},
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        var optionsAxios = this.optionsAxios
        if(token != null){
            optionsAxios['headers']['Authorization'] = token
        }
        else{
            window.location.href = '/'
        }
        var sideMenu_active = this.state.sideMenu[0]
        // var lastSideMenuSelected = await localStorage.getItem('lastSideMenuSelected')
        var type = this.props.match.params.type
        if(type === 'list-order'){
            var sideMenu = this.state.sideMenu
            for(var x in sideMenu){
                sideMenu[x].is_active = false
                if(sideMenu[x].type === type){
                    sideMenu[x].is_active = true
                }
            }
            sideMenu_active = this.state.sideMenu[1]
        }

        await this.setState({token : token, optionsAxios : optionsAxios, sideMenu_active : sideMenu_active})
        
        await this.get_auth()
    }

    async setAlert(alertData){
        var alert = JSON.parse(alertData)
        await this.setState({alert : alert})
    }

    async get_auth(){
        try {
            var url = this.url + '/auth/profile'
      
            var response = await this.axios.get(url, this.state.optionsAxios,
            );
      
            if (response.data.status === 'success') {
                var data = response.data.data
                data.image_display = this.no_image_profile
                if(data.file_name != null){
                    data.image_display = this.url_image + '/user?file_name=' + data.file_name
                }
                data.birth_date = new Date(data.birth_date)
                data.image_type = 'old'
                var personal_data_form = JSON.stringify(data)
                personal_data_form = JSON.parse(personal_data_form)
                await this.setState({user_data : data, personal_data_form : personal_data_form, birth_date : data.birth_date})
            }

        } catch (e) {
            // console.log(e.message)
        }
    }

    async sideMenu(index){
        var sideMenu = this.state.sideMenu
        if(sideMenu[index].type !== 'logout'){
            for(var x in sideMenu){
                sideMenu[x].is_active = false
            }
            sideMenu[index].is_active = true

            // await localStorage.setItem('lastSideMenuSelected', sideMenu[index].type)
            window.history.pushState(null, null, '/profile/' + (sideMenu[index].type == 'profile' ? '' : sideMenu[index].type))
            await this.setState({sideMenu : sideMenu, sideMenu_active : sideMenu[index]})
        }
        else if(sideMenu[index].type === 'logout'){
            await localStorage.clear()
            await localStorage.setItem('currency_selected', this.current_currency)
            window.location.href = '/'
        }
    }

    async changePersonalInfo(value, type){
        await this.setState({alert : {is_show : false, message : '', type : ''}})
        var personal_data_form = this.state.personal_data_form
        if(type === 'phone'){
            var index = value.length - 1
            if(value.charCodeAt(index) >= 48 && value.charCodeAt(index) <= 57){
                personal_data_form[type] = value
            }
        }
        else if(type === 'birth_date'){
            personal_data_form[type] = value
            await this.setState({birth_date : value})
        }
        else{
            personal_data_form[type] = value
        }
        await this.setState({personal_data_form : personal_data_form})
    }

    async changePicture(event){
        // var context = this
				// this.getBase64(event.target.files[0], (result) => {
				// 	var user_data = context.state.personal_data_form
				// 	var data_photo = {
				// 			original_orientation : 0,
				// 			image : result,
				// 	}
				// 	user_data.image = data_photo
				// 	user_data.image_display = URL.createObjectURL(event.target.files[0])
				// 	user_data.image_type = 'new'
				// 	context.setState({personal_data_form : user_data})
				// })
        
    }

    render(){
        const {
            sideMenu,
            sideMenu_active,
            user_data,
            personal_data_form,
            alert,
            birth_date
        } = this.state

        return (
            <>

            <div className='row py-5'>
                <div className="col-md-4 col-lg-3 mb-0 mb-md-4 pt-5">
                    <ul className="list-group profileMenu">
                        <li className="list-group-item p-3 d-none d-md-block">
                            <div className="row">
                                <div className="col-6 offset-3">
                                    <div className="ratio-11 rounded-circle" style={{backgroundImage : "url(" + user_data.image_display +")", backgroundRepeat : 'no-repeat'}} ></div>
                                </div>
                                <div className="col-12 text-center mt-3">
                                    <p className={"text-primary m-0" + (user_data.name != '' ? ' text-capitalize' : '')} style={{wordWrap : 'break-word'}}><b>{user_data.name != '' ? user_data.name : user_data.email}</b></p>
                                </div>
                            </div>
                        </li>
                        {
                            sideMenu.map((data, index)=>(
                                <a href='#!' className={"list-group-item list-group-item-action sideMenuProfile border-top" + (data.is_active ? ' active' : '')} onClick={()=>this.sideMenu(index)} key={index}>{data.title}</a>
                            ))
                        }    
                    </ul>
                </div>

                <div className="col-12 col-md-8 col-lg-9 pb-5 pt-3 pb-md-5 pt-md-5">
                    {
                        sideMenu_active.type === 'profile' ?
                        <MyProfile user_data={personal_data_form} birth_date={birth_date} changePersonalInfo={(value, type)=>this.changePersonalInfo(value, type)} setAlert={(alertData)=>this.setAlert(alertData)} alert={alert} get_auth={()=>this.get_auth()} changePicture={(event)=>this.changePicture(event)} />
                        : sideMenu_active.type === 'list-order' ?
                        <ListOrder />
                        : <></>
                    }                    
                </div>

            </div>

            </>
        )
    }
}