import React from 'react';
import Base from '../Utils/Base';

export default class ModalPreloader extends Base{

	state = {
	}

	async componentDidMount(){
	}

    render(){
        return (
        <>
            <div className="modal fade" id="modalPreloader" tabIndex="-1" aria-labelledby="modalPreloaderLabel" aria-hidden="true" data-backdrop="static" data-keyboard='false'>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalPreloaderLabel">Please Wait...</h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
        );
    }
}
