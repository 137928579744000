import React from 'react';
import Base from '../Utils/Base';

export default class Footer extends Base {
	state = {
		footer_arr: [
			{
				title: 'Lumosh', detail: [
					{ title: 'Home', url: '/', type: 'link' },
					{ title: 'Sustainability', url: '/sustainability', type: 'link' },
					{ title: 'Bedtime Stories', url: '/blog', type: 'link' },
					{ title: 'In The Press', url: '/in-the-press', type: 'link' },
				]
			},
			{
				title: 'Help', detail: [
					{ title: 'Shipping & Returns', type: 'link', url: '/help/shipping-returns' },
					{ title: 'Store Policy', type: 'link', url: '/help/store-policy' },
					{ title: 'Payment Methods', type: 'link', url: '/help/payment-methods' },
					{ title: 'FAQ', type: 'link', url: '/help/faq' },
				]
			},
			{
				title: 'Contact', detail: [
					{ title: '', url: '#!', type: 'link' },
					{ title: 'cert', url: '#!', type: 'other' },
				]
			},
		],
	}

	async componentDidMount() {
		await this.get_contact()
	}

	async get_contact() {
		try {
			var url = this.url + '/blog/home?blog_home_type_name=contact&is_publish=1'

			var response = await this.axios.get(url, this.optionsAxios);

			if (response.data.status == 'success') {
				var data = response.data.data.data
				var footer_arr = this.state.footer_arr
				footer_arr[2].detail[0]['title'] = data[0].title
				await this.setState({ footer_arr: footer_arr })
			}

		} catch (e) {
			console.log(e.message)
		}
	}

	render() {
		const {
			footer_arr
		} = this.state
		return (
			<>
				<footer className="footer pt-5">
					<div className="">
						<div className="container">
							<div className="row m-0" style={{ borderTop: '2px solid #000' }}>
								<div className='col-12 py-5 text-center text-md-left'>
									<div className='row'>
										{
											footer_arr.map((data_footer, index_footer) => (
												<div className={'col-lg-4 text-lg-left' + (index_footer === 0 ? '' : ' mt-3') + ' mt-lg-0'} key={index_footer}>
													<h5 className='mb-4'>{data_footer.title}</h5>
													<div>
														{
															data_footer.detail.map((detail, index) => {
																if(detail.title === "cert")
																	return (
																		<div>
																			<img src={require('../assets/images/RapidSSL-SEAL.gif')} />
																		</div>
																	)
																else
																	return (
																		<p className='text-white' key={index}>
																			<a className='text-uppercase' style={{ fontSize: '13px' }} href={detail.url}>{detail.title}</a>
																		</p>
																	)
															})
														}
													</div>
												</div>
											))
										}
										{/* <div className="col-lg-3 mt-3 mt-lg-0" id='newsletter'>
                                        <h5 className=''>Newsletter</h5>
                                        <label htmlFor="basic-url">Enter Email*</label>
                                        <input type="email" className="form-control" placeholder='Email' />
                                        <button type='button' className='btn btn-primary w-100 mt-2 btn_primary text-uppercase py-2'><h6 className='m-0'>Subscribe</h6></button>
                                    </div> */}
									</div>
								</div>

								<div className='col-12 text-center pb-2'>
									<p><small>© 2021 by Lumosh Living</small></p>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</>
		);
	}
}