import React from 'react'
import Base from '../Utils/Base'

import ProductList from './productList'
import NoData from '../Components/NoData'

export default class ShopAll extends Base {
  state = {
    category_arr: [],
    data_arr: [],
    category_selected: '',
    page: 1,
    per_page: 0,
    is_search: false,
    search: '',
    is_loading: true,
    current_currency : '',
    current_rate : ''
  }
  search_callback = ''

  async componentDidMount() {
    var currency = await localStorage.getItem('currency_selected')
    if(currency != null){
        await this.setState({current_currency : currency})
        if(currency === 'USD'){
          await this.get_rate()
        }
    }
    else{
        await this.setState({current_currency : 'IDR'})
    }

    await this.get_category()
    await this.get_data()
  }

  async get_rate(){
    try {
        var url = this.url + '/exchange/last'

        var response = await this.axios.get(url, this.optionsAxios);

        if (response.data.status == 'success') {
            await this.setState({current_rate : response.data.data.price})
        }

    } catch (e) {
        console.log(e.message)
    }
  }

  async get_category() {
    try {
      var url = this.url + '/category/all'

      var response = await this.axios.get(url, this.optionsAxios,
      );

      if (response.data.status == 'success') {
        var data = response.data.data
        var arr = [
          { id: '', name: 'View All', is_active: true }
        ]
        for (var x in data) {
          data[x].is_active = false
          arr.push(data[x])
        }
        this.setState({ category_arr: arr })
      }

    } catch (e) {
      console.log(e.message)
    }
  }

  async get_data() {
    try {
      var url = this.url + '/product?category_id=' + this.state.category_selected + '&page=' + this.state.page + '&search=' + this.state.search + '&is_publish=1'

      var response = await this.axios.get(url, this.optionsAxios,
      );

      if (response.data.status == 'success') {
        var data = response.data.data.data
        if (data.length == 0) {
          await this.setState({ page: this.state.page - 1 })
        }

        var data_arr = this.state.data_arr

        for (var x in data) {
          data[x].image_display = this.no_image
          var photo = data[x].arr_photo
          for (var y in photo) {
            if (photo[y].is_primary) {
              data[x].image_display = this.url_image + '/product?file_name=' + photo[y].file_name
            }
          }

          data[x].currencyFormat = (data[x].currency.name === 'IDR' ? this.currencyIDR : this.currencyUSD)
          data[x].currency_symbol = (data[x].currency.name === 'IDR' ? 'Rp. ' : '$')

          // if(this.state.current_currency === 'USD'){
          //   if(data[x].currency.name === 'IDR'){
          //     data[x].currency_symbol = '$'
          //     data[x].price = parseFloat(data[x].price / this.state.current_rate).toFixed(2)
          //     if (data[x].price_after_discount != null) {
          //       data[x].price_after_discount = parseFloat(data[x].price_after_discount / this.state.current_rate).toFixed(2)
          //     }
          //     data[x].currencyFormat = this.currencyUSD
          //   }
          // }
          // else if(this.state.current_currency === 'IDR'){
          //   if(data[x].currency.name === 'USD'){
          //     data[x].currency_symbol = 'Rp. '
          //     data[x].price = parseFloat(data[x].price * this.state.current_rate)
          //     data[x].price_after_discount = parseFloat(data[x].price_after_discount * this.state.current_rate)
          //     data[x].currencyFormat = this.currencyUSD
          //   }
          // }

          data[x].price_display = data[x].currency_symbol + parseFloat(data[x].price).toLocaleString(data[x].currencyFormat)
          data[x].is_discount = false
          if (data[x].price_after_discount != null) {
            data[x].is_discount = true
            data[x].price_display_after_disc = data[x].currency_symbol + parseFloat(data[x].price_after_discount).toLocaleString(data[x].currencyFormat)
          }


          data_arr.push(data[x])
        }

        await this.setState({ data_arr: data_arr, per_page: response.data.data.per_page })

        setTimeout(async () => {
          await this.setState({ is_loading: false })
        }, 1000);
      }

    } catch (e) {
      console.log(e.message)
    }
  }

  async updateQty(type, index, qty = 0) {
    var data = this.state.data_arr

    if (type === 'min') {
      if (data[index].orderQty != 1) {
        data[index].orderQty -= 1
      }
    }
    else if (type === 'plus') {
      if (data[index].orderQty < data[index].qty) {
        data[index].orderQty += 1
      }
    }
    else if (type === 'type') {
      data[index].orderQty = parseInt(qty)
      if (qty > data[index].qty) {
        data[index].orderQty = data[index].qty
      }
    }
    await this.setState({ data_arr: data })
  }

  async addToCart(index) {
    var data = this.state.data_arr
    // console.log(data[index].orderQty)

    var cart_data = await localStorage.getItem('cart')
    cart_data = JSON.parse(cart_data)

    if (cart_data == null) {
      cart_data.push(data[index])
      await localStorage.setItem('cart', JSON.stringify(cart_data))
    }

    if (cart_data != null) {
      for (var x in cart_data) {
        if (cart_data[x].id === data[index].id) {
          cart_data[x].orderQty += parseInt(data[index].orderQty)
        }
      }
      await localStorage.setItem('cart', JSON.stringify(cart_data))
    }
  }

  async changeCategory(index) {
    await this.setState({ data_arr: [], page: 1 })
    var category = this.state.category_arr
    for (var x in category) {
      category[x].is_active = false
    }
    category[index].is_active = true
    await this.setState({ category_arr: category, category_selected: category[index].id })

    await this.setState({ is_loading: true })

    await this.get_data()
  }

  async loadMore() {
    await this.setState({ page: this.state.page + 1 })
    await this.get_data()
  }

  async search_product() {
    if (this.state.search !== '') {
      await this.search()
    }
    else if (this.state.search === '') {
      await this.setState({ is_search: !this.state.is_search })
      if (this.state.is_search) {
        this.$('#search_input').focus()
      }
    }
  }

  async changeSearch(value) {
    await this.search(value)
  }
  
  async search(search=''){
    await this.setState({ search: search, page: 1, data_arr: [] })
    await this.setState({ is_loading: true })
    clearTimeout(this.search_callback)
    this.search_callback = setTimeout(async () => {
      await this.get_data()
    }, 750);    
  }

  render() {
    const {
      category_arr,
      data_arr,
      per_page,
      is_search,
      search,
      is_loading
    } = this.state

    return (
      <>

        <section className="mt-5">
          <div className='row'>
            <div id="sa_01" className='col-12 p-4'>
              <div className='row'>
                <div className='col-12 col-lg-5 mb-3 mb-lg-0 d-block d-lg-none'>
                  <div className='p-4 mb-3 d-md-none' style={{ backgroundColor: '#D7CDC2' }}>
                  </div>
                  <div className="ratio-43" style={{ backgroundImage: "url(" + this.img_shop_header + ")", backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }} ></div>
                </div>
                <div className='col-12 col-lg-6 text-right px-3 px-lg-5'>
                  <div className='d-flex align-middle align-items-center h-100 justify-content-end'>
                    <div className='row px-0 px-lg-5'>
                      <div className='col-12'>
                        <h4 className='m-0 text-uppercase' style={{ fontFamily: "LuloClean" }}>We Believe In</h4>
                        <h4 className='m-0 text-uppercase text-white mt-2'><span style={{ fontFamily: "LuloClean", backgroundColor: 'black' }}>Authenticity.</span></h4>
                      </div>
                      <div className='col-12 mt-3'>
                        <p className='m-0' style={{ fontSize: '1rem', fontFamily: 'Questrial' }}>
                          Created by hand has its own characteristic that left on every single product. Every flaw is beautiful, and none of them are perfect, just like us as human, yet it is the real definition of <u>authentic</u>.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-lg-5 d-none d-lg-block'>
                  <div className="ratio-169" style={{ backgroundImage: "url(" + this.img_shop_header + ")", backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }} ></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-5">
          <div className='row'>
            <div className='col-12 p-4 text-right'>
              <div className='form-inline justify-content-center'>
                <div id="sc_000" className="input-group border-bottom">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-transparent border-0"><i className="fas fa-search"></i></span>
                  </div>
                  <input type="text" className="form-control form-control-sm bg-transparent border-0 p-0" id={'search_input'} placeholder='Search' value={search} onChange={(e) => this.changeSearch(e.target.value)} />
                </div>
              </div>
              {/* {
                !is_search ?
                  <span style={{ cursor: 'pointer' }} onClick={() => this.search_product()}><i className="fas fa-search"></i></span>
                  :
                  <>
                    <div className='form-inline justify-content-center'>
                      <div id="sc_000" className="input-group border-bottom">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent border-0"><i className="fas fa-search"></i></span>
                        </div>
                        <input type="text" className="form-control form-control-sm bg-transparent border-0 p-0" id={'search_input'} placeholder='Search' value={search} onChange={(e) => this.changeSearch(e.target.value)} />
                        <div className="input-group-append">
                          <span className="input-group-text bg-transparent border-0" onClick={() => this.search_product()}><i className="fas fa-times"></i></span>
                        </div>
                      </div>
                    </div>
                  </>
              } */}
            </div>
            <div className='col-12 px-4 d-flex justify-content-center'>
              <div className='shop-category'>
              {
                category_arr.map((data, index) => (
                  <>
                    <button className={'btn shopCategory fnt-pd py-2 px-3' + (data.is_active ? ' active fnt-pd-b' : '')} key={index} onClick={() => this.changeCategory(index)}>{data.name}</button>
                  </>
                ))
              }
              </div>
            </div>
          </div>
        </section>

        <section className="mt-5 px-md-5">
          <div className='row m-0'>
            <div className='col-12 px-2 px-md-5'>
              <div className='row'>
                {
                  is_loading ?
                    <div className='col-12 text-center'>
                      <img src={this.loading} />
                    </div>
                    :
                    <>
                      {
                        data_arr.length == 0 ?
                          <div className='col'>
                            <NoData />
                          </div>
                          :
                          <>
                            {
                              data_arr.map((data, index) => (
                                <div className='col-6 col-md-4 mb-3'>
                                  <ProductList 
                                    key={index} 
                                    data={data}
                                    current_currency={this.state.current_currency}
                                    current_rate={this.state.current_rate} 
                                    updateQty={(type, value) => this.updateQty(type, index, value)} 
                                    addToCart={() => this.addToCart(index)} 
                                    hoverList={(type) => this.hoverList(type, index)} />
                                </div>
                              ))
                            }
                          </>
                      }
                    </>
                }

              </div>
            </div>

            {
              data_arr.length >= per_page && data_arr.length > 0 ?
                <div className='col-12 text-center mt-4'>
                  <button className='btn btn-sm btn_outline' onClick={() => this.loadMore()}>Load More</button>
                </div>
                :
                <></>
            }
          </div>
        </section>

      </>
    )
  }
}